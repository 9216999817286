// Text Color Classes
///////////////////////

// Suzy Black
.text-suzy-black {
color: $suzy-black;
}

// Suzy White
.text-suzy-white {
color: $suzy-white;
}

// Suzy Blue
.text-suzy-blue-50 {
  color: $suzy-blue-50;
}
.text-suzy-blue-100 {
  color: $suzy-blue-100;
}
.text-suzy-blue-200 {
  color: $suzy-blue-200;
}
.text-suzy-blue-300 {
  color: $suzy-blue-300;
}
.text-suzy-blue-400 {
  color: $suzy-blue-400;
}
.text-suzy-blue-500 {
  color: $suzy-blue-500;
}
.text-suzy-blue-600 {
  color: $suzy-blue-600;
}
.text-suzy-blue-700 {
  color: $suzy-blue-700;
}
.text-suzy-blue-800 {
  color: $suzy-blue-800;
}
.text-suzy-blue-900 {
  color: $suzy-blue-900;
}

// Suzy Grey
.text-suzy-grey-50 {
color: $suzy-grey-50;
}
.text-suzy-grey-100 {
color: $suzy-grey-100;
}
.text-suzy-grey-200 {
color: $suzy-grey-200;
}
.text-suzy-grey-300 {
color: $suzy-grey-300;
}
.text-suzy-grey-400 {
color: $suzy-grey-400;
}
.text-suzy-grey-500 {
color: $suzy-grey-500;
}
.text-suzy-grey-600 {
color: $suzy-grey-600;
}
.text-suzy-grey-700 {
color: $suzy-grey-700;
}
.text-suzy-grey-800 {
color: $suzy-grey-800;
}
.text-suzy-grey-900 {
color: $suzy-grey-900;
}

// Suzy Purple
.text-suzy-purple-50 {
color: $suzy-purple-50;
}
.text-suzy-purple-100 {
color: $suzy-purple-100;
}
.text-suzy-purple-200 {
color: $suzy-purple-200;
}
.text-suzy-purple-300 {
color: $suzy-purple-300;
}
.text-suzy-purple-400 {
color: $suzy-purple-400;
}
.text-suzy-purple-500 {
color: $suzy-purple-500;
}
.text-suzy-purple-600 {
color: $suzy-purple-600;
}
.text-suzy-purple-700 {
color: $suzy-purple-700;
}
.text-suzy-purple-800 {
color: $suzy-purple-800;
}
.text-suzy-purple-900 {
color: $suzy-purple-900;
}

// Suzy Core Blue
.text-suzy-core-blue-50 {
color: $suzy-core-blue-50;
}
.text-suzy-core-blue-100 {
color: $suzy-core-blue-100;
}
.text-suzy-core-blue-200 {
color: $suzy-core-blue-200;
}
.text-suzy-core-blue-300 {
color: $suzy-core-blue-300;
}
.text-suzy-core-blue-400 {
color: $suzy-core-blue-400;
}
.text-suzy-core-blue-500 {
color: $suzy-core-blue-500;
}
.text-suzy-core-blue-600 {
color: $suzy-core-blue-600;
}
.text-suzy-core-blue-700 {
color: $suzy-core-blue-700;
}
.text-suzy-core-blue-800 {
color: $suzy-core-blue-800;
}
.text-suzy-core-blue-900 {
color: $suzy-core-blue-900;
}

// Suzy Red
.text-suzy-red-50 {
color: $suzy-red-50;
}
.text-suzy-red-100 {
color: $suzy-red-100;
}
.text-suzy-red-200 {
color: $suzy-red-200;
}
.text-suzy-red-300 {
color: $suzy-red-300;
}
.text-suzy-red-400 {
color: $suzy-red-400;
}
.text-suzy-red-500 {
color: $suzy-red-500;
}
.text-suzy-red-600 {
color: $suzy-red-600;
}
.text-suzy-red-700 {
color: $suzy-red-700;
}
.text-suzy-red-800 {
color: $suzy-red-800;
}
.text-suzy-red-900 {
color: $suzy-red-900;
}

// Suzy Warm
.text-suzy-warm-50 {
color: $suzy-warm-50;
}
.text-suzy-warm-100 {
color: $suzy-warm-100;
}
.text-suzy-warm-200 {
color: $suzy-warm-200;
}
.text-suzy-warm-300 {
color: $suzy-warm-300;
}
.text-suzy-warm-400 {
color: $suzy-warm-400;
}
.text-suzy-warm-500 {
color: $suzy-warm-500;
}
.text-suzy-warm-600 {
color: $suzy-warm-600;
}
.text-suzy-warm-700 {
color: $suzy-warm-700;
}
.text-suzy-warm-800 {
color: $suzy-warm-800;
}
.text-suzy-warm-900 {
color: $suzy-warm-900;
}

// Suzy Green
.text-suzy-green-50 {
color: $suzy-green-50;
}
.text-suzy-green-100 {
color: $suzy-green-100;
}
.text-suzy-green-200 {
color: $suzy-green-200;
}
.text-suzy-green-300 {
color: $suzy-green-300;
}
.text-suzy-green-400 {
color: $suzy-green-400;
}
.text-suzy-green-500 {
color: $suzy-green-500;
}
.text-suzy-green-600 {
color: $suzy-green-600;
}
.text-suzy-green-700 {
color: $suzy-green-700;
}
.text-suzy-green-800 {
color: $suzy-green-800;
}
.text-suzy-green-900 {
color: $suzy-green-900;
}

// Suzy Pink
.text-suzy-pink-50 {
color: $suzy-pink-50;
}
.text-suzy-pink-100 {
color: $suzy-pink-100;
}
.text-suzy-pink-200 {
color: $suzy-pink-200;
}
.text-suzy-pink-300 {
color: $suzy-pink-300;
}
.text-suzy-pink-400 {
color: $suzy-pink-400;
}
.text-suzy-pink-500 {
color: $suzy-pink-500;
}
.text-suzy-pink-600 {
color: $suzy-pink-600;
}
.text-suzy-pink-700 {
color: $suzy-pink-700;
}
.text-suzy-pink-800 {
color: $suzy-pink-800;
}
.text-suzy-pink-900 {
color: $suzy-pink-900;
}

// Background Color Classes
///////////////////////

// Suzy Black
.bg-color-suzy-black {
background-color: $suzy-black;
}

// Suzy White
.bg-color-suzy-white {
background-color: $suzy-white;
}

// Suzy Blue
.bg-color-suzy-blue-50 {
background-color: $suzy-blue-50;
}
.bg-color-suzy-blue-100 {
background-color: $suzy-blue-100;
}
.bg-color-suzy-blue-200 {
background-color: $suzy-blue-200;
}
.bg-color-suzy-blue-300 {
background-color: $suzy-blue-300;
}
.bg-color-suzy-blue-400 {
background-color: $suzy-blue-400;
}
.bg-color-suzy-blue-500 {
background-color: $suzy-blue-500;
}
.bg-color-suzy-blue-600 {
background-color: $suzy-blue-600;
}
.bg-color-suzy-blue-700 {
background-color: $suzy-blue-700;
}
.bg-color-suzy-blue-800 {
background-color: $suzy-blue-800;
}
.bg-color-suzy-blue-900 {
background-color: $suzy-blue-900;
}

// Suzy Grey
.bg-color-suzy-grey-50 {
background-color: $suzy-grey-50;
}
.bg-color-suzy-grey-100 {
background-color: $suzy-grey-100;
}
.bg-color-suzy-grey-200 {
background-color: $suzy-grey-200;
}
.bg-color-suzy-grey-300 {
background-color: $suzy-grey-300;
}
.bg-color-suzy-grey-400 {
background-color: $suzy-grey-400;
}
.bg-color-suzy-grey-500 {
background-color: $suzy-grey-500;
}
.bg-color-suzy-grey-600 {
background-color: $suzy-grey-600;
}
.bg-color-suzy-grey-700 {
background-color: $suzy-grey-700;
}
.bg-color-suzy-grey-800 {
background-color: $suzy-grey-800;
}
.bg-color-suzy-grey-900 {
background-color: $suzy-grey-900;
}

// Suzy Purple
.bg-color-suzy-purple-50 {
background-color: $suzy-purple-50;
}
.bg-color-suzy-purple-100 {
background-color: $suzy-purple-100;
}
.bg-color-suzy-purple-200 {
background-color: $suzy-purple-200;
}
.bg-color-suzy-purple-300 {
background-color: $suzy-purple-300;
}
.bg-color-suzy-purple-400 {
background-color: $suzy-purple-400;
}
.bg-color-suzy-purple-500 {
background-color: $suzy-purple-500;
}
.bg-color-suzy-purple-600 {
background-color: $suzy-purple-600;
}
.bg-color-suzy-purple-700 {
background-color: $suzy-purple-700;
}
.bg-color-suzy-purple-800 {
background-color: $suzy-purple-800;
}
.bg-color-suzy-purple-900 {
background-color: $suzy-purple-900;
}

// Suzy Core Blue
.bg-color-suzy-core-blue-50 {
background-color: $suzy-core-blue-50;
}
.bg-color-suzy-core-blue-100 {
background-color: $suzy-core-blue-100;
}
.bg-color-suzy-core-blue-200 {
background-color: $suzy-core-blue-200;
}
.bg-color-suzy-core-blue-300 {
background-color: $suzy-core-blue-300;
}
.bg-color-suzy-core-blue-400 {
background-color: $suzy-core-blue-400;
}
.bg-color-suzy-core-blue-500 {
background-color: $suzy-core-blue-500;
}
.bg-color-suzy-core-blue-600 {
background-color: $suzy-core-blue-600;
}
.bg-color-suzy-core-blue-700 {
background-color: $suzy-core-blue-700;
}
.bg-color-suzy-core-blue-800 {
background-color: $suzy-core-blue-800;
}
.bg-color-suzy-core-blue-900 {
background-color: $suzy-core-blue-900;
}

// Suzy Red
.bg-color-suzy-red-50 {
background-color: $suzy-red-50;
}
.bg-color-suzy-red-100 {
background-color: $suzy-red-100;
}
.bg-color-suzy-red-200 {
background-color: $suzy-red-200;
}
.bg-color-suzy-red-300 {
background-color: $suzy-red-300;
}
.bg-color-suzy-red-400 {
background-color: $suzy-red-400;
}
.bg-color-suzy-red-500 {
background-color: $suzy-red-500;
}
.bg-color-suzy-red-600 {
background-color: $suzy-red-600;
}
.bg-color-suzy-red-700 {
background-color: $suzy-red-700;
}
.bg-color-suzy-red-800 {
background-color: $suzy-red-800;
}
.bg-color-suzy-red-900 {
background-color: $suzy-red-900;
}

// Suzy Warm
.bg-color-suzy-warm-50 {
background-color: $suzy-warm-50;
}
.bg-color-suzy-warm-100 {
background-color: $suzy-warm-100;
}
.bg-color-suzy-warm-200 {
background-color: $suzy-warm-200;
}
.bg-color-suzy-warm-300 {
background-color: $suzy-warm-300;
}
.bg-color-suzy-warm-400 {
background-color: $suzy-warm-400;
}
.bg-color-suzy-warm-500 {
background-color: $suzy-warm-500;
}
.bg-color-suzy-warm-600 {
background-color: $suzy-warm-600;
}
.bg-color-suzy-warm-700 {
background-color: $suzy-warm-700;
}
.bg-color-suzy-warm-800 {
background-color: $suzy-warm-800;
}
.bg-color-suzy-warm-900 {
background-color: $suzy-warm-900;
}

// Suzy Green
.bg-color-suzy-green-50 {
background-color: $suzy-green-50;
}
.bg-color-suzy-green-100 {
background-color: $suzy-green-100;
}
.bg-color-suzy-green-200 {
background-color: $suzy-green-200;
}
.bg-color-suzy-green-300 {
background-color: $suzy-green-300;
}
.bg-color-suzy-green-400 {
background-color: $suzy-green-400;
}
.bg-color-suzy-green-500 {
background-color: $suzy-green-500;
}
.bg-color-suzy-green-600 {
background-color: $suzy-green-600;
}
.bg-color-suzy-green-700 {
background-color: $suzy-green-700;
}
.bg-color-suzy-green-800 {
background-color: $suzy-green-800;
}
.bg-color-suzy-green-900 {
background-color: $suzy-green-900;
}

// Suzy Gradients
.bg-color-suzy-gradient-1 {
background-color: $suzy-gradient-1;
}
.bg-color-suzy-gradient-2 {
background-color: $suzy-gradient-2;
}
.bg-color-suzy-gradient-3 {
background-color: $suzy-gradient-3;
}
.bg-color-suzy-gradient-4 {
background-color: $suzy-gradient-4;
}

// Suzy Pink
.bg-color-suzy-pink-50 {
background-color: $suzy-pink-50;
}
.bg-color-suzy-pink-100 {
background-color: $suzy-pink-100;
}
.bg-color-suzy-pink-200 {
background-color: $suzy-pink-200;
}
.bg-color-suzy-pink-300 {
background-color: $suzy-pink-300;
}
.bg-color-suzy-pink-400 {
background-color: $suzy-pink-400;
}
.bg-color-suzy-pink-500 {
background-color: $suzy-pink-500;
}
.bg-color-suzy-pink-600 {
background-color: $suzy-pink-600;
}
.bg-color-suzy-pink-700 {
background-color: $suzy-pink-700;
}
.bg-color-suzy-pink-800 {
background-color: $suzy-pink-800;
}
.bg-color-suzy-pink-900 {
background-color: $suzy-pink-900;
}

// Outline 1px Color Classes
///////////////////////

// Suzy Black
.outline-suzy-black {
outline: 1px solid $suzy-black;
}

// Suzy White
.outline-suzy-white {
outline: 1px solid $suzy-white;
}

// Suzy Blue
.outline-suzy-blue-50 {
outline: 1px solid $suzy-blue-50;
}
.outline-suzy-blue-100 {
outline: 1px solid $suzy-blue-100;
}
.outline-suzy-blue-200 {
outline: 1px solid $suzy-blue-200;
}
.outline-suzy-blue-300 {
outline: 1px solid $suzy-blue-300;
}
.outline-suzy-blue-400 {
outline: 1px solid $suzy-blue-400;
}
.outline-suzy-blue-500 {
outline: 1px solid $suzy-blue-500;
}
.outline-suzy-blue-600 {
outline: 1px solid $suzy-blue-600;
}
.outline-suzy-blue-700 {
outline: 1px solid $suzy-blue-700;
}
.outline-suzy-blue-800 {
outline: 1px solid $suzy-blue-800;
}
.outline-suzy-blue-900 {
outline: 1px solid $suzy-blue-900;
}

// Suzy Grey
.outline-suzy-grey-50 {
outline: 1px solid $suzy-grey-50;
}
.outline-suzy-grey-100 {
outline: 1px solid $suzy-grey-100;
}
.outline-suzy-grey-200 {
outline: 1px solid $suzy-grey-200;
}
.outline-suzy-grey-300 {
outline: 1px solid $suzy-grey-300;
}
.outline-suzy-grey-400 {
outline: 1px solid $suzy-grey-400;
}
.outline-suzy-grey-500 {
outline: 1px solid $suzy-grey-500;
}
.outline-suzy-grey-600 {
outline: 1px solid $suzy-grey-600;
}
.outline-suzy-grey-700 {
outline: 1px solid $suzy-grey-700;
}
.outline-suzy-grey-800 {
outline: 1px solid $suzy-grey-800;
}
.outline-suzy-grey-900 {
outline: 1px solid $suzy-grey-900;
}

// Suzy Purple
.outline-suzy-purple-50 {
outline: 1px solid $suzy-purple-50;
}
.outline-suzy-purple-100 {
outline: 1px solid $suzy-purple-100;
}
.outline-suzy-purple-200 {
outline: 1px solid $suzy-purple-200;
}
.outline-suzy-purple-300 {
outline: 1px solid $suzy-purple-300;
}
.outline-suzy-purple-400 {
outline: 1px solid $suzy-purple-400;
}
.outline-suzy-purple-500 {
outline: 1px solid $suzy-purple-500;
}
.outline-suzy-purple-600 {
outline: 1px solid $suzy-purple-600;
}
.outline-suzy-purple-700 {
outline: 1px solid $suzy-purple-700;
}
.outline-suzy-purple-800 {
outline: 1px solid $suzy-purple-800;
}
.outline-suzy-purple-900 {
outline: 1px solid $suzy-purple-900;
}

// Suzy Core Blue
.outline-suzy-core-blue-50 {
outline: 1px solid $suzy-core-blue-50;
}
.outline-suzy-core-blue-100 {
outline: 1px solid $suzy-core-blue-100;
}
.outline-suzy-core-blue-200 {
outline: 1px solid $suzy-core-blue-200;
}
.outline-suzy-core-blue-300 {
outline: 1px solid $suzy-core-blue-300;
}
.outline-suzy-core-blue-400 {
outline: 1px solid $suzy-core-blue-400;
}
.outline-suzy-core-blue-500 {
outline: 1px solid $suzy-core-blue-500;
}
.outline-suzy-core-blue-600 {
outline: 1px solid $suzy-core-blue-600;
}
.outline-suzy-core-blue-700 {
outline: 1px solid $suzy-core-blue-700;
}
.outline-suzy-core-blue-800 {
outline: 1px solid $suzy-core-blue-800;
}
.outline-suzy-core-blue-900 {
outline: 1px solid $suzy-core-blue-900;
}

// Suzy Red
.outline-suzy-red-50 {
outline: 1px solid $suzy-red-50;
}
.outline-suzy-red-100 {
outline: 1px solid $suzy-red-100;
}
.outline-suzy-red-200 {
outline: 1px solid $suzy-red-200;
}
.outline-suzy-red-300 {
outline: 1px solid $suzy-red-300;
}
.outline-suzy-red-400 {
outline: 1px solid $suzy-red-400;
}
.outline-suzy-red-500 {
outline: 1px solid $suzy-red-500;
}
.outline-suzy-red-600 {
outline: 1px solid $suzy-red-600;
}
.outline-suzy-red-700 {
outline: 1px solid $suzy-red-700;
}
.outline-suzy-red-800 {
outline: 1px solid $suzy-red-800;
}
.outline-suzy-red-900 {
outline: 1px solid $suzy-red-900;
}

// Suzy Warm
.outline-suzy-warm-50 {
outline: 1px solid $suzy-warm-50;
}
.outline-suzy-warm-100 {
outline: 1px solid $suzy-warm-100;
}
.outline-suzy-warm-200 {
outline: 1px solid $suzy-warm-200;
}
.outline-suzy-warm-300 {
outline: 1px solid $suzy-warm-300;
}
.outline-suzy-warm-400 {
outline: 1px solid $suzy-warm-400;
}
.outline-suzy-warm-500 {
outline: 1px solid $suzy-warm-500;
}
.outline-suzy-warm-600 {
outline: 1px solid $suzy-warm-600;
}
.outline-suzy-warm-700 {
outline: 1px solid $suzy-warm-700;
}
.outline-suzy-warm-800 {
outline: 1px solid $suzy-warm-800;
}
.outline-suzy-warm-900 {
outline: 1px solid $suzy-warm-900;
}

// Suzy Green
.outline-suzy-green-50 {
outline: 1px solid $suzy-green-50;
}
.outline-suzy-green-100 {
outline: 1px solid $suzy-green-100;
}
.outline-suzy-green-200 {
outline: 1px solid $suzy-green-200;
}
.outline-suzy-green-300 {
outline: 1px solid $suzy-green-300;
}
.outline-suzy-green-400 {
outline: 1px solid $suzy-green-400;
}
.outline-suzy-green-500 {
outline: 1px solid $suzy-green-500;
}
.outline-suzy-green-600 {
outline: 1px solid $suzy-green-600;
}
.outline-suzy-green-700 {
outline: 1px solid $suzy-green-700;
}
.outline-suzy-green-800 {
outline: 1px solid $suzy-green-800;
}
.outline-suzy-green-900 {
outline: 1px solid $suzy-green-900;
}

// Suzy Pink
.outline-suzy-pink-50 {
outline: 1px solid $suzy-pink-50;
}
.outline-suzy-pink-100 {
outline: 1px solid $suzy-pink-100;
}
.outline-suzy-pink-200 {
outline: 1px solid $suzy-pink-200;
}
.outline-suzy-pink-300 {
outline: 1px solid $suzy-pink-300;
}
.outline-suzy-pink-400 {
outline: 1px solid $suzy-pink-400;
}
.outline-suzy-pink-500 {
outline: 1px solid $suzy-pink-500;
}
.outline-suzy-pink-600 {
outline: 1px solid $suzy-pink-600;
}
.outline-suzy-pink-700 {
outline: 1px solid $suzy-pink-700;
}
.outline-suzy-pink-800 {
outline: 1px solid $suzy-pink-800;
}
.outline-suzy-pink-900 {
outline: 1px solid $suzy-pink-900;
}

// Outline 2px Color Classes
///////////////////////

// Suzy Black
.outline-medium-suzy-black {
outline: 2px solid $suzy-black;
}

// Suzy White
.outline-medium-suzy-white {
outline: 2px solid $suzy-white;
}

// Suzy Blue
.outline-medium-suzy-blue-50 {
outline: 2px solid $suzy-blue-50;
}
.outline-medium-suzy-blue-100 {
outline: 2px solid $suzy-blue-100;
}
.outline-medium-suzy-blue-200 {
outline: 2px solid $suzy-blue-200;
}
.outline-medium-suzy-blue-300 {
outline: 2px solid $suzy-blue-300;
}
.outline-medium-suzy-blue-400 {
outline: 2px solid $suzy-blue-400;
}
.outline-medium-suzy-blue-500 {
outline: 2px solid $suzy-blue-500;
}
.outline-medium-suzy-blue-600 {
outline: 2px solid $suzy-blue-600;
}
.outline-medium-suzy-blue-700 {
outline: 2px solid $suzy-blue-700;
}
.outline-medium-suzy-blue-800 {
outline: 2px solid $suzy-blue-800;
}
.outline-medium-suzy-blue-900 {
outline: 2px solid $suzy-blue-900;
}

// Suzy Grey
.outline-medium-suzy-grey-50 {
outline: 2px solid $suzy-grey-50;
}
.outline-medium-suzy-grey-100 {
outline: 2px solid $suzy-grey-100;
}
.outline-medium-suzy-grey-200 {
outline: 2px solid $suzy-grey-200;
}
.outline-medium-suzy-grey-300 {
outline: 2px solid $suzy-grey-300;
}
.outline-medium-suzy-grey-400 {
outline: 2px solid $suzy-grey-400;
}
.outline-medium-suzy-grey-500 {
outline: 2px solid $suzy-grey-500;
}
.outline-medium-suzy-grey-600 {
outline: 2px solid $suzy-grey-600;
}
.outline-medium-suzy-grey-700 {
outline: 2px solid $suzy-grey-700;
}
.outline-medium-suzy-grey-800 {
outline: 2px solid $suzy-grey-800;
}
.outline-medium-suzy-grey-900 {
outline: 2px solid $suzy-grey-900;
}

// Suzy Purple
.outline-medium-suzy-purple-50 {
outline: 2px solid $suzy-purple-50;
}
.outline-medium-suzy-purple-100 {
outline: 2px solid $suzy-purple-100;
}
.outline-medium-suzy-purple-200 {
outline: 2px solid $suzy-purple-200;
}
.outline-medium-suzy-purple-300 {
outline: 2px solid $suzy-purple-300;
}
.outline-medium-suzy-purple-400 {
outline: 2px solid $suzy-purple-400;
}
.outline-medium-suzy-purple-500 {
outline: 2px solid $suzy-purple-500;
}
.outline-medium-suzy-purple-600 {
outline: 2px solid $suzy-purple-600;
}
.outline-medium-suzy-purple-700 {
outline: 2px solid $suzy-purple-700;
}
.outline-medium-suzy-purple-800 {
outline: 2px solid $suzy-purple-800;
}
.outline-medium-suzy-purple-900 {
outline: 2px solid $suzy-purple-900;
}

// Suzy Core Blue
.outline-medium-suzy-core-blue-50 {
outline: 2px solid $suzy-core-blue-50;
}
.outline-medium-suzy-core-blue-100 {
outline: 2px solid $suzy-core-blue-100;
}
.outline-medium-suzy-core-blue-200 {
outline: 2px solid $suzy-core-blue-200;
}
.outline-medium-suzy-core-blue-300 {
outline: 2px solid $suzy-core-blue-300;
}
.outline-medium-suzy-core-blue-400 {
outline: 2px solid $suzy-core-blue-400;
}
.outline-medium-suzy-core-blue-500 {
outline: 2px solid $suzy-core-blue-500;
}
.outline-medium-suzy-core-blue-600 {
outline: 2px solid $suzy-core-blue-600;
}
.outline-medium-suzy-core-blue-700 {
outline: 2px solid $suzy-core-blue-700;
}
.outline-medium-suzy-core-blue-800 {
outline: 2px solid $suzy-core-blue-800;
}
.outline-medium-suzy-core-blue-900 {
outline: 2px solid $suzy-core-blue-900;
}

// Suzy Red
.outline-medium-suzy-red-50 {
outline: 2px solid $suzy-red-50;
}
.outline-medium-suzy-red-100 {
outline: 2px solid $suzy-red-100;
}
.outline-medium-suzy-red-200 {
outline: 2px solid $suzy-red-200;
}
.outline-medium-suzy-red-300 {
outline: 2px solid $suzy-red-300;
}
.outline-medium-suzy-red-400 {
outline: 2px solid $suzy-red-400;
}
.outline-medium-suzy-red-500 {
outline: 2px solid $suzy-red-500;
}
.outline-medium-suzy-red-600 {
outline: 2px solid $suzy-red-600;
}
.outline-medium-suzy-red-700 {
outline: 2px solid $suzy-red-700;
}
.outline-medium-suzy-red-800 {
outline: 2px solid $suzy-red-800;
}
.outline-medium-suzy-red-900 {
outline: 2px solid $suzy-red-900;
}

// Suzy Warm
.outline-medium-suzy-warm-50 {
outline: 2px solid $suzy-warm-50;
}
.outline-medium-suzy-warm-100 {
outline: 2px solid $suzy-warm-100;
}
.outline-medium-suzy-warm-200 {
outline: 2px solid $suzy-warm-200;
}
.outline-medium-suzy-warm-300 {
outline: 2px solid $suzy-warm-300;
}
.outline-medium-suzy-warm-400 {
outline: 2px solid $suzy-warm-400;
}
.outline-medium-suzy-warm-500 {
outline: 2px solid $suzy-warm-500;
}
.outline-medium-suzy-warm-600 {
outline: 2px solid $suzy-warm-600;
}
.outline-medium-suzy-warm-700 {
outline: 2px solid $suzy-warm-700;
}
.outline-medium-suzy-warm-800 {
outline: 2px solid $suzy-warm-800;
}
.outline-medium-suzy-warm-900 {
outline: 2px solid $suzy-warm-900;
}

// Suzy Green
.outline-medium-suzy-green-50 {
outline: 2px solid $suzy-green-50;
}
.outline-medium-suzy-green-100 {
outline: 2px solid $suzy-green-100;
}
.outline-medium-suzy-green-200 {
outline: 2px solid $suzy-green-200;
}
.outline-medium-suzy-green-300 {
outline: 2px solid $suzy-green-300;
}
.outline-medium-suzy-green-400 {
outline: 2px solid $suzy-green-400;
}
.outline-medium-suzy-green-500 {
outline: 2px solid $suzy-green-500;
}
.outline-medium-suzy-green-600 {
outline: 2px solid $suzy-green-600;
}
.outline-medium-suzy-green-700 {
outline: 2px solid $suzy-green-700;
}
.outline-medium-suzy-green-800 {
outline: 2px solid $suzy-green-800;
}
.outline-medium-suzy-green-900 {
outline: 2px solid $suzy-green-900;
}

// Suzy Pink
.outline-medium-suzy-pink-50 {
outline: 2px solid $suzy-pink-50;
}
.outline-medium-suzy-pink-100 {
outline: 2px solid $suzy-pink-100;
}
.outline-medium-suzy-pink-200 {
outline: 2px solid $suzy-pink-200;
}
.outline-medium-suzy-pink-300 {
outline: 2px solid $suzy-pink-300;
}
.outline-medium-suzy-pink-400 {
outline: 2px solid $suzy-pink-400;
}
.outline-medium-suzy-pink-500 {
outline: 2px solid $suzy-pink-500;
}
.outline-medium-suzy-pink-600 {
outline: 2px solid $suzy-pink-600;
}
.outline-medium-suzy-pink-700 {
outline: 2px solid $suzy-pink-700;
}
.outline-medium-suzy-pink-800 {
outline: 2px solid $suzy-pink-800;
}
.outline-medium-suzy-pink-900 {
outline: 2px solid $suzy-pink-900;
}

// Border Color Classes
///////////////////////

// Suzy Black
.border-suzy-black {
border: 1px solid $suzy-black;
}

// Suzy White
.border-suzy-white {
border: 1px solid $suzy-white;
}

// Suzy Blue
.border-suzy-blue-50 {
border: 1px solid $suzy-blue-50;
}
.border-suzy-blue-100 {
border: 1px solid $suzy-blue-100;
}
.border-suzy-blue-200 {
border: 1px solid $suzy-blue-200;
}
.border-suzy-blue-300 {
border: 1px solid $suzy-blue-300;
}
.border-suzy-blue-400 {
border: 1px solid $suzy-blue-400;
}
.border-suzy-blue-500 {
border: 1px solid $suzy-blue-500;
}
.border-suzy-blue-600 {
border: 1px solid $suzy-blue-600;
}
.border-suzy-blue-700 {
border: 1px solid $suzy-blue-700;
}
.border-suzy-blue-800 {
border: 1px solid $suzy-blue-800;
}
.border-suzy-blue-900 {
border: 1px solid $suzy-blue-900;
}

// Suzy Grey
.border-suzy-grey-50 {
border: 1px solid $suzy-grey-50;
}
.border-suzy-grey-100 {
border: 1px solid $suzy-grey-100;
}
.border-suzy-grey-200 {
border: 1px solid $suzy-grey-200;
}
.border-suzy-grey-300 {
border: 1px solid $suzy-grey-300;
}
.border-suzy-grey-400 {
border: 1px solid $suzy-grey-400;
}
.border-suzy-grey-500 {
border: 1px solid $suzy-grey-500;
}
.border-suzy-grey-600 {
border: 1px solid $suzy-grey-600;
}
.border-suzy-grey-700 {
border: 1px solid $suzy-grey-700;
}
.border-suzy-grey-800 {
border: 1px solid $suzy-grey-800;
}
.border-suzy-grey-900 {
border: 1px solid $suzy-grey-900;
}

// Suzy Purple
.border-suzy-purple-50 {
border: 1px solid $suzy-purple-50;
}
.border-suzy-purple-100 {
border: 1px solid $suzy-purple-100;
}
.border-suzy-purple-200 {
border: 1px solid $suzy-purple-200;
}
.border-suzy-purple-300 {
border: 1px solid $suzy-purple-300;
}
.border-suzy-purple-400 {
border: 1px solid $suzy-purple-400;
}
.border-suzy-purple-500 {
border: 1px solid $suzy-purple-500;
}
.border-suzy-purple-600 {
border: 1px solid $suzy-purple-600;
}
.border-suzy-purple-700 {
border: 1px solid $suzy-purple-700;
}
.border-suzy-purple-800 {
border: 1px solid $suzy-purple-800;
}
.border-suzy-purple-900 {
border: 1px solid $suzy-purple-900;
}

// Suzy Core Blue
.border-suzy-core-blue-50 {
border: 1px solid $suzy-core-blue-50;
}
.border-suzy-core-blue-100 {
border: 1px solid $suzy-core-blue-100;
}
.border-suzy-core-blue-200 {
border: 1px solid $suzy-core-blue-200;
}
.border-suzy-core-blue-300 {
border: 1px solid $suzy-core-blue-300;
}
.border-suzy-core-blue-400 {
border: 1px solid $suzy-core-blue-400;
}
.border-suzy-core-blue-500 {
border: 1px solid $suzy-core-blue-500;
}
.border-suzy-core-blue-600 {
border: 1px solid $suzy-core-blue-600;
}
.border-suzy-core-blue-700 {
border: 1px solid $suzy-core-blue-700;
}
.border-suzy-core-blue-800 {
border: 1px solid $suzy-core-blue-800;
}
.border-suzy-core-blue-900 {
border: 1px solid $suzy-core-blue-900;
}

// Suzy Red
.border-suzy-red-50 {
border: 1px solid $suzy-red-50;
}
.border-suzy-red-100 {
border: 1px solid $suzy-red-100;
}
.border-suzy-red-200 {
border: 1px solid $suzy-red-200;
}
.border-suzy-red-300 {
border: 1px solid $suzy-red-300;
}
.border-suzy-red-400 {
border: 1px solid $suzy-red-400;
}
.border-suzy-red-500 {
border: 1px solid $suzy-red-500;
}
.border-suzy-red-600 {
border: 1px solid $suzy-red-600;
}
.border-suzy-red-700 {
border: 1px solid $suzy-red-700;
}
.border-suzy-red-800 {
border: 1px solid $suzy-red-800;
}
.border-suzy-red-900 {
border: 1px solid $suzy-red-900;
}

// Suzy Warm
.border-suzy-warm-50 {
border: 1px solid $suzy-warm-50;
}
.border-suzy-warm-100 {
border: 1px solid $suzy-warm-100;
}
.border-suzy-warm-200 {
border: 1px solid $suzy-warm-200;
}
.border-suzy-warm-300 {
border: 1px solid $suzy-warm-300;
}
.border-suzy-warm-400 {
border: 1px solid $suzy-warm-400;
}
.border-suzy-warm-500 {
border: 1px solid $suzy-warm-500;
}
.border-suzy-warm-600 {
border: 1px solid $suzy-warm-600;
}
.border-suzy-warm-700 {
border: 1px solid $suzy-warm-700;
}
.border-suzy-warm-800 {
border: 1px solid $suzy-warm-800;
}
.border-suzy-warm-900 {
border: 1px solid $suzy-warm-900;
}

// Suzy Green
.border-suzy-green-50 {
border: 1px solid $suzy-green-50;
}
.border-suzy-green-100 {
border: 1px solid $suzy-green-100;
}
.border-suzy-green-200 {
border: 1px solid $suzy-green-200;
}
.border-suzy-green-300 {
border: 1px solid $suzy-green-300;
}
.border-suzy-green-400 {
border: 1px solid $suzy-green-400;
}
.border-suzy-green-500 {
border: 1px solid $suzy-green-500;
}
.border-suzy-green-600 {
border: 1px solid $suzy-green-600;
}
.border-suzy-green-700 {
border: 1px solid $suzy-green-700;
}
.border-suzy-green-800 {
border: 1px solid $suzy-green-800;
}
.border-suzy-green-900 {
border: 1px solid $suzy-green-900;
}

// Suzy Pink
.border-suzy-pink-50 {
border: 1px solid $suzy-pink-50;
}
.border-suzy-pink-100 {
border: 1px solid $suzy-pink-100;
}
.border-suzy-pink-200 {
border: 1px solid $suzy-pink-200;
}
.border-suzy-pink-300 {
border: 1px solid $suzy-pink-300;
}
.border-suzy-pink-400 {
border: 1px solid $suzy-pink-400;
}
.border-suzy-pink-500 {
border: 1px solid $suzy-pink-500;
}
.border-suzy-pink-600 {
border: 1px solid $suzy-pink-600;
}
.border-suzy-pink-700 {
border: 1px solid $suzy-pink-700;
}
.border-suzy-pink-800 {
border: 1px solid $suzy-pink-800;
}
.border-suzy-pink-900 {
border: 1px solid $suzy-pink-900;
}

// Border Top Color Classes
///////////////////////

// Suzy Black
.border-top-suzy-black {
border-top: 1px solid $suzy-black;
}

// Suzy White
.border-top-suzy-white {
border-top: 1px solid $suzy-white;
}

// Suzy Blue
.border-top-suzy-blue-50 {
border-top: 1px solid $suzy-blue-50;
}
.border-top-suzy-blue-100 {
border-top: 1px solid $suzy-blue-100;
}
.border-top-suzy-blue-200 {
border-top: 1px solid $suzy-blue-200;
}
.border-top-suzy-blue-300 {
border-top: 1px solid $suzy-blue-300;
}
.border-top-suzy-blue-400 {
border-top: 1px solid $suzy-blue-400;
}
.border-top-suzy-blue-500 {
border-top: 1px solid $suzy-blue-500;
}
.border-top-suzy-blue-600 {
border-top: 1px solid $suzy-blue-600;
}
.border-top-suzy-blue-700 {
border-top: 1px solid $suzy-blue-700;
}
.border-top-suzy-blue-800 {
border-top: 1px solid $suzy-blue-800;
}
.border-top-suzy-blue-900 {
border-top: 1px solid $suzy-blue-900;
}

// Suzy Grey
.border-top-suzy-grey-50 {
border-top: 1px solid $suzy-grey-50;
}
.border-top-suzy-grey-100 {
border-top: 1px solid $suzy-grey-100;
}
.border-top-suzy-grey-200 {
border-top: 1px solid $suzy-grey-200;
}
.border-top-suzy-grey-300 {
border-top: 1px solid $suzy-grey-300;
}
.border-top-suzy-grey-400 {
border-top: 1px solid $suzy-grey-400;
}
.border-top-suzy-grey-500 {
border-top: 1px solid $suzy-grey-500;
}
.border-top-suzy-grey-600 {
border-top: 1px solid $suzy-grey-600;
}
.border-top-suzy-grey-700 {
border-top: 1px solid $suzy-grey-700;
}
.border-top-suzy-grey-800 {
border-top: 1px solid $suzy-grey-800;
}
.border-top-suzy-grey-900 {
border-top: 1px solid $suzy-grey-900;
}

// Suzy Purple
.border-top-suzy-purple-50 {
border-top: 1px solid $suzy-purple-50;
}
.border-top-suzy-purple-100 {
border-top: 1px solid $suzy-purple-100;
}
.border-top-suzy-purple-200 {
border-top: 1px solid $suzy-purple-200;
}
.border-top-suzy-purple-300 {
border-top: 1px solid $suzy-purple-300;
}
.border-top-suzy-purple-400 {
border-top: 1px solid $suzy-purple-400;
}
.border-top-suzy-purple-500 {
border-top: 1px solid $suzy-purple-500;
}
.border-top-suzy-purple-600 {
border-top: 1px solid $suzy-purple-600;
}
.border-top-suzy-purple-700 {
border-top: 1px solid $suzy-purple-700;
}
.border-top-suzy-purple-800 {
border-top: 1px solid $suzy-purple-800;
}
.border-top-suzy-purple-900 {
border-top: 1px solid $suzy-purple-900;
}

// Suzy Core Blue
.border-top-suzy-core-blue-50 {
border-top: 1px solid $suzy-core-blue-50;
}
.border-top-suzy-core-blue-100 {
border-top: 1px solid $suzy-core-blue-100;
}
.border-top-suzy-core-blue-200 {
border-top: 1px solid $suzy-core-blue-200;
}
.border-top-suzy-core-blue-300 {
border-top: 1px solid $suzy-core-blue-300;
}
.border-top-suzy-core-blue-400 {
border-top: 1px solid $suzy-core-blue-400;
}
.border-top-suzy-core-blue-500 {
border-top: 1px solid $suzy-core-blue-500;
}
.border-top-suzy-core-blue-600 {
border-top: 1px solid $suzy-core-blue-600;
}
.border-top-suzy-core-blue-700 {
border-top: 1px solid $suzy-core-blue-700;
}
.border-top-suzy-core-blue-800 {
border-top: 1px solid $suzy-core-blue-800;
}
.border-top-suzy-core-blue-900 {
border-top: 1px solid $suzy-core-blue-900;
}

// Suzy Red
.border-top-suzy-red-50 {
border-top: 1px solid $suzy-red-50;
}
.border-top-suzy-red-100 {
border-top: 1px solid $suzy-red-100;
}
.border-top-suzy-red-200 {
border-top: 1px solid $suzy-red-200;
}
.border-top-suzy-red-300 {
border-top: 1px solid $suzy-red-300;
}
.border-top-suzy-red-400 {
border-top: 1px solid $suzy-red-400;
}
.border-top-suzy-red-500 {
border-top: 1px solid $suzy-red-500;
}
.border-top-suzy-red-600 {
border-top: 1px solid $suzy-red-600;
}
.border-top-suzy-red-700 {
border-top: 1px solid $suzy-red-700;
}
.border-top-suzy-red-800 {
border-top: 1px solid $suzy-red-800;
}
.border-top-suzy-red-900 {
border-top: 1px solid $suzy-red-900;
}

// Suzy Warm
.border-top-suzy-warm-50 {
border-top: 1px solid $suzy-warm-50;
}
.border-top-suzy-warm-100 {
border-top: 1px solid $suzy-warm-100;
}
.border-top-suzy-warm-200 {
border-top: 1px solid $suzy-warm-200;
}
.border-top-suzy-warm-300 {
border-top: 1px solid $suzy-warm-300;
}
.border-top-suzy-warm-400 {
border-top: 1px solid $suzy-warm-400;
}
.border-top-suzy-warm-500 {
border-top: 1px solid $suzy-warm-500;
}
.border-top-suzy-warm-600 {
border-top: 1px solid $suzy-warm-600;
}
.border-top-suzy-warm-700 {
border-top: 1px solid $suzy-warm-700;
}
.border-top-suzy-warm-800 {
border-top: 1px solid $suzy-warm-800;
}
.border-top-suzy-warm-900 {
border-top: 1px solid $suzy-warm-900;
}

// Suzy Green
.border-top-suzy-green-50 {
border-top: 1px solid $suzy-green-50;
}
.border-top-suzy-green-100 {
border-top: 1px solid $suzy-green-100;
}
.border-top-suzy-green-200 {
border-top: 1px solid $suzy-green-200;
}
.border-top-suzy-green-300 {
border-top: 1px solid $suzy-green-300;
}
.border-top-suzy-green-400 {
border-top: 1px solid $suzy-green-400;
}
.border-top-suzy-green-500 {
border-top: 1px solid $suzy-green-500;
}
.border-top-suzy-green-600 {
border-top: 1px solid $suzy-green-600;
}
.border-top-suzy-green-700 {
border-top: 1px solid $suzy-green-700;
}
.border-top-suzy-green-800 {
border-top: 1px solid $suzy-green-800;
}
.border-top-suzy-green-900 {
border-top: 1px solid $suzy-green-900;
}

// Suzy Pink
.border-top-suzy-pink-50 {
border-top: 1px solid $suzy-pink-50;
}
.border-top-suzy-pink-100 {
border-top: 1px solid $suzy-pink-100;
}
.border-top-suzy-pink-200 {
border-top: 1px solid $suzy-pink-200;
}
.border-top-suzy-pink-300 {
border-top: 1px solid $suzy-pink-300;
}
.border-top-suzy-pink-400 {
border-top: 1px solid $suzy-pink-400;
}
.border-top-suzy-pink-500 {
border-top: 1px solid $suzy-pink-500;
}
.border-top-suzy-pink-600 {
border-top: 1px solid $suzy-pink-600;
}
.border-top-suzy-pink-700 {
border-top: 1px solid $suzy-pink-700;
}
.border-top-suzy-pink-800 {
border-top: 1px solid $suzy-pink-800;
}
.border-top-suzy-pink-900 {
border-top: 1px solid $suzy-pink-900;
}

// Border Right Color Classes
///////////////////////

// Suzy Black
.border-right-suzy-black {
border-right: 1px solid $suzy-black;
}

// Suzy White
.border-right-suzy-white {
border-right: 1px solid $suzy-white;
}

// Suzy Blue
.border-right-suzy-blue-50 {
border-right: 1px solid $suzy-blue-50;
}
.border-right-suzy-blue-100 {
border-right: 1px solid $suzy-blue-100;
}
.border-right-suzy-blue-200 {
border-right: 1px solid $suzy-blue-200;
}
.border-right-suzy-blue-300 {
border-right: 1px solid $suzy-blue-300;
}
.border-right-suzy-blue-400 {
border-right: 1px solid $suzy-blue-400;
}
.border-right-suzy-blue-500 {
border-right: 1px solid $suzy-blue-500;
}
.border-right-suzy-blue-600 {
border-right: 1px solid $suzy-blue-600;
}
.border-right-suzy-blue-700 {
border-right: 1px solid $suzy-blue-700;
}
.border-right-suzy-blue-800 {
border-right: 1px solid $suzy-blue-800;
}
.border-right-suzy-blue-900 {
border-right: 1px solid $suzy-blue-900;
}

// Suzy Grey
.border-right-suzy-grey-50 {
border-right: 1px solid $suzy-grey-50;
}
.border-right-suzy-grey-100 {
border-right: 1px solid $suzy-grey-100;
}
.border-right-suzy-grey-200 {
border-right: 1px solid $suzy-grey-200;
}
.border-right-suzy-grey-300 {
border-right: 1px solid $suzy-grey-300;
}
.border-right-suzy-grey-400 {
border-right: 1px solid $suzy-grey-400;
}
.border-right-suzy-grey-500 {
border-right: 1px solid $suzy-grey-500;
}
.border-right-suzy-grey-600 {
border-right: 1px solid $suzy-grey-600;
}
.border-right-suzy-grey-700 {
border-right: 1px solid $suzy-grey-700;
}
.border-right-suzy-grey-800 {
border-right: 1px solid $suzy-grey-800;
}
.border-right-suzy-grey-900 {
border-right: 1px solid $suzy-grey-900;
}

// Suzy Purple
.border-right-suzy-purple-50 {
border-right: 1px solid $suzy-purple-50;
}
.border-right-suzy-purple-100 {
border-right: 1px solid $suzy-purple-100;
}
.border-right-suzy-purple-200 {
border-right: 1px solid $suzy-purple-200;
}
.border-right-suzy-purple-300 {
border-right: 1px solid $suzy-purple-300;
}
.border-right-suzy-purple-400 {
border-right: 1px solid $suzy-purple-400;
}
.border-right-suzy-purple-500 {
border-right: 1px solid $suzy-purple-500;
}
.border-right-suzy-purple-600 {
border-right: 1px solid $suzy-purple-600;
}
.border-right-suzy-purple-700 {
border-right: 1px solid $suzy-purple-700;
}
.border-right-suzy-purple-800 {
border-right: 1px solid $suzy-purple-800;
}
.border-right-suzy-purple-900 {
border-right: 1px solid $suzy-purple-900;
}

// Suzy Core Blue
.border-right-suzy-core-blue-50 {
border-right: 1px solid $suzy-core-blue-50;
}
.border-right-suzy-core-blue-100 {
border-right: 1px solid $suzy-core-blue-100;
}
.border-right-suzy-core-blue-200 {
border-right: 1px solid $suzy-core-blue-200;
}
.border-right-suzy-core-blue-300 {
border-right: 1px solid $suzy-core-blue-300;
}
.border-right-suzy-core-blue-400 {
border-right: 1px solid $suzy-core-blue-400;
}
.border-right-suzy-core-blue-500 {
border-right: 1px solid $suzy-core-blue-500;
}
.border-right-suzy-core-blue-600 {
border-right: 1px solid $suzy-core-blue-600;
}
.border-right-suzy-core-blue-700 {
border-right: 1px solid $suzy-core-blue-700;
}
.border-right-suzy-core-blue-800 {
border-right: 1px solid $suzy-core-blue-800;
}
.border-right-suzy-core-blue-900 {
border-right: 1px solid $suzy-core-blue-900;
}

// Suzy Red
.border-right-suzy-red-50 {
border-right: 1px solid $suzy-red-50;
}
.border-right-suzy-red-100 {
border-right: 1px solid $suzy-red-100;
}
.border-right-suzy-red-200 {
border-right: 1px solid $suzy-red-200;
}
.border-right-suzy-red-300 {
border-right: 1px solid $suzy-red-300;
}
.border-right-suzy-red-400 {
border-right: 1px solid $suzy-red-400;
}
.border-right-suzy-red-500 {
border-right: 1px solid $suzy-red-500;
}
.border-right-suzy-red-600 {
border-right: 1px solid $suzy-red-600;
}
.border-right-suzy-red-700 {
border-right: 1px solid $suzy-red-700;
}
.border-right-suzy-red-800 {
border-right: 1px solid $suzy-red-800;
}
.border-right-suzy-red-900 {
border-right: 1px solid $suzy-red-900;
}

// Suzy Warm
.border-right-suzy-warm-50 {
border-right: 1px solid $suzy-warm-50;
}
.border-right-suzy-warm-100 {
border-right: 1px solid $suzy-warm-100;
}
.border-right-suzy-warm-200 {
border-right: 1px solid $suzy-warm-200;
}
.border-right-suzy-warm-300 {
border-right: 1px solid $suzy-warm-300;
}
.border-right-suzy-warm-400 {
border-right: 1px solid $suzy-warm-400;
}
.border-right-suzy-warm-500 {
border-right: 1px solid $suzy-warm-500;
}
.border-right-suzy-warm-600 {
border-right: 1px solid $suzy-warm-600;
}
.border-right-suzy-warm-700 {
border-right: 1px solid $suzy-warm-700;
}
.border-right-suzy-warm-800 {
border-right: 1px solid $suzy-warm-800;
}
.border-right-suzy-warm-900 {
border-right: 1px solid $suzy-warm-900;
}

// Suzy Green
.border-right-suzy-green-50 {
border-right: 1px solid $suzy-green-50;
}
.border-right-suzy-green-100 {
border-right: 1px solid $suzy-green-100;
}
.border-right-suzy-green-200 {
border-right: 1px solid $suzy-green-200;
}
.border-right-suzy-green-300 {
border-right: 1px solid $suzy-green-300;
}
.border-right-suzy-green-400 {
border-right: 1px solid $suzy-green-400;
}
.border-right-suzy-green-500 {
border-right: 1px solid $suzy-green-500;
}
.border-right-suzy-green-600 {
border-right: 1px solid $suzy-green-600;
}
.border-right-suzy-green-700 {
border-right: 1px solid $suzy-green-700;
}
.border-right-suzy-green-800 {
border-right: 1px solid $suzy-green-800;
}
.border-right-suzy-green-900 {
border-right: 1px solid $suzy-green-900;
}

// Suzy Pink
.border-right-suzy-pink-50 {
border-right: 1px solid $suzy-pink-50;
}
.border-right-suzy-pink-100 {
border-right: 1px solid $suzy-pink-100;
}
.border-right-suzy-pink-200 {
border-right: 1px solid $suzy-pink-200;
}
.border-right-suzy-pink-300 {
border-right: 1px solid $suzy-pink-300;
}
.border-right-suzy-pink-400 {
border-right: 1px solid $suzy-pink-400;
}
.border-right-suzy-pink-500 {
border-right: 1px solid $suzy-pink-500;
}
.border-right-suzy-pink-600 {
border-right: 1px solid $suzy-pink-600;
}
.border-right-suzy-pink-700 {
border-right: 1px solid $suzy-pink-700;
}
.border-right-suzy-pink-800 {
border-right: 1px solid $suzy-pink-800;
}
.border-right-suzy-pink-900 {
border-right: 1px solid $suzy-pink-900;
}

// Border Bottom Color Classes
///////////////////////

// Suzy Black
.border-bottom-suzy-black {
border-bottom: 1px solid $suzy-black;
}

// Suzy White
.border-bottom-suzy-white {
border-bottom: 1px solid $suzy-white;
}

// Suzy Blue
.border-bottom-suzy-blue-50 {
border-bottom: 1px solid $suzy-blue-50;
}
.border-bottom-suzy-blue-100 {
border-bottom: 1px solid $suzy-blue-100;
}
.border-bottom-suzy-blue-200 {
border-bottom: 1px solid $suzy-blue-200;
}
.border-bottom-suzy-blue-300 {
border-bottom: 1px solid $suzy-blue-300;
}
.border-bottom-suzy-blue-400 {
border-bottom: 1px solid $suzy-blue-400;
}
.border-bottom-suzy-blue-500 {
border-bottom: 1px solid $suzy-blue-500;
}
.border-bottom-suzy-blue-600 {
border-bottom: 1px solid $suzy-blue-600;
}
.border-bottom-suzy-blue-700 {
border-bottom: 1px solid $suzy-blue-700;
}
.border-bottom-suzy-blue-800 {
border-bottom: 1px solid $suzy-blue-800;
}
.border-bottom-suzy-blue-900 {
border-bottom: 1px solid $suzy-blue-900;
}

// Suzy Grey
.border-bottom-suzy-grey-50 {
border-bottom: 1px solid $suzy-grey-50;
}
.border-bottom-suzy-grey-100 {
border-bottom: 1px solid $suzy-grey-100;
}
.border-bottom-suzy-grey-200 {
border-bottom: 1px solid $suzy-grey-200;
}
.border-bottom-suzy-grey-300 {
border-bottom: 1px solid $suzy-grey-300;
}
.border-bottom-suzy-grey-400 {
border-bottom: 1px solid $suzy-grey-400;
}
.border-bottom-suzy-grey-500 {
border-bottom: 1px solid $suzy-grey-500;
}
.border-bottom-suzy-grey-600 {
border-bottom: 1px solid $suzy-grey-600;
}
.border-bottom-suzy-grey-700 {
border-bottom: 1px solid $suzy-grey-700;
}
.border-bottom-suzy-grey-800 {
border-bottom: 1px solid $suzy-grey-800;
}
.border-bottom-suzy-grey-900 {
border-bottom: 1px solid $suzy-grey-900;
}

// Suzy Purple
.border-bottom-suzy-purple-50 {
border-bottom: 1px solid $suzy-purple-50;
}
.border-bottom-suzy-purple-100 {
border-bottom: 1px solid $suzy-purple-100;
}
.border-bottom-suzy-purple-200 {
border-bottom: 1px solid $suzy-purple-200;
}
.border-bottom-suzy-purple-300 {
border-bottom: 1px solid $suzy-purple-300;
}
.border-bottom-suzy-purple-400 {
border-bottom: 1px solid $suzy-purple-400;
}
.border-bottom-suzy-purple-500 {
border-bottom: 1px solid $suzy-purple-500;
}
.border-bottom-suzy-purple-600 {
border-bottom: 1px solid $suzy-purple-600;
}
.border-bottom-suzy-purple-700 {
border-bottom: 1px solid $suzy-purple-700;
}
.border-bottom-suzy-purple-800 {
border-bottom: 1px solid $suzy-purple-800;
}
.border-bottom-suzy-purple-900 {
border-bottom: 1px solid $suzy-purple-900;
}

// Suzy Core Blue
.border-bottom-suzy-core-blue-50 {
border-bottom: 1px solid $suzy-core-blue-50;
}
.border-bottom-suzy-core-blue-100 {
border-bottom: 1px solid $suzy-core-blue-100;
}
.border-bottom-suzy-core-blue-200 {
border-bottom: 1px solid $suzy-core-blue-200;
}
.border-bottom-suzy-core-blue-300 {
border-bottom: 1px solid $suzy-core-blue-300;
}
.border-bottom-suzy-core-blue-400 {
border-bottom: 1px solid $suzy-core-blue-400;
}
.border-bottom-suzy-core-blue-500 {
border-bottom: 1px solid $suzy-core-blue-500;
}
.border-bottom-suzy-core-blue-600 {
border-bottom: 1px solid $suzy-core-blue-600;
}
.border-bottom-suzy-core-blue-700 {
border-bottom: 1px solid $suzy-core-blue-700;
}
.border-bottom-suzy-core-blue-800 {
border-bottom: 1px solid $suzy-core-blue-800;
}
.border-bottom-suzy-core-blue-900 {
border-bottom: 1px solid $suzy-core-blue-900;
}

// Suzy Red
.border-bottom-suzy-red-50 {
border-bottom: 1px solid $suzy-red-50;
}
.border-bottom-suzy-red-100 {
border-bottom: 1px solid $suzy-red-100;
}
.border-bottom-suzy-red-200 {
border-bottom: 1px solid $suzy-red-200;
}
.border-bottom-suzy-red-300 {
border-bottom: 1px solid $suzy-red-300;
}
.border-bottom-suzy-red-400 {
border-bottom: 1px solid $suzy-red-400;
}
.border-bottom-suzy-red-500 {
border-bottom: 1px solid $suzy-red-500;
}
.border-bottom-suzy-red-600 {
border-bottom: 1px solid $suzy-red-600;
}
.border-bottom-suzy-red-700 {
border-bottom: 1px solid $suzy-red-700;
}
.border-bottom-suzy-red-800 {
border-bottom: 1px solid $suzy-red-800;
}
.border-bottom-suzy-red-900 {
border-bottom: 1px solid $suzy-red-900;
}

// Suzy Warm
.border-bottom-suzy-warm-50 {
border-bottom: 1px solid $suzy-warm-50;
}
.border-bottom-suzy-warm-100 {
border-bottom: 1px solid $suzy-warm-100;
}
.border-bottom-suzy-warm-200 {
border-bottom: 1px solid $suzy-warm-200;
}
.border-bottom-suzy-warm-300 {
border-bottom: 1px solid $suzy-warm-300;
}
.border-bottom-suzy-warm-400 {
border-bottom: 1px solid $suzy-warm-400;
}
.border-bottom-suzy-warm-500 {
border-bottom: 1px solid $suzy-warm-500;
}
.border-bottom-suzy-warm-600 {
border-bottom: 1px solid $suzy-warm-600;
}
.border-bottom-suzy-warm-700 {
border-bottom: 1px solid $suzy-warm-700;
}
.border-bottom-suzy-warm-800 {
border-bottom: 1px solid $suzy-warm-800;
}
.border-bottom-suzy-warm-900 {
border-bottom: 1px solid $suzy-warm-900;
}

// Suzy Green
.border-bottom-suzy-green-50 {
border-bottom: 1px solid $suzy-green-50;
}
.border-bottom-suzy-green-100 {
border-bottom: 1px solid $suzy-green-100;
}
.border-bottom-suzy-green-200 {
border-bottom: 1px solid $suzy-green-200;
}
.border-bottom-suzy-green-300 {
border-bottom: 1px solid $suzy-green-300;
}
.border-bottom-suzy-green-400 {
border-bottom: 1px solid $suzy-green-400;
}
.border-bottom-suzy-green-500 {
border-bottom: 1px solid $suzy-green-500;
}
.border-bottom-suzy-green-600 {
border-bottom: 1px solid $suzy-green-600;
}
.border-bottom-suzy-green-700 {
border-bottom: 1px solid $suzy-green-700;
}
.border-bottom-suzy-green-800 {
border-bottom: 1px solid $suzy-green-800;
}
.border-bottom-suzy-green-900 {
border-bottom: 1px solid $suzy-green-900;
}

// Suzy Pink
.border-bottom-suzy-pink-50 {
border-bottom: 1px solid $suzy-pink-50;
}
.border-bottom-suzy-pink-100 {
border-bottom: 1px solid $suzy-pink-100;
}
.border-bottom-suzy-pink-200 {
border-bottom: 1px solid $suzy-pink-200;
}
.border-bottom-suzy-pink-300 {
border-bottom: 1px solid $suzy-pink-300;
}
.border-bottom-suzy-pink-400 {
border-bottom: 1px solid $suzy-pink-400;
}
.border-bottom-suzy-pink-500 {
border-bottom: 1px solid $suzy-pink-500;
}
.border-bottom-suzy-pink-600 {
border-bottom: 1px solid $suzy-pink-600;
}
.border-bottom-suzy-pink-700 {
border-bottom: 1px solid $suzy-pink-700;
}
.border-bottom-suzy-pink-800 {
border-bottom: 1px solid $suzy-pink-800;
}
.border-bottom-suzy-pink-900 {
border-bottom: 1px solid $suzy-pink-900;
}

// Border Left Color Classes
///////////////////////

// Suzy Black
.border-left-suzy-black {
border-left: 1px solid $suzy-black;
}

// Suzy White
.border-left-suzy-white {
border-left: 1px solid $suzy-white;
}

// Suzy Blue
.border-left-suzy-blue-50 {
border-left: 1px solid $suzy-blue-50;
}
.border-left-suzy-blue-100 {
border-left: 1px solid $suzy-blue-100;
}
.border-left-suzy-blue-200 {
border-left: 1px solid $suzy-blue-200;
}
.border-left-suzy-blue-300 {
border-left: 1px solid $suzy-blue-300;
}
.border-left-suzy-blue-400 {
border-left: 1px solid $suzy-blue-400;
}
.border-left-suzy-blue-500 {
border-left: 1px solid $suzy-blue-500;
}
.border-left-suzy-blue-600 {
border-left: 1px solid $suzy-blue-600;
}
.border-left-suzy-blue-700 {
border-left: 1px solid $suzy-blue-700;
}
.border-left-suzy-blue-800 {
border-left: 1px solid $suzy-blue-800;
}
.border-left-suzy-blue-900 {
border-left: 1px solid $suzy-blue-900;
}

// Suzy Grey
.border-left-suzy-grey-50 {
border-left: 1px solid $suzy-grey-50;
}
.border-left-suzy-grey-100 {
border-left: 1px solid $suzy-grey-100;
}
.border-left-suzy-grey-200 {
border-left: 1px solid $suzy-grey-200;
}
.border-left-suzy-grey-300 {
border-left: 1px solid $suzy-grey-300;
}
.border-left-suzy-grey-400 {
border-left: 1px solid $suzy-grey-400;
}
.border-left-suzy-grey-500 {
border-left: 1px solid $suzy-grey-500;
}
.border-left-suzy-grey-600 {
border-left: 1px solid $suzy-grey-600;
}
.border-left-suzy-grey-700 {
border-left: 1px solid $suzy-grey-700;
}
.border-left-suzy-grey-800 {
border-left: 1px solid $suzy-grey-800;
}
.border-left-suzy-grey-900 {
border-left: 1px solid $suzy-grey-900;
}

// Suzy Purple
.border-left-suzy-purple-50 {
border-left: 1px solid $suzy-purple-50;
}
.border-left-suzy-purple-100 {
border-left: 1px solid $suzy-purple-100;
}
.border-left-suzy-purple-200 {
border-left: 1px solid $suzy-purple-200;
}
.border-left-suzy-purple-300 {
border-left: 1px solid $suzy-purple-300;
}
.border-left-suzy-purple-400 {
border-left: 1px solid $suzy-purple-400;
}
.border-left-suzy-purple-500 {
border-left: 1px solid $suzy-purple-500;
}
.border-left-suzy-purple-600 {
border-left: 1px solid $suzy-purple-600;
}
.border-left-suzy-purple-700 {
border-left: 1px solid $suzy-purple-700;
}
.border-left-suzy-purple-800 {
border-left: 1px solid $suzy-purple-800;
}
.border-left-suzy-purple-900 {
border-left: 1px solid $suzy-purple-900;
}

// Suzy Core Blue
.border-left-suzy-core-blue-50 {
border-left: 1px solid $suzy-core-blue-50;
}
.border-left-suzy-core-blue-100 {
border-left: 1px solid $suzy-core-blue-100;
}
.border-left-suzy-core-blue-200 {
border-left: 1px solid $suzy-core-blue-200;
}
.border-left-suzy-core-blue-300 {
border-left: 1px solid $suzy-core-blue-300;
}
.border-left-suzy-core-blue-400 {
border-left: 1px solid $suzy-core-blue-400;
}
.border-left-suzy-core-blue-500 {
border-left: 1px solid $suzy-core-blue-500;
}
.border-left-suzy-core-blue-600 {
border-left: 1px solid $suzy-core-blue-600;
}
.border-left-suzy-core-blue-700 {
border-left: 1px solid $suzy-core-blue-700;
}
.border-left-suzy-core-blue-800 {
border-left: 1px solid $suzy-core-blue-800;
}
.border-left-suzy-core-blue-900 {
border-left: 1px solid $suzy-core-blue-900;
}

// Suzy Red
.border-left-suzy-red-50 {
border-left: 1px solid $suzy-red-50;
}
.border-left-suzy-red-100 {
border-left: 1px solid $suzy-red-100;
}
.border-left-suzy-red-200 {
border-left: 1px solid $suzy-red-200;
}
.border-left-suzy-red-300 {
border-left: 1px solid $suzy-red-300;
}
.border-left-suzy-red-400 {
border-left: 1px solid $suzy-red-400;
}
.border-left-suzy-red-500 {
border-left: 1px solid $suzy-red-500;
}
.border-left-suzy-red-600 {
border-left: 1px solid $suzy-red-600;
}
.border-left-suzy-red-700 {
border-left: 1px solid $suzy-red-700;
}
.border-left-suzy-red-800 {
border-left: 1px solid $suzy-red-800;
}
.border-left-suzy-red-900 {
border-left: 1px solid $suzy-red-900;
}

// Suzy Warm
.border-left-suzy-warm-50 {
border-left: 1px solid $suzy-warm-50;
}
.border-left-suzy-warm-100 {
border-left: 1px solid $suzy-warm-100;
}
.border-left-suzy-warm-200 {
border-left: 1px solid $suzy-warm-200;
}
.border-left-suzy-warm-300 {
border-left: 1px solid $suzy-warm-300;
}
.border-left-suzy-warm-400 {
border-left: 1px solid $suzy-warm-400;
}
.border-left-suzy-warm-500 {
border-left: 1px solid $suzy-warm-500;
}
.border-left-suzy-warm-600 {
border-left: 1px solid $suzy-warm-600;
}
.border-left-suzy-warm-700 {
border-left: 1px solid $suzy-warm-700;
}
.border-left-suzy-warm-800 {
border-left: 1px solid $suzy-warm-800;
}
.border-left-suzy-warm-900 {
border-left: 1px solid $suzy-warm-900;
}

// Suzy Green
.border-left-suzy-green-50 {
border-left: 1px solid $suzy-green-50;
}
.border-left-suzy-green-100 {
border-left: 1px solid $suzy-green-100;
}
.border-left-suzy-green-200 {
border-left: 1px solid $suzy-green-200;
}
.border-left-suzy-green-300 {
border-left: 1px solid $suzy-green-300;
}
.border-left-suzy-green-400 {
border-left: 1px solid $suzy-green-400;
}
.border-left-suzy-green-500 {
border-left: 1px solid $suzy-green-500;
}
.border-left-suzy-green-600 {
border-left: 1px solid $suzy-green-600;
}
.border-left-suzy-green-700 {
border-left: 1px solid $suzy-green-700;
}
.border-left-suzy-green-800 {
border-left: 1px solid $suzy-green-800;
}
.border-left-suzy-green-900 {
border-left: 1px solid $suzy-green-900;
}

// Suzy Pink
.border-left-suzy-pink-50 {
border-left: 1px solid $suzy-pink-50;
}
.border-left-suzy-pink-100 {
border-left: 1px solid $suzy-pink-100;
}
.border-left-suzy-pink-200 {
border-left: 1px solid $suzy-pink-200;
}
.border-left-suzy-pink-300 {
border-left: 1px solid $suzy-pink-300;
}
.border-left-suzy-pink-400 {
border-left: 1px solid $suzy-pink-400;
}
.border-left-suzy-pink-500 {
border-left: 1px solid $suzy-pink-500;
}
.border-left-suzy-pink-600 {
border-left: 1px solid $suzy-pink-600;
}
.border-left-suzy-pink-700 {
border-left: 1px solid $suzy-pink-700;
}
.border-left-suzy-pink-800 {
border-left: 1px solid $suzy-pink-800;
}
.border-left-suzy-pink-900 {
border-left: 1px solid $suzy-pink-900;
}

// Border 2px Color Classes
///////////////////////

// Suzy Black
.border-medium-suzy-black {
border: 2px solid $suzy-black;
}

// Suzy White
.border-medium-suzy-white {
border: 2px solid $suzy-white;
}

// Suzy Blue
.border-medium-suzy-blue-50 {
border: 2px solid $suzy-blue-50;
}
.border-medium-suzy-blue-100 {
border: 2px solid $suzy-blue-100;
}
.border-medium-suzy-blue-200 {
border: 2px solid $suzy-blue-200;
}
.border-medium-suzy-blue-300 {
border: 2px solid $suzy-blue-300;
}
.border-medium-suzy-blue-400 {
border: 2px solid $suzy-blue-400;
}
.border-medium-suzy-blue-500 {
border: 2px solid $suzy-blue-500;
}
.border-medium-suzy-blue-600 {
border: 2px solid $suzy-blue-600;
}
.border-medium-suzy-blue-700 {
border: 2px solid $suzy-blue-700;
}
.border-medium-suzy-blue-800 {
border: 2px solid $suzy-blue-800;
}
.border-medium-suzy-blue-900 {
border: 2px solid $suzy-blue-900;
}

// Suzy Grey
.border-medium-suzy-grey-50 {
border: 2px solid $suzy-grey-50;
}
.border-medium-suzy-grey-100 {
border: 2px solid $suzy-grey-100;
}
.border-medium-suzy-grey-200 {
border: 2px solid $suzy-grey-200;
}
.border-medium-suzy-grey-300 {
border: 2px solid $suzy-grey-300;
}
.border-medium-suzy-grey-400 {
border: 2px solid $suzy-grey-400;
}
.border-medium-suzy-grey-500 {
border: 2px solid $suzy-grey-500;
}
.border-medium-suzy-grey-600 {
border: 2px solid $suzy-grey-600;
}
.border-medium-suzy-grey-700 {
border: 2px solid $suzy-grey-700;
}
.border-medium-suzy-grey-800 {
border: 2px solid $suzy-grey-800;
}
.border-medium-suzy-grey-900 {
border: 2px solid $suzy-grey-900;
}

// Suzy Purple
.border-medium-suzy-purple-50 {
border: 2px solid $suzy-purple-50;
}
.border-medium-suzy-purple-100 {
border: 2px solid $suzy-purple-100;
}
.border-medium-suzy-purple-200 {
border: 2px solid $suzy-purple-200;
}
.border-medium-suzy-purple-300 {
border: 2px solid $suzy-purple-300;
}
.border-medium-suzy-purple-400 {
border: 2px solid $suzy-purple-400;
}
.border-medium-suzy-purple-500 {
border: 2px solid $suzy-purple-500;
}
.border-medium-suzy-purple-600 {
border: 2px solid $suzy-purple-600;
}
.border-medium-suzy-purple-700 {
border: 2px solid $suzy-purple-700;
}
.border-medium-suzy-purple-800 {
border: 2px solid $suzy-purple-800;
}
.border-medium-suzy-purple-900 {
border: 2px solid $suzy-purple-900;
}

// Suzy Core Blue
.border-medium-suzy-core-blue-50 {
border: 2px solid $suzy-core-blue-50;
}
.border-medium-suzy-core-blue-100 {
border: 2px solid $suzy-core-blue-100;
}
.border-medium-suzy-core-blue-200 {
border: 2px solid $suzy-core-blue-200;
}
.border-medium-suzy-core-blue-300 {
border: 2px solid $suzy-core-blue-300;
}
.border-medium-suzy-core-blue-400 {
border: 2px solid $suzy-core-blue-400;
}
.border-medium-suzy-core-blue-500 {
border: 2px solid $suzy-core-blue-500;
}
.border-medium-suzy-core-blue-600 {
border: 2px solid $suzy-core-blue-600;
}
.border-medium-suzy-core-blue-700 {
border: 2px solid $suzy-core-blue-700;
}
.border-medium-suzy-core-blue-800 {
border: 2px solid $suzy-core-blue-800;
}
.border-medium-suzy-core-blue-900 {
border: 2px solid $suzy-core-blue-900;
}

// Suzy Red
.border-medium-suzy-red-50 {
border: 2px solid $suzy-red-50;
}
.border-medium-suzy-red-100 {
border: 2px solid $suzy-red-100;
}
.border-medium-suzy-red-200 {
border: 2px solid $suzy-red-200;
}
.border-medium-suzy-red-300 {
border: 2px solid $suzy-red-300;
}
.border-medium-suzy-red-400 {
border: 2px solid $suzy-red-400;
}
.border-medium-suzy-red-500 {
border: 2px solid $suzy-red-500;
}
.border-medium-suzy-red-600 {
border: 2px solid $suzy-red-600;
}
.border-medium-suzy-red-700 {
border: 2px solid $suzy-red-700;
}
.border-medium-suzy-red-800 {
border: 2px solid $suzy-red-800;
}
.border-medium-suzy-red-900 {
border: 2px solid $suzy-red-900;
}

// Suzy Warm
.border-medium-suzy-warm-50 {
border: 2px solid $suzy-warm-50;
}
.border-medium-suzy-warm-100 {
border: 2px solid $suzy-warm-100;
}
.border-medium-suzy-warm-200 {
border: 2px solid $suzy-warm-200;
}
.border-medium-suzy-warm-300 {
border: 2px solid $suzy-warm-300;
}
.border-medium-suzy-warm-400 {
border: 2px solid $suzy-warm-400;
}
.border-medium-suzy-warm-500 {
border: 2px solid $suzy-warm-500;
}
.border-medium-suzy-warm-600 {
border: 2px solid $suzy-warm-600;
}
.border-medium-suzy-warm-700 {
border: 2px solid $suzy-warm-700;
}
.border-medium-suzy-warm-800 {
border: 2px solid $suzy-warm-800;
}
.border-medium-suzy-warm-900 {
border: 2px solid $suzy-warm-900;
}

// Suzy Green
.border-medium-suzy-green-50 {
border: 2px solid $suzy-green-50;
}
.border-medium-suzy-green-100 {
border: 2px solid $suzy-green-100;
}
.border-medium-suzy-green-200 {
border: 2px solid $suzy-green-200;
}
.border-medium-suzy-green-300 {
border: 2px solid $suzy-green-300;
}
.border-medium-suzy-green-400 {
border: 2px solid $suzy-green-400;
}
.border-medium-suzy-green-500 {
border: 2px solid $suzy-green-500;
}
.border-medium-suzy-green-600 {
border: 2px solid $suzy-green-600;
}
.border-medium-suzy-green-700 {
border: 2px solid $suzy-green-700;
}
.border-medium-suzy-green-800 {
border: 2px solid $suzy-green-800;
}
.border-medium-suzy-green-900 {
border: 2px solid $suzy-green-900;
}

// Suzy Pink
.border-medium-suzy-pink-50 {
border: 2px solid $suzy-pink-50;
}
.border-medium-suzy-pink-100 {
border: 2px solid $suzy-pink-100;
}
.border-medium-suzy-pink-200 {
border: 2px solid $suzy-pink-200;
}
.border-medium-suzy-pink-300 {
border: 2px solid $suzy-pink-300;
}
.border-medium-suzy-pink-400 {
border: 2px solid $suzy-pink-400;
}
.border-medium-suzy-pink-500 {
border: 2px solid $suzy-pink-500;
}
.border-medium-suzy-pink-600 {
border: 2px solid $suzy-pink-600;
}
.border-medium-suzy-pink-700 {
border: 2px solid $suzy-pink-700;
}
.border-medium-suzy-pink-800 {
border: 2px solid $suzy-pink-800;
}
.border-medium-suzy-pink-900 {
border: 2px solid $suzy-pink-900;
}

// Border Top 2px Color Classes
///////////////////////

// Suzy Black
.border-medium-top-suzy-black {
border-top: 2px solid $suzy-black;
}

// Suzy White
.border-medium-top-suzy-white {
border-top: 2px solid $suzy-white;
}

// Suzy Blue
.border-medium-top-suzy-blue-50 {
border-top: 2px solid $suzy-blue-50;
}
.border-medium-top-suzy-blue-100 {
border-top: 2px solid $suzy-blue-100;
}
.border-medium-top-suzy-blue-200 {
border-top: 2px solid $suzy-blue-200;
}
.border-medium-top-suzy-blue-300 {
border-top: 2px solid $suzy-blue-300;
}
.border-medium-top-suzy-blue-400 {
border-top: 2px solid $suzy-blue-400;
}
.border-medium-top-suzy-blue-500 {
border-top: 2px solid $suzy-blue-500;
}
.border-medium-top-suzy-blue-600 {
border-top: 2px solid $suzy-blue-600;
}
.border-medium-top-suzy-blue-700 {
border-top: 2px solid $suzy-blue-700;
}
.border-medium-top-suzy-blue-800 {
border-top: 2px solid $suzy-blue-800;
}
.border-medium-top-suzy-blue-900 {
border-top: 2px solid $suzy-blue-900;
}

// Suzy Grey
.border-medium-top-suzy-grey-50 {
border-top: 2px solid $suzy-grey-50;
}
.border-medium-top-suzy-grey-100 {
border-top: 2px solid $suzy-grey-100;
}
.border-medium-top-suzy-grey-200 {
border-top: 2px solid $suzy-grey-200;
}
.border-medium-top-suzy-grey-300 {
border-top: 2px solid $suzy-grey-300;
}
.border-medium-top-suzy-grey-400 {
border-top: 2px solid $suzy-grey-400;
}
.border-medium-top-suzy-grey-500 {
border-top: 2px solid $suzy-grey-500;
}
.border-medium-top-suzy-grey-600 {
border-top: 2px solid $suzy-grey-600;
}
.border-medium-top-suzy-grey-700 {
border-top: 2px solid $suzy-grey-700;
}
.border-medium-top-suzy-grey-800 {
border-top: 2px solid $suzy-grey-800;
}
.border-medium-top-suzy-grey-900 {
border-top: 2px solid $suzy-grey-900;
}

// Suzy Purple
.border-medium-top-suzy-purple-50 {
border-top: 2px solid $suzy-purple-50;
}
.border-medium-top-suzy-purple-100 {
border-top: 2px solid $suzy-purple-100;
}
.border-medium-top-suzy-purple-200 {
border-top: 2px solid $suzy-purple-200;
}
.border-medium-top-suzy-purple-300 {
border-top: 2px solid $suzy-purple-300;
}
.border-medium-top-suzy-purple-400 {
border-top: 2px solid $suzy-purple-400;
}
.border-medium-top-suzy-purple-500 {
border-top: 2px solid $suzy-purple-500;
}
.border-medium-top-suzy-purple-600 {
border-top: 2px solid $suzy-purple-600;
}
.border-medium-top-suzy-purple-700 {
border-top: 2px solid $suzy-purple-700;
}
.border-medium-top-suzy-purple-800 {
border-top: 2px solid $suzy-purple-800;
}
.border-medium-top-suzy-purple-900 {
border-top: 2px solid $suzy-purple-900;
}

// Suzy Core Blue
.border-medium-top-suzy-core-blue-50 {
border-top: 2px solid $suzy-core-blue-50;
}
.border-medium-top-suzy-core-blue-100 {
border-top: 2px solid $suzy-core-blue-100;
}
.border-medium-top-suzy-core-blue-200 {
border-top: 2px solid $suzy-core-blue-200;
}
.border-medium-top-suzy-core-blue-300 {
border-top: 2px solid $suzy-core-blue-300;
}
.border-medium-top-suzy-core-blue-400 {
border-top: 2px solid $suzy-core-blue-400;
}
.border-medium-top-suzy-core-blue-500 {
border-top: 2px solid $suzy-core-blue-500;
}
.border-medium-top-suzy-core-blue-600 {
border-top: 2px solid $suzy-core-blue-600;
}
.border-medium-top-suzy-core-blue-700 {
border-top: 2px solid $suzy-core-blue-700;
}
.border-medium-top-suzy-core-blue-800 {
border-top: 2px solid $suzy-core-blue-800;
}
.border-medium-top-suzy-core-blue-900 {
border-top: 2px solid $suzy-core-blue-900;
}

// Suzy Red
.border-medium-top-suzy-red-50 {
border-top: 2px solid $suzy-red-50;
}
.border-medium-top-suzy-red-100 {
border-top: 2px solid $suzy-red-100;
}
.border-medium-top-suzy-red-200 {
border-top: 2px solid $suzy-red-200;
}
.border-medium-top-suzy-red-300 {
border-top: 2px solid $suzy-red-300;
}
.border-medium-top-suzy-red-400 {
border-top: 2px solid $suzy-red-400;
}
.border-medium-top-suzy-red-500 {
border-top: 2px solid $suzy-red-500;
}
.border-medium-top-suzy-red-600 {
border-top: 2px solid $suzy-red-600;
}
.border-medium-top-suzy-red-700 {
border-top: 2px solid $suzy-red-700;
}
.border-medium-top-suzy-red-800 {
border-top: 2px solid $suzy-red-800;
}
.border-medium-top-suzy-red-900 {
border-top: 2px solid $suzy-red-900;
}

// Suzy Warm
.border-medium-top-suzy-warm-50 {
border-top: 2px solid $suzy-warm-50;
}
.border-medium-top-suzy-warm-100 {
border-top: 2px solid $suzy-warm-100;
}
.border-medium-top-suzy-warm-200 {
border-top: 2px solid $suzy-warm-200;
}
.border-medium-top-suzy-warm-300 {
border-top: 2px solid $suzy-warm-300;
}
.border-medium-top-suzy-warm-400 {
border-top: 2px solid $suzy-warm-400;
}
.border-medium-top-suzy-warm-500 {
border-top: 2px solid $suzy-warm-500;
}
.border-medium-top-suzy-warm-600 {
border-top: 2px solid $suzy-warm-600;
}
.border-medium-top-suzy-warm-700 {
border-top: 2px solid $suzy-warm-700;
}
.border-medium-top-suzy-warm-800 {
border-top: 2px solid $suzy-warm-800;
}
.border-medium-top-suzy-warm-900 {
border-top: 2px solid $suzy-warm-900;
}

// Suzy Green
.border-medium-top-suzy-green-50 {
border-top: 2px solid $suzy-green-50;
}
.border-medium-top-suzy-green-100 {
border-top: 2px solid $suzy-green-100;
}
.border-medium-top-suzy-green-200 {
border-top: 2px solid $suzy-green-200;
}
.border-medium-top-suzy-green-300 {
border-top: 2px solid $suzy-green-300;
}
.border-medium-top-suzy-green-400 {
border-top: 2px solid $suzy-green-400;
}
.border-medium-top-suzy-green-500 {
border-top: 2px solid $suzy-green-500;
}
.border-medium-top-suzy-green-600 {
border-top: 2px solid $suzy-green-600;
}
.border-medium-top-suzy-green-700 {
border-top: 2px solid $suzy-green-700;
}
.border-medium-top-suzy-green-800 {
border-top: 2px solid $suzy-green-800;
}
.border-medium-top-suzy-green-900 {
border-top: 2px solid $suzy-green-900;
}

// Suzy Pink
.border-medium-top-suzy-pink-50 {
border-top: 2px solid $suzy-pink-50;
}
.border-medium-top-suzy-pink-100 {
border-top: 2px solid $suzy-pink-100;
}
.border-medium-top-suzy-pink-200 {
border-top: 2px solid $suzy-pink-200;
}
.border-medium-top-suzy-pink-300 {
border-top: 2px solid $suzy-pink-300;
}
.border-medium-top-suzy-pink-400 {
border-top: 2px solid $suzy-pink-400;
}
.border-medium-top-suzy-pink-500 {
border-top: 2px solid $suzy-pink-500;
}
.border-medium-top-suzy-pink-600 {
border-top: 2px solid $suzy-pink-600;
}
.border-medium-top-suzy-pink-700 {
border-top: 2px solid $suzy-pink-700;
}
.border-medium-top-suzy-pink-800 {
border-top: 2px solid $suzy-pink-800;
}
.border-medium-top-suzy-pink-900 {
border-top: 2px solid $suzy-pink-900;
}

// Border Right 2px Color Classes
///////////////////////

// Suzy Black
.border-medium-right-suzy-black {
border-right: 2px solid $suzy-black;
}

// Suzy White
.border-medium-right-suzy-white {
border-right: 2px solid $suzy-white;
}

// Suzy Blue
.border-medium-right-suzy-blue-50 {
border-right: 2px solid $suzy-blue-50;
}
.border-medium-right-suzy-blue-100 {
border-right: 2px solid $suzy-blue-100;
}
.border-medium-right-suzy-blue-200 {
border-right: 2px solid $suzy-blue-200;
}
.border-medium-right-suzy-blue-300 {
border-right: 2px solid $suzy-blue-300;
}
.border-medium-right-suzy-blue-400 {
border-right: 2px solid $suzy-blue-400;
}
.border-medium-right-suzy-blue-500 {
border-right: 2px solid $suzy-blue-500;
}
.border-medium-right-suzy-blue-600 {
border-right: 2px solid $suzy-blue-600;
}
.border-medium-right-suzy-blue-700 {
border-right: 2px solid $suzy-blue-700;
}
.border-medium-right-suzy-blue-800 {
border-right: 2px solid $suzy-blue-800;
}
.border-medium-right-suzy-blue-900 {
border-right: 2px solid $suzy-blue-900;
}

// Suzy Grey
.border-medium-right-suzy-grey-50 {
border-right: 2px solid $suzy-grey-50;
}
.border-medium-right-suzy-grey-100 {
border-right: 2px solid $suzy-grey-100;
}
.border-medium-right-suzy-grey-200 {
border-right: 2px solid $suzy-grey-200;
}
.border-medium-right-suzy-grey-300 {
border-right: 2px solid $suzy-grey-300;
}
.border-medium-right-suzy-grey-400 {
border-right: 2px solid $suzy-grey-400;
}
.border-medium-right-suzy-grey-500 {
border-right: 2px solid $suzy-grey-500;
}
.border-medium-right-suzy-grey-600 {
border-right: 2px solid $suzy-grey-600;
}
.border-medium-right-suzy-grey-700 {
border-right: 2px solid $suzy-grey-700;
}
.border-medium-right-suzy-grey-800 {
border-right: 2px solid $suzy-grey-800;
}
.border-medium-right-suzy-grey-900 {
border-right: 2px solid $suzy-grey-900;
}

// Suzy Purple
.border-medium-right-suzy-purple-50 {
border-right: 2px solid $suzy-purple-50;
}
.border-medium-right-suzy-purple-100 {
border-right: 2px solid $suzy-purple-100;
}
.border-medium-right-suzy-purple-200 {
border-right: 2px solid $suzy-purple-200;
}
.border-medium-right-suzy-purple-300 {
border-right: 2px solid $suzy-purple-300;
}
.border-medium-right-suzy-purple-400 {
border-right: 2px solid $suzy-purple-400;
}
.border-medium-right-suzy-purple-500 {
border-right: 2px solid $suzy-purple-500;
}
.border-medium-right-suzy-purple-600 {
border-right: 2px solid $suzy-purple-600;
}
.border-medium-right-suzy-purple-700 {
border-right: 2px solid $suzy-purple-700;
}
.border-medium-right-suzy-purple-800 {
border-right: 2px solid $suzy-purple-800;
}
.border-medium-right-suzy-purple-900 {
border-right: 2px solid $suzy-purple-900;
}

// Suzy Core Blue
.border-medium-right-suzy-core-blue-50 {
border-right: 2px solid $suzy-core-blue-50;
}
.border-medium-right-suzy-core-blue-100 {
border-right: 2px solid $suzy-core-blue-100;
}
.border-medium-right-suzy-core-blue-200 {
border-right: 2px solid $suzy-core-blue-200;
}
.border-medium-right-suzy-core-blue-300 {
border-right: 2px solid $suzy-core-blue-300;
}
.border-medium-right-suzy-core-blue-400 {
border-right: 2px solid $suzy-core-blue-400;
}
.border-medium-right-suzy-core-blue-500 {
border-right: 2px solid $suzy-core-blue-500;
}
.border-medium-right-suzy-core-blue-600 {
border-right: 2px solid $suzy-core-blue-600;
}
.border-medium-right-suzy-core-blue-700 {
border-right: 2px solid $suzy-core-blue-700;
}
.border-medium-right-suzy-core-blue-800 {
border-right: 2px solid $suzy-core-blue-800;
}
.border-medium-right-suzy-core-blue-900 {
border-right: 2px solid $suzy-core-blue-900;
}

// Suzy Red
.border-medium-right-suzy-red-50 {
border-right: 2px solid $suzy-red-50;
}
.border-medium-right-suzy-red-100 {
border-right: 2px solid $suzy-red-100;
}
.border-medium-right-suzy-red-200 {
border-right: 2px solid $suzy-red-200;
}
.border-medium-right-suzy-red-300 {
border-right: 2px solid $suzy-red-300;
}
.border-medium-right-suzy-red-400 {
border-right: 2px solid $suzy-red-400;
}
.border-medium-right-suzy-red-500 {
border-right: 2px solid $suzy-red-500;
}
.border-medium-right-suzy-red-600 {
border-right: 2px solid $suzy-red-600;
}
.border-medium-right-suzy-red-700 {
border-right: 2px solid $suzy-red-700;
}
.border-medium-right-suzy-red-800 {
border-right: 2px solid $suzy-red-800;
}
.border-medium-right-suzy-red-900 {
border-right: 2px solid $suzy-red-900;
}

// Suzy Warm
.border-medium-right-suzy-warm-50 {
border-right: 2px solid $suzy-warm-50;
}
.border-medium-right-suzy-warm-100 {
border-right: 2px solid $suzy-warm-100;
}
.border-medium-right-suzy-warm-200 {
border-right: 2px solid $suzy-warm-200;
}
.border-medium-right-suzy-warm-300 {
border-right: 2px solid $suzy-warm-300;
}
.border-medium-right-suzy-warm-400 {
border-right: 2px solid $suzy-warm-400;
}
.border-medium-right-suzy-warm-500 {
border-right: 2px solid $suzy-warm-500;
}
.border-medium-right-suzy-warm-600 {
border-right: 2px solid $suzy-warm-600;
}
.border-medium-right-suzy-warm-700 {
border-right: 2px solid $suzy-warm-700;
}
.border-medium-right-suzy-warm-800 {
border-right: 2px solid $suzy-warm-800;
}
.border-medium-right-suzy-warm-900 {
border-right: 2px solid $suzy-warm-900;
}

// Suzy Green
.border-medium-right-suzy-green-50 {
border-right: 2px solid $suzy-green-50;
}
.border-medium-right-suzy-green-100 {
border-right: 2px solid $suzy-green-100;
}
.border-medium-right-suzy-green-200 {
border-right: 2px solid $suzy-green-200;
}
.border-medium-right-suzy-green-300 {
border-right: 2px solid $suzy-green-300;
}
.border-medium-right-suzy-green-400 {
border-right: 2px solid $suzy-green-400;
}
.border-medium-right-suzy-green-500 {
border-right: 2px solid $suzy-green-500;
}
.border-medium-right-suzy-green-600 {
border-right: 2px solid $suzy-green-600;
}
.border-medium-right-suzy-green-700 {
border-right: 2px solid $suzy-green-700;
}
.border-medium-right-suzy-green-800 {
border-right: 2px solid $suzy-green-800;
}
.border-medium-right-suzy-green-900 {
border-right: 2px solid $suzy-green-900;
}

// Suzy Pink
.border-medium-right-suzy-pink-50 {
border-right: 2px solid $suzy-pink-50;
}
.border-medium-right-suzy-pink-100 {
border-right: 2px solid $suzy-pink-100;
}
.border-medium-right-suzy-pink-200 {
border-right: 2px solid $suzy-pink-200;
}
.border-medium-right-suzy-pink-300 {
border-right: 2px solid $suzy-pink-300;
}
.border-medium-right-suzy-pink-400 {
border-right: 2px solid $suzy-pink-400;
}
.border-medium-right-suzy-pink-500 {
border-right: 2px solid $suzy-pink-500;
}
.border-medium-right-suzy-pink-600 {
border-right: 2px solid $suzy-pink-600;
}
.border-medium-right-suzy-pink-700 {
border-right: 2px solid $suzy-pink-700;
}
.border-medium-right-suzy-pink-800 {
border-right: 2px solid $suzy-pink-800;
}
.border-medium-right-suzy-pink-900 {
border-right: 2px solid $suzy-pink-900;
}

// Border Bottom 2px Color Classes
///////////////////////

// Suzy Black
.border-medium-bottom-suzy-black {
border-bottom: 2px solid $suzy-black;
}

// Suzy White
.border-medium-bottom-suzy-white {
border-bottom: 2px solid $suzy-white;
}

// Suzy Blue
.border-medium-bottom-suzy-blue-50 {
border-bottom: 2px solid $suzy-blue-50;
}
.border-medium-bottom-suzy-blue-100 {
border-bottom: 2px solid $suzy-blue-100;
}
.border-medium-bottom-suzy-blue-200 {
border-bottom: 2px solid $suzy-blue-200;
}
.border-medium-bottom-suzy-blue-300 {
border-bottom: 2px solid $suzy-blue-300;
}
.border-medium-bottom-suzy-blue-400 {
border-bottom: 2px solid $suzy-blue-400;
}
.border-medium-bottom-suzy-blue-500 {
border-bottom: 2px solid $suzy-blue-500;
}
.border-medium-bottom-suzy-blue-600 {
border-bottom: 2px solid $suzy-blue-600;
}
.border-medium-bottom-suzy-blue-700 {
border-bottom: 2px solid $suzy-blue-700;
}
.border-medium-bottom-suzy-blue-800 {
border-bottom: 2px solid $suzy-blue-800;
}
.border-medium-bottom-suzy-blue-900 {
border-bottom: 2px solid $suzy-blue-900;
}

// Suzy Grey
.border-medium-bottom-suzy-grey-50 {
border-bottom: 2px solid $suzy-grey-50;
}
.border-medium-bottom-suzy-grey-100 {
border-bottom: 2px solid $suzy-grey-100;
}
.border-medium-bottom-suzy-grey-200 {
border-bottom: 2px solid $suzy-grey-200;
}
.border-medium-bottom-suzy-grey-300 {
border-bottom: 2px solid $suzy-grey-300;
}
.border-medium-bottom-suzy-grey-400 {
border-bottom: 2px solid $suzy-grey-400;
}
.border-medium-bottom-suzy-grey-500 {
border-bottom: 2px solid $suzy-grey-500;
}
.border-medium-bottom-suzy-grey-600 {
border-bottom: 2px solid $suzy-grey-600;
}
.border-medium-bottom-suzy-grey-700 {
border-bottom: 2px solid $suzy-grey-700;
}
.border-medium-bottom-suzy-grey-800 {
border-bottom: 2px solid $suzy-grey-800;
}
.border-medium-bottom-suzy-grey-900 {
border-bottom: 2px solid $suzy-grey-900;
}

// Suzy Purple
.border-medium-bottom-suzy-purple-50 {
border-bottom: 2px solid $suzy-purple-50;
}
.border-medium-bottom-suzy-purple-100 {
border-bottom: 2px solid $suzy-purple-100;
}
.border-medium-bottom-suzy-purple-200 {
border-bottom: 2px solid $suzy-purple-200;
}
.border-medium-bottom-suzy-purple-300 {
border-bottom: 2px solid $suzy-purple-300;
}
.border-medium-bottom-suzy-purple-400 {
border-bottom: 2px solid $suzy-purple-400;
}
.border-medium-bottom-suzy-purple-500 {
border-bottom: 2px solid $suzy-purple-500;
}
.border-medium-bottom-suzy-purple-600 {
border-bottom: 2px solid $suzy-purple-600;
}
.border-medium-bottom-suzy-purple-700 {
border-bottom: 2px solid $suzy-purple-700;
}
.border-medium-bottom-suzy-purple-800 {
border-bottom: 2px solid $suzy-purple-800;
}
.border-medium-bottom-suzy-purple-900 {
border-bottom: 2px solid $suzy-purple-900;
}

// Suzy Core Blue
.border-medium-bottom-suzy-core-blue-50 {
border-bottom: 2px solid $suzy-core-blue-50;
}
.border-medium-bottom-suzy-core-blue-100 {
border-bottom: 2px solid $suzy-core-blue-100;
}
.border-medium-bottom-suzy-core-blue-200 {
border-bottom: 2px solid $suzy-core-blue-200;
}
.border-medium-bottom-suzy-core-blue-300 {
border-bottom: 2px solid $suzy-core-blue-300;
}
.border-medium-bottom-suzy-core-blue-400 {
border-bottom: 2px solid $suzy-core-blue-400;
}
.border-medium-bottom-suzy-core-blue-500 {
border-bottom: 2px solid $suzy-core-blue-500;
}
.border-medium-bottom-suzy-core-blue-600 {
border-bottom: 2px solid $suzy-core-blue-600;
}
.border-medium-bottom-suzy-core-blue-700 {
border-bottom: 2px solid $suzy-core-blue-700;
}
.border-medium-bottom-suzy-core-blue-800 {
border-bottom: 2px solid $suzy-core-blue-800;
}
.border-medium-bottom-suzy-core-blue-900 {
border-bottom: 2px solid $suzy-core-blue-900;
}

// Suzy Red
.border-medium-bottom-suzy-red-50 {
border-bottom: 2px solid $suzy-red-50;
}
.border-medium-bottom-suzy-red-100 {
border-bottom: 2px solid $suzy-red-100;
}
.border-medium-bottom-suzy-red-200 {
border-bottom: 2px solid $suzy-red-200;
}
.border-medium-bottom-suzy-red-300 {
border-bottom: 2px solid $suzy-red-300;
}
.border-medium-bottom-suzy-red-400 {
border-bottom: 2px solid $suzy-red-400;
}
.border-medium-bottom-suzy-red-500 {
border-bottom: 2px solid $suzy-red-500;
}
.border-medium-bottom-suzy-red-600 {
border-bottom: 2px solid $suzy-red-600;
}
.border-medium-bottom-suzy-red-700 {
border-bottom: 2px solid $suzy-red-700;
}
.border-medium-bottom-suzy-red-800 {
border-bottom: 2px solid $suzy-red-800;
}
.border-medium-bottom-suzy-red-900 {
border-bottom: 2px solid $suzy-red-900;
}

// Suzy Warm
.border-medium-bottom-suzy-warm-50 {
border-bottom: 2px solid $suzy-warm-50;
}
.border-medium-bottom-suzy-warm-100 {
border-bottom: 2px solid $suzy-warm-100;
}
.border-medium-bottom-suzy-warm-200 {
border-bottom: 2px solid $suzy-warm-200;
}
.border-medium-bottom-suzy-warm-300 {
border-bottom: 2px solid $suzy-warm-300;
}
.border-medium-bottom-suzy-warm-400 {
border-bottom: 2px solid $suzy-warm-400;
}
.border-medium-bottom-suzy-warm-500 {
border-bottom: 2px solid $suzy-warm-500;
}
.border-medium-bottom-suzy-warm-600 {
border-bottom: 2px solid $suzy-warm-600;
}
.border-medium-bottom-suzy-warm-700 {
border-bottom: 2px solid $suzy-warm-700;
}
.border-medium-bottom-suzy-warm-800 {
border-bottom: 2px solid $suzy-warm-800;
}
.border-medium-bottom-suzy-warm-900 {
border-bottom: 2px solid $suzy-warm-900;
}

// Suzy Green
.border-medium-bottom-suzy-green-50 {
border-bottom: 2px solid $suzy-green-50;
}
.border-medium-bottom-suzy-green-100 {
border-bottom: 2px solid $suzy-green-100;
}
.border-medium-bottom-suzy-green-200 {
border-bottom: 2px solid $suzy-green-200;
}
.border-medium-bottom-suzy-green-300 {
border-bottom: 2px solid $suzy-green-300;
}
.border-medium-bottom-suzy-green-400 {
border-bottom: 2px solid $suzy-green-400;
}
.border-medium-bottom-suzy-green-500 {
border-bottom: 2px solid $suzy-green-500;
}
.border-medium-bottom-suzy-green-600 {
border-bottom: 2px solid $suzy-green-600;
}
.border-medium-bottom-suzy-green-700 {
border-bottom: 2px solid $suzy-green-700;
}
.border-medium-bottom-suzy-green-800 {
border-bottom: 2px solid $suzy-green-800;
}
.border-medium-bottom-suzy-green-900 {
border-bottom: 2px solid $suzy-green-900;
}

// Suzy Pink
.border-medium-bottom-suzy-pink-50 {
border-bottom: 2px solid $suzy-pink-50;
}
.border-medium-bottom-suzy-pink-100 {
border-bottom: 2px solid $suzy-pink-100;
}
.border-medium-bottom-suzy-pink-200 {
border-bottom: 2px solid $suzy-pink-200;
}
.border-medium-bottom-suzy-pink-300 {
border-bottom: 2px solid $suzy-pink-300;
}
.border-medium-bottom-suzy-pink-400 {
border-bottom: 2px solid $suzy-pink-400;
}
.border-medium-bottom-suzy-pink-500 {
border-bottom: 2px solid $suzy-pink-500;
}
.border-medium-bottom-suzy-pink-600 {
border-bottom: 2px solid $suzy-pink-600;
}
.border-medium-bottom-suzy-pink-700 {
border-bottom: 2px solid $suzy-pink-700;
}
.border-medium-bottom-suzy-pink-800 {
border-bottom: 2px solid $suzy-pink-800;
}
.border-medium-bottom-suzy-pink-900 {
border-bottom: 2px solid $suzy-pink-900;
}

// Border Left 2px Color Classes
///////////////////////

// Suzy Black
.border-medium-left-suzy-black {
border-left: 2px solid $suzy-black;
}

// Suzy White
.border-medium-left-suzy-white {
border-left: 2px solid $suzy-white;
}

// Suzy Blue
.border-medium-left-suzy-blue-50 {
border-left: 2px solid $suzy-blue-50;
}
.border-medium-left-suzy-blue-100 {
border-left: 2px solid $suzy-blue-100;
}
.border-medium-left-suzy-blue-200 {
border-left: 2px solid $suzy-blue-200;
}
.border-medium-left-suzy-blue-300 {
border-left: 2px solid $suzy-blue-300;
}
.border-medium-left-suzy-blue-400 {
border-left: 2px solid $suzy-blue-400;
}
.border-medium-left-suzy-blue-500 {
border-left: 2px solid $suzy-blue-500;
}
.border-medium-left-suzy-blue-600 {
border-left: 2px solid $suzy-blue-600;
}
.border-medium-left-suzy-blue-700 {
border-left: 2px solid $suzy-blue-700;
}
.border-medium-left-suzy-blue-800 {
border-left: 2px solid $suzy-blue-800;
}
.border-medium-left-suzy-blue-900 {
border-left: 2px solid $suzy-blue-900;
}

// Suzy Grey
.border-medium-left-suzy-grey-50 {
border-left: 2px solid $suzy-grey-50;
}
.border-medium-left-suzy-grey-100 {
border-left: 2px solid $suzy-grey-100;
}
.border-medium-left-suzy-grey-200 {
border-left: 2px solid $suzy-grey-200;
}
.border-medium-left-suzy-grey-300 {
border-left: 2px solid $suzy-grey-300;
}
.border-medium-left-suzy-grey-400 {
border-left: 2px solid $suzy-grey-400;
}
.border-medium-left-suzy-grey-500 {
border-left: 2px solid $suzy-grey-500;
}
.border-medium-left-suzy-grey-600 {
border-left: 2px solid $suzy-grey-600;
}
.border-medium-left-suzy-grey-700 {
border-left: 2px solid $suzy-grey-700;
}
.border-medium-left-suzy-grey-800 {
border-left: 2px solid $suzy-grey-800;
}
.border-medium-left-suzy-grey-900 {
border-left: 2px solid $suzy-grey-900;
}

// Suzy Purple
.border-medium-left-suzy-purple-50 {
border-left: 2px solid $suzy-purple-50;
}
.border-medium-left-suzy-purple-100 {
border-left: 2px solid $suzy-purple-100;
}
.border-medium-left-suzy-purple-200 {
border-left: 2px solid $suzy-purple-200;
}
.border-medium-left-suzy-purple-300 {
border-left: 2px solid $suzy-purple-300;
}
.border-medium-left-suzy-purple-400 {
border-left: 2px solid $suzy-purple-400;
}
.border-medium-left-suzy-purple-500 {
border-left: 2px solid $suzy-purple-500;
}
.border-medium-left-suzy-purple-600 {
border-left: 2px solid $suzy-purple-600;
}
.border-medium-left-suzy-purple-700 {
border-left: 2px solid $suzy-purple-700;
}
.border-medium-left-suzy-purple-800 {
border-left: 2px solid $suzy-purple-800;
}
.border-medium-left-suzy-purple-900 {
border-left: 2px solid $suzy-purple-900;
}

// Suzy Core Blue
.border-medium-left-suzy-core-blue-50 {
border-left: 2px solid $suzy-core-blue-50;
}
.border-medium-left-suzy-core-blue-100 {
border-left: 2px solid $suzy-core-blue-100;
}
.border-medium-left-suzy-core-blue-200 {
border-left: 2px solid $suzy-core-blue-200;
}
.border-medium-left-suzy-core-blue-300 {
border-left: 2px solid $suzy-core-blue-300;
}
.border-medium-left-suzy-core-blue-400 {
border-left: 2px solid $suzy-core-blue-400;
}
.border-medium-left-suzy-core-blue-500 {
border-left: 2px solid $suzy-core-blue-500;
}
.border-medium-left-suzy-core-blue-600 {
border-left: 2px solid $suzy-core-blue-600;
}
.border-medium-left-suzy-core-blue-700 {
border-left: 2px solid $suzy-core-blue-700;
}
.border-medium-left-suzy-core-blue-800 {
border-left: 2px solid $suzy-core-blue-800;
}
.border-medium-left-suzy-core-blue-900 {
border-left: 2px solid $suzy-core-blue-900;
}

// Suzy Red
.border-medium-left-suzy-red-50 {
border-left: 2px solid $suzy-red-50;
}
.border-medium-left-suzy-red-100 {
border-left: 2px solid $suzy-red-100;
}
.border-medium-left-suzy-red-200 {
border-left: 2px solid $suzy-red-200;
}
.border-medium-left-suzy-red-300 {
border-left: 2px solid $suzy-red-300;
}
.border-medium-left-suzy-red-400 {
border-left: 2px solid $suzy-red-400;
}
.border-medium-left-suzy-red-500 {
border-left: 2px solid $suzy-red-500;
}
.border-medium-left-suzy-red-600 {
border-left: 2px solid $suzy-red-600;
}
.border-medium-left-suzy-red-700 {
border-left: 2px solid $suzy-red-700;
}
.border-medium-left-suzy-red-800 {
border-left: 2px solid $suzy-red-800;
}
.border-medium-left-suzy-red-900 {
border-left: 2px solid $suzy-red-900;
}

// Suzy Warm
.border-medium-left-suzy-warm-50 {
border-left: 2px solid $suzy-warm-50;
}
.border-medium-left-suzy-warm-100 {
border-left: 2px solid $suzy-warm-100;
}
.border-medium-left-suzy-warm-200 {
border-left: 2px solid $suzy-warm-200;
}
.border-medium-left-suzy-warm-300 {
border-left: 2px solid $suzy-warm-300;
}
.border-medium-left-suzy-warm-400 {
border-left: 2px solid $suzy-warm-400;
}
.border-medium-left-suzy-warm-500 {
border-left: 2px solid $suzy-warm-500;
}
.border-medium-left-suzy-warm-600 {
border-left: 2px solid $suzy-warm-600;
}
.border-medium-left-suzy-warm-700 {
border-left: 2px solid $suzy-warm-700;
}
.border-medium-left-suzy-warm-800 {
border-left: 2px solid $suzy-warm-800;
}
.border-medium-left-suzy-warm-900 {
border-left: 2px solid $suzy-warm-900;
}

// Suzy Green
.border-medium-left-suzy-green-50 {
border-left: 2px solid $suzy-green-50;
}
.border-medium-left-suzy-green-100 {
border-left: 2px solid $suzy-green-100;
}
.border-medium-left-suzy-green-200 {
border-left: 2px solid $suzy-green-200;
}
.border-medium-left-suzy-green-300 {
border-left: 2px solid $suzy-green-300;
}
.border-medium-left-suzy-green-400 {
border-left: 2px solid $suzy-green-400;
}
.border-medium-left-suzy-green-500 {
border-left: 2px solid $suzy-green-500;
}
.border-medium-left-suzy-green-600 {
border-left: 2px solid $suzy-green-600;
}
.border-medium-left-suzy-green-700 {
border-left: 2px solid $suzy-green-700;
}
.border-medium-left-suzy-green-800 {
border-left: 2px solid $suzy-green-800;
}
.border-medium-left-suzy-green-900 {
border-left: 2px solid $suzy-green-900;
}

// Suzy Pink
.border-medium-left-suzy-pink-50 {
border-left: 2px solid $suzy-pink-50;
}
.border-medium-left-suzy-pink-100 {
border-left: 2px solid $suzy-pink-100;
}
.border-medium-left-suzy-pink-200 {
border-left: 2px solid $suzy-pink-200;
}
.border-medium-left-suzy-pink-300 {
border-left: 2px solid $suzy-pink-300;
}
.border-medium-left-suzy-pink-400 {
border-left: 2px solid $suzy-pink-400;
}
.border-medium-left-suzy-pink-500 {
border-left: 2px solid $suzy-pink-500;
}
.border-medium-left-suzy-pink-600 {
border-left: 2px solid $suzy-pink-600;
}
.border-medium-left-suzy-pink-700 {
border-left: 2px solid $suzy-pink-700;
}
.border-medium-left-suzy-pink-800 {
border-left: 2px solid $suzy-pink-800;
}
.border-medium-left-suzy-pink-900 {
border-left: 2px solid $suzy-pink-900;
}

// SVG Fill Color Classes
///////////////////////

// Suzy Black
.fill-suzy-black {
fill: $suzy-black;
}

// Suzy White
.fill-suzy-white {
fill: $suzy-white;
}

// Suzy Blue
.fill-suzy-blue-50 {
fill: $suzy-blue-50;
}
.fill-suzy-blue-100 {
fill: $suzy-blue-100;
}
.fill-suzy-blue-200 {
fill: $suzy-blue-200;
}
.fill-suzy-blue-300 {
fill: $suzy-blue-300;
}
.fill-suzy-blue-400 {
fill: $suzy-blue-400;
}
.fill-suzy-blue-500 {
fill: $suzy-blue-500;
}
.fill-suzy-blue-600 {
fill: $suzy-blue-600;
}
.fill-suzy-blue-700 {
fill: $suzy-blue-700;
}
.fill-suzy-blue-800 {
fill: $suzy-blue-800;
}
.fill-suzy-blue-900 {
fill: $suzy-blue-900;
}

// Suzy Grey
.fill-suzy-grey-50 {
fill: $suzy-grey-50;
}
.fill-suzy-grey-100 {
fill: $suzy-grey-100;
}
.fill-suzy-grey-200 {
fill: $suzy-grey-200;
}
.fill-suzy-grey-300 {
fill: $suzy-grey-300;
}
.fill-suzy-grey-400 {
fill: $suzy-grey-400;
}
.fill-suzy-grey-500 {
fill: $suzy-grey-500;
}
.fill-suzy-grey-600 {
fill: $suzy-grey-600;
}
.fill-suzy-grey-700 {
fill: $suzy-grey-700;
}
.fill-suzy-grey-800 {
fill: $suzy-grey-800;
}
.fill-suzy-grey-900 {
fill: $suzy-grey-900;
}

// Suzy Purple
.fill-suzy-purple-50 {
fill: $suzy-purple-50;
}
.fill-suzy-purple-100 {
fill: $suzy-purple-100;
}
.fill-suzy-purple-200 {
fill: $suzy-purple-200;
}
.fill-suzy-purple-300 {
fill: $suzy-purple-300;
}
.fill-suzy-purple-400 {
fill: $suzy-purple-400;
}
.fill-suzy-purple-500 {
fill: $suzy-purple-500;
}
.fill-suzy-purple-600 {
fill: $suzy-purple-600;
}
.fill-suzy-purple-700 {
fill: $suzy-purple-700;
}
.fill-suzy-purple-800 {
fill: $suzy-purple-800;
}
.fill-suzy-purple-900 {
fill: $suzy-purple-900;
}

// Suzy Core Blue
.fill-suzy-core-blue-50 {
fill: $suzy-core-blue-50;
}
.fill-suzy-core-blue-100 {
fill: $suzy-core-blue-100;
}
.fill-suzy-core-blue-200 {
fill: $suzy-core-blue-200;
}
.fill-suzy-core-blue-300 {
fill: $suzy-core-blue-300;
}
.fill-suzy-core-blue-400 {
fill: $suzy-core-blue-400;
}
.fill-suzy-core-blue-500 {
fill: $suzy-core-blue-500;
}
.fill-suzy-core-blue-600 {
fill: $suzy-core-blue-600;
}
.fill-suzy-core-blue-700 {
fill: $suzy-core-blue-700;
}
.fill-suzy-core-blue-800 {
fill: $suzy-core-blue-800;
}
.fill-suzy-core-blue-900 {
fill: $suzy-core-blue-900;
}

// Suzy Red
.fill-suzy-red-50 {
fill: $suzy-red-50;
}
.fill-suzy-red-100 {
fill: $suzy-red-100;
}
.fill-suzy-red-200 {
fill: $suzy-red-200;
}
.fill-suzy-red-300 {
fill: $suzy-red-300;
}
.fill-suzy-red-400 {
fill: $suzy-red-400;
}
.fill-suzy-red-500 {
fill: $suzy-red-500;
}
.fill-suzy-red-600 {
fill: $suzy-red-600;
}
.fill-suzy-red-700 {
fill: $suzy-red-700;
}
.fill-suzy-red-800 {
fill: $suzy-red-800;
}
.fill-suzy-red-900 {
fill: $suzy-red-900;
}

// Suzy Warm
.fill-suzy-warm-50 {
fill: $suzy-warm-50;
}
.fill-suzy-warm-100 {
fill: $suzy-warm-100;
}
.fill-suzy-warm-200 {
fill: $suzy-warm-200;
}
.fill-suzy-warm-300 {
fill: $suzy-warm-300;
}
.fill-suzy-warm-400 {
fill: $suzy-warm-400;
}
.fill-suzy-warm-500 {
fill: $suzy-warm-500;
}
.fill-suzy-warm-600 {
fill: $suzy-warm-600;
}
.fill-suzy-warm-700 {
fill: $suzy-warm-700;
}
.fill-suzy-warm-800 {
fill: $suzy-warm-800;
}
.fill-suzy-warm-900 {
fill: $suzy-warm-900;
}

// Suzy Green
.fill-suzy-green-50 {
fill: $suzy-green-50;
}
.fill-suzy-green-100 {
fill: $suzy-green-100;
}
.fill-suzy-green-200 {
fill: $suzy-green-200;
}
.fill-suzy-green-300 {
fill: $suzy-green-300;
}
.fill-suzy-green-400 {
fill: $suzy-green-400;
}
.fill-suzy-green-500 {
fill: $suzy-green-500;
}
.fill-suzy-green-600 {
fill: $suzy-green-600;
}
.fill-suzy-green-700 {
fill: $suzy-green-700;
}
.fill-suzy-green-800 {
fill: $suzy-green-800;
}
.fill-suzy-green-900 {
fill: $suzy-green-900;
}

// Suzy Pink
.fill-suzy-pink-50 {
fill: $suzy-pink-50;
}
.fill-suzy-pink-100 {
fill: $suzy-pink-100;
}
.fill-suzy-pink-200 {
fill: $suzy-pink-200;
}
.fill-suzy-pink-300 {
fill: $suzy-pink-300;
}
.fill-suzy-pink-400 {
fill: $suzy-pink-400;
}
.fill-suzy-pink-500 {
fill: $suzy-pink-500;
}
.fill-suzy-pink-600 {
fill: $suzy-pink-600;
}
.fill-suzy-pink-700 {
fill: $suzy-pink-700;
}
.fill-suzy-pink-800 {
fill: $suzy-pink-800;
}
.fill-suzy-pink-900 {
fill: $suzy-pink-900;
}

// SVG Stroke Classes
///////////////////////

// Suzy Black
.stroke-suzy-black {
stroke: $suzy-black;
}

// Suzy White
.stroke-suzy-white {
stroke: $suzy-white;
}

// Suzy Blue
.stroke-suzy-blue-50 {
stroke: $suzy-blue-50;
}
.stroke-suzy-blue-100 {
stroke: $suzy-blue-100;
}
.stroke-suzy-blue-200 {
stroke: $suzy-blue-200;
}
.stroke-suzy-blue-300 {
stroke: $suzy-blue-300;
}
.stroke-suzy-blue-400 {
stroke: $suzy-blue-400;
}
.stroke-suzy-blue-500 {
stroke: $suzy-blue-500;
}
.stroke-suzy-blue-600 {
stroke: $suzy-blue-600;
}
.stroke-suzy-blue-700 {
stroke: $suzy-blue-700;
}
.stroke-suzy-blue-800 {
stroke: $suzy-blue-800;
}
.stroke-suzy-blue-900 {
stroke: $suzy-blue-900;
}

// Suzy Grey
.stroke-suzy-grey-50 {
stroke: $suzy-grey-50;
}
.stroke-suzy-grey-100 {
stroke: $suzy-grey-100;
}
.stroke-suzy-grey-200 {
stroke: $suzy-grey-200;
}
.stroke-suzy-grey-300 {
stroke: $suzy-grey-300;
}
.stroke-suzy-grey-400 {
stroke: $suzy-grey-400;
}
.stroke-suzy-grey-500 {
stroke: $suzy-grey-500;
}
.stroke-suzy-grey-600 {
stroke: $suzy-grey-600;
}
.stroke-suzy-grey-700 {
stroke: $suzy-grey-700;
}
.stroke-suzy-grey-800 {
stroke: $suzy-grey-800;
}
.stroke-suzy-grey-900 {
stroke: $suzy-grey-900;
}

// Suzy Purple
.stroke-suzy-purple-50 {
stroke: $suzy-purple-50;
}
.stroke-suzy-purple-100 {
stroke: $suzy-purple-100;
}
.stroke-suzy-purple-200 {
stroke: $suzy-purple-200;
}
.stroke-suzy-purple-300 {
stroke: $suzy-purple-300;
}
.stroke-suzy-purple-400 {
stroke: $suzy-purple-400;
}
.stroke-suzy-purple-500 {
stroke: $suzy-purple-500;
}
.stroke-suzy-purple-600 {
stroke: $suzy-purple-600;
}
.stroke-suzy-purple-700 {
stroke: $suzy-purple-700;
}
.stroke-suzy-purple-800 {
stroke: $suzy-purple-800;
}
.stroke-suzy-purple-900 {
stroke: $suzy-purple-900;
}

// Suzy Core Blue
.stroke-suzy-core-blue-50 {
stroke: $suzy-core-blue-50;
}
.stroke-suzy-core-blue-100 {
stroke: $suzy-core-blue-100;
}
.stroke-suzy-core-blue-200 {
stroke: $suzy-core-blue-200;
}
.stroke-suzy-core-blue-300 {
stroke: $suzy-core-blue-300;
}
.stroke-suzy-core-blue-400 {
stroke: $suzy-core-blue-400;
}
.stroke-suzy-core-blue-500 {
stroke: $suzy-core-blue-500;
}
.stroke-suzy-core-blue-600 {
stroke: $suzy-core-blue-600;
}
.stroke-suzy-core-blue-700 {
stroke: $suzy-core-blue-700;
}
.stroke-suzy-core-blue-800 {
stroke: $suzy-core-blue-800;
}
.stroke-suzy-core-blue-900 {
stroke: $suzy-core-blue-900;
}

// Suzy Red
.stroke-suzy-red-50 {
stroke: $suzy-red-50;
}
.stroke-suzy-red-100 {
stroke: $suzy-red-100;
}
.stroke-suzy-red-200 {
stroke: $suzy-red-200;
}
.stroke-suzy-red-300 {
stroke: $suzy-red-300;
}
.stroke-suzy-red-400 {
stroke: $suzy-red-400;
}
.stroke-suzy-red-500 {
stroke: $suzy-red-500;
}
.stroke-suzy-red-600 {
stroke: $suzy-red-600;
}
.stroke-suzy-red-700 {
stroke: $suzy-red-700;
}
.stroke-suzy-red-800 {
stroke: $suzy-red-800;
}
.stroke-suzy-red-900 {
stroke: $suzy-red-900;
}

// Suzy Warm
.stroke-suzy-warm-50 {
stroke: $suzy-warm-50;
}
.stroke-suzy-warm-100 {
stroke: $suzy-warm-100;
}
.stroke-suzy-warm-200 {
stroke: $suzy-warm-200;
}
.stroke-suzy-warm-300 {
stroke: $suzy-warm-300;
}
.stroke-suzy-warm-400 {
stroke: $suzy-warm-400;
}
.stroke-suzy-warm-500 {
stroke: $suzy-warm-500;
}
.stroke-suzy-warm-600 {
stroke: $suzy-warm-600;
}
.stroke-suzy-warm-700 {
stroke: $suzy-warm-700;
}
.stroke-suzy-warm-800 {
stroke: $suzy-warm-800;
}
.stroke-suzy-warm-900 {
stroke: $suzy-warm-900;
}

// Suzy Green
.stroke-suzy-green-50 {
stroke: $suzy-green-50;
}
.stroke-suzy-green-100 {
stroke: $suzy-green-100;
}
.stroke-suzy-green-200 {
stroke: $suzy-green-200;
}
.stroke-suzy-green-300 {
stroke: $suzy-green-300;
}
.stroke-suzy-green-400 {
stroke: $suzy-green-400;
}
.stroke-suzy-green-500 {
stroke: $suzy-green-500;
}
.stroke-suzy-green-600 {
stroke: $suzy-green-600;
}
.stroke-suzy-green-700 {
stroke: $suzy-green-700;
}
.stroke-suzy-green-800 {
stroke: $suzy-green-800;
}
.stroke-suzy-green-900 {
stroke: $suzy-green-900;
}

// Suzy Pink
.stroke-suzy-pink-50 {
stroke: $suzy-pink-50;
}
.stroke-suzy-pink-100 {
stroke: $suzy-pink-100;
}
.stroke-suzy-pink-200 {
stroke: $suzy-pink-200;
}
.stroke-suzy-pink-300 {
stroke: $suzy-pink-300;
}
.stroke-suzy-pink-400 {
stroke: $suzy-pink-400;
}
.stroke-suzy-pink-500 {
stroke: $suzy-pink-500;
}
.stroke-suzy-pink-600 {
stroke: $suzy-pink-600;
}
.stroke-suzy-pink-700 {
stroke: $suzy-pink-700;
}
.stroke-suzy-pink-800 {
stroke: $suzy-pink-800;
}
.stroke-suzy-pink-900 {
stroke: $suzy-pink-900;
}

// Base Typography Classes
///////////////////////

// Font Families
.suzy-inter {
font-family: $suzy-font-families-inter;
}

// Base Font Size
.suzy-font-base {
  font-size: $suzy-font-base;
}

// Font Sizes
.suzy-font-size-0 {
font-size: $suzy-font-size-0;
}
.suzy-font-size-1 {
font-size: $suzy-font-size-1;
}
.suzy-font-size-2 {
font-size: $suzy-font-size-2;
}
.suzy-font-size-3 {
font-size: $suzy-font-size-3;
}
.suzy-font-size-4 {
font-size: $suzy-font-size-4;
}
.suzy-font-size-5 {
font-size: $suzy-font-size-5;
}
.suzy-font-size-6 {
font-size: $suzy-font-size-6;
}
.suzy-font-size-7 {
font-size: $suzy-font-size-7;
}

// Font Weights
.suzy-font-weights-inter-medium {
font-weight: $suzy-font-weights-inter-medium;
}
.suzy-font-weights-inter-bold {
font-weight: $suzy-font-weights-inter-bold;
}
.suzy-font-weights-inter-regular {
font-weight: $suzy-font-weights-inter-regular;
}
.suzy-font-weights-inter-semibold {
font-weight: $suzy-font-weights-inter-semibold;
}
.suzy-font-weights-quicksand-0 {
font-weight: $suzy-font-weights-quicksand-0;
}
.suzy-font-weights-quicksand-1 {
font-weight: $suzy-font-weights-quicksand-1;
}
.suzy-font-weights-quicksand-2 {
font-weight: $suzy-font-weights-quicksand-2;
}

// Typography Composition Classes
///////////////////////

.d1 {
font-weight: $suzy-d1-font-weight;
line-height: $suzy-d1-line-height;
font-size: $suzy-d1-font-size;
letter-spacing: $suzy-d1-letter-spacing;
text-transform: $suzy-d1-text-case;
text-decoration: $suzy-d1-text-decoration;
}

.d2 {
font-weight: $suzy-d2-font-weight;
line-height: $suzy-d2-line-height;
font-size: $suzy-d2-font-size;
letter-spacing: $suzy-d2-letter-spacing;
text-transform: $suzy-d2-text-case;
text-decoration: $suzy-d2-text-decoration;
}

.h1 {
font-weight: $suzy-h1-font-weight;
line-height: $suzy-h1-line-height;
font-size: $suzy-h1-font-size;
letter-spacing: $suzy-h1-letter-spacing;
text-transform: $suzy-h1-text-case;
text-decoration: $suzy-h1-text-decoration;
}

.h2 {
font-weight: $suzy-h2-font-weight;
line-height: $suzy-h2-line-height;
font-size: $suzy-h2-font-size;
letter-spacing: $suzy-h2-letter-spacing;
text-transform: $suzy-h2-text-case;
text-decoration: $suzy-h2-text-decoration;
}

.h3 {
font-weight: $suzy-h3-font-weight;
line-height: $suzy-h3-line-height;
font-size: $suzy-h3-font-size;
letter-spacing: $suzy-h3-letter-spacing;
text-transform: $suzy-h3-text-case;
text-decoration: $suzy-h3-text-decoration;
}

.h4 {
font-weight: $suzy-h4-font-weight;
line-height: $suzy-h4-line-height;
font-size: $suzy-h4-font-size;
letter-spacing: $suzy-h4-letter-spacing;
text-transform: $suzy-h4-text-case;
text-decoration: $suzy-h4-text-decoration;
}

.h5 {
font-weight: $suzy-h5-font-weight;
line-height: $suzy-h5-line-height;
font-size: $suzy-h5-font-size;
letter-spacing: $suzy-h5-letter-spacing;
text-transform: $suzy-h5-text-case;
text-decoration: $suzy-h5-text-decoration;
}

.eyebrow {
font-weight: $suzy-eyebrow-font-weight;
line-height: $suzy-eyebrow-line-height;
font-size: $suzy-eyebrow-font-size;
letter-spacing: $suzy-eyebrow-letter-spacing;
text-transform: $suzy-eyebrow-text-case;
text-decoration: $suzy-eyebrow-text-decoration;
}

.body-copy-large {
font-weight: $suzy-body-copy-large-font-weight;
line-height: $suzy-body-copy-large-line-height;
font-size: $suzy-body-copy-large-font-size;
letter-spacing: $suzy-body-copy-large-letter-spacing;
text-transform: $suzy-body-copy-large-text-case;
text-decoration: $suzy-body-copy-large-text-decoration;
}

.body-copy-regular {
font-weight: $suzy-body-copy-regular-font-weight;
line-height: $suzy-body-copy-regular-line-height;
font-size: $suzy-body-copy-regular-font-size;
letter-spacing: $suzy-body-copy-regular-letter-spacing;
text-transform: $suzy-body-copy-regular-text-case;
text-decoration: $suzy-body-copy-regular-text-decoration;
}

.body-copy-bold {
font-weight: $suzy-body-copy-bold-font-weight;
line-height: $suzy-body-copy-bold-line-height;
font-size: $suzy-body-copy-bold-font-size;
letter-spacing: $suzy-body-copy-bold-letter-spacing;
text-transform: $suzy-body-copy-bold-text-case;
text-decoration: $suzy-body-copy-bold-text-decoration;
}

.body-copy-medium {
font-weight: $suzy-body-copy-medium-font-weight;
line-height: $suzy-body-copy-medium-line-height;
font-size: $suzy-body-copy-medium-font-size;
letter-spacing: $suzy-body-copy-medium-letter-spacing;
text-transform: $suzy-body-copy-medium-text-case;
text-decoration: $suzy-body-copy-medium-text-decoration;
}

.body-copy-small {
font-weight: $suzy-body-copy-small-font-weight;
line-height: $suzy-body-copy-small-line-height;
font-size: $suzy-body-copy-small-font-size;
letter-spacing: $suzy-body-copy-small-letter-spacing;
text-transform: $suzy-body-copy-small-text-case;
text-decoration: $suzy-body-copy-small-text-decoration;
}

.body-copy-small-bold {
font-weight: $suzy-body-copy-small-bold-font-weight;
line-height: $suzy-body-copy-small-bold-line-height;
font-size: $suzy-body-copy-small-bold-font-size;
letter-spacing: $suzy-body-copy-small-bold-letter-spacing;
text-transform: $suzy-body-copy-small-bold-text-case;
text-decoration: $suzy-body-copy-small-bold-text-decoration;
}

.body-copy-caption {
font-weight: $suzy-body-copy-caption-font-weight;
line-height: $suzy-body-copy-caption-line-height;
font-size: $suzy-body-copy-caption-font-size;
letter-spacing: $suzy-body-copy-caption-letter-spacing;
text-transform: $suzy-body-copy-caption-text-case;
text-decoration: $suzy-body-copy-caption-text-decoration;
}

.assistive-text {
font-weight: $suzy-assistive-text-font-weight;
line-height: $suzy-assistive-text-line-height;
font-size: $suzy-assistive-text-font-size;
letter-spacing: $suzy-assistive-text-letter-spacing;
text-transform: $suzy-assistive-text-text-case;
text-decoration: $suzy-assistive-text-text-decoration;
}

.button-text{
font-weight: $suzy-button-text-font-weight;
line-height: $suzy-button-text-line-height;
font-size: $suzy-button-text-font-size;
letter-spacing: $suzy-button-text-letter-spacing;
text-transform: $suzy-button-text-text-case;
text-decoration: $suzy-button-text-text-decoration;
}

.button-text-small {
font-weight: $suzy-button-text-small-font-weight;
line-height: $suzy-button-text-small-line-height;
font-size: $suzy-button-text-small-font-size;
letter-spacing: $suzy-button-text-small-letter-spacing;
text-transform: $suzy-button-text-small-text-case;
text-decoration: $suzy-button-text-small-text-decoration;
}

.text-link {
font-weight: $suzy-link-text-font-weight;
line-height: $suzy-link-text-line-height;
font-size: $suzy-link-text-font-size;
letter-spacing: $suzy-link-text-letter-spacing;
text-transform: $suzy-link-text-text-case;
text-decoration: $suzy-link-text-text-decoration;
  &:hover {
    color: $suzy-purple-500;
  }
  &:active {
    color: $suzy-purple-600;
  }
}

.text-link-small {
font-weight: $suzy-link-text-small-font-weight;
line-height: $suzy-link-text-small-line-height;
font-size: $suzy-link-text-small-font-size;
letter-spacing: $suzy-link-text-small-letter-spacing;
text-transform: $suzy-link-text-small-text-case;
text-decoration: $suzy-link-text-small-text-decoration;
  &:hover {
    color: $suzy-purple-500;
  }
  &:active {
    color: $suzy-purple-600;
  }
}

.text-link-x-small {
font-weight: $suzy-link-text-x-small-font-weight;
line-height: $suzy-link-text-x-small-line-height;
font-size: $suzy-link-text-x-small-font-size;
letter-spacing: $suzy-link-text-x-small-letter-spacing;
text-transform: $suzy-link-text-x-small-text-case;
text-decoration: $suzy-link-text-x-small-text-decoration;
  &:hover {
    color: $suzy-purple-500;
  }
  &:active {
    color: $suzy-purple-600;
  }
}

.text-link-title {
font-weight: $suzy-link-title-font-weight;
line-height: $suzy-link-title-line-height;
font-size: $suzy-link-title-font-size;
letter-spacing: $suzy-link-title-letter-spacing;
text-transform: $suzy-link-title-text-case;
text-decoration: $suzy-link-title-text-decoration;
  .suzy-icon {
    display: inline-block;
    &:hover {
      text-decoration: none !important;
    }
  }
  .material-symbols-rounded {
    display: inline-block;
    &:hover {
      text-decoration: none !important;
    }
  }
  &:hover {
    color: $suzy-purple-500;
    text-decoration: underline;
  }
  &:active {
    color: $suzy-purple-600;
  }
}

.label {
font-weight: $suzy-label-font-weight;
line-height: $suzy-label-line-height;
font-size: $suzy-label-font-size;
letter-spacing: $suzy-label-letter-spacing;
text-transform: $suzy-label-text-case;
text-decoration: $suzy-label-text-decoration;
}

.secondary-label {
font-weight: $suzy-secondary-label-font-weight;
line-height: $suzy-secondary-label-line-height;
font-size: $suzy-secondary-label-font-size;
letter-spacing: $suzy-secondary-label-letter-spacing;
text-transform: $suzy-secondary-label-text-case;
text-decoration: $suzy-secondary-label-text-decoration;
}

.input-text {
font-weight: $suzy-input-font-weight;
line-height: $suzy-input-line-height;
font-size: $suzy-input-font-size;
letter-spacing: $suzy-input-letter-spacing;
text-transform: $suzy-input-text-case;
text-decoration: $suzy-input-text-decoration;
}

.input-label {
font-weight: $suzy-input-label-font-weight;
line-height: $suzy-input-label-line-height;
font-size: $suzy-input-label-font-size;
letter-spacing: $suzy-input-label-letter-spacing;
text-transform: $suzy-input-label-text-case;
text-decoration: $suzy-input-label-text-decoration;
}

.input-label-small {
font-weight: $suzy-input-label-small-font-weight;
line-height: $suzy-input-label-small-line-height;
font-size: $suzy-input-label-small-font-size;
letter-spacing: $suzy-input-label-small-letter-spacing;
text-transform: $suzy-input-label-small-text-case;
text-decoration: $suzy-input-label-small-text-decoration;
}

// Box Shadow Classes
///////////////////////

.box-shadow-lightbox {
  box-shadow: $suzy-box-shadow-lightbox;
}

.box-shadow-small {
box-shadow: $suzy-box-shadow-small;
}

.box-shadow-medium {
box-shadow: $suzy-box-shadow-medium;
}

.box-shadow-large {
box-shadow: $suzy-box-shadow-large;
}

// Spacing Padding Classes
///////////////////////

.spacing-padding-0 {
padding: 0px;
}

.spacing-padding-y-0 {
padding-top: 0px;
padding-bottom: 0px;
}

.spacing-padding-x-0 {
padding-left: 0px;
padding-right: 0px;
}

.spacing-padding-t-0 {
padding-top: 0px;
}

.spacing-padding-r-0 {
padding-right: 0px;
}

.spacing-padding-b-0 {
padding-bottom: 0px;
}

.spacing-padding-l-0 {
padding-left: 0px;
}

.spacing-padding-4 {
padding: $suzy-spacing-4;
}

.spacing-padding-y-4 {
padding-top: $suzy-spacing-4;
padding-bottom: $suzy-spacing-4;
}

.spacing-padding-x-4 {
padding-left: $suzy-spacing-4;
padding-right: $suzy-spacing-4;
}

.spacing-padding-t-4 {
padding-top: $suzy-spacing-4;
}

.spacing-padding-r-4 {
padding-right: $suzy-spacing-4;
}

.spacing-padding-b-4 {
padding-bottom: $suzy-spacing-4;
}

.spacing-padding-l-4 {
padding-left: $suzy-spacing-4;
}

.spacing-padding-8 {
padding: $suzy-spacing-8;
}

.spacing-padding-y-8 {
padding-top: $suzy-spacing-8;
padding-bottom: $suzy-spacing-8;
}

.spacing-padding-x-8 {
padding-left: $suzy-spacing-8;
padding-right: $suzy-spacing-8;
}

.spacing-padding-t-8 {
padding-top: $suzy-spacing-8;
}

.spacing-padding-r-8 {
padding-right: $suzy-spacing-8;
}

.spacing-padding-b-8 {
padding-bottom: $suzy-spacing-8;
}

.spacing-padding-l-8 {
padding-left: $suzy-spacing-8;
}

.spacing-padding-12 {
padding: $suzy-spacing-12;
}

.spacing-padding-y-12 {
padding-top: $suzy-spacing-12;
padding-bottom: $suzy-spacing-12;
}

.spacing-padding-x-12 {
padding-left: $suzy-spacing-12;
padding-right: $suzy-spacing-12;
}

.spacing-padding-t-12 {
padding-top: $suzy-spacing-12;
}

.spacing-padding-r-12 {
padding-right: $suzy-spacing-12;
}

.spacing-padding-b-12 {
padding-bottom: $suzy-spacing-12;
}

.spacing-padding-l-12 {
padding-left: $suzy-spacing-12;
}

.spacing-padding-16 {
padding: $suzy-spacing-16;
}

.spacing-padding-y-16 {
padding-top: $suzy-spacing-16;
padding-bottom: $suzy-spacing-16;
}

.spacing-padding-x-16 {
padding-left: $suzy-spacing-16;
padding-right: $suzy-spacing-16;
}

.spacing-padding-t-16 {
padding-top: $suzy-spacing-16;
}

.spacing-padding-r-16 {
padding-right: $suzy-spacing-16;
}

.spacing-padding-b-16 {
padding-bottom: $suzy-spacing-16;
}

.spacing-padding-l-16 {
padding-left: $suzy-spacing-16;
}

.spacing-padding-20 {
padding: $suzy-spacing-20;
}

.spacing-padding-y-20 {
padding-top: $suzy-spacing-20;
padding-bottom: $suzy-spacing-20;
}

.spacing-padding-x-20 {
padding-left: $suzy-spacing-20;
padding-right: $suzy-spacing-20;
}

.spacing-padding-t-20 {
padding-top: $suzy-spacing-20;
}

.spacing-padding-r-20 {
padding-right: $suzy-spacing-20;
}

.spacing-padding-b-20 {
padding-bottom: $suzy-spacing-20;
}

.spacing-padding-l-20 {
padding-left: $suzy-spacing-20;
}

.spacing-padding-24 {
padding: $suzy-spacing-24;
}

.spacing-padding-y-24 {
padding-top: $suzy-spacing-24;
padding-bottom: $suzy-spacing-24;
}

.spacing-padding-x-24 {
padding-left: $suzy-spacing-24;
padding-right: $suzy-spacing-24;
}

.spacing-padding-t-24 {
padding-top: $suzy-spacing-24;
}

.spacing-padding-r-24 {
padding-right: $suzy-spacing-24;
}

.spacing-padding-b-24 {
padding-bottom: $suzy-spacing-24;
}

.spacing-padding-l-24 {
padding-left: $suzy-spacing-24;
}

.spacing-padding-32 {
padding: $suzy-spacing-32;
}

.spacing-padding-y-32 {
padding-top: $suzy-spacing-32;
padding-bottom: $suzy-spacing-32;
}

.spacing-padding-x-32 {
padding-left: $suzy-spacing-32;
padding-right: $suzy-spacing-32;
}

.spacing-padding-t-32 {
padding-top: $suzy-spacing-32;
}

.spacing-padding-r-32 {
padding-right: $suzy-spacing-32;
}

.spacing-padding-b-32 {
padding-bottom: $suzy-spacing-32;
}

.spacing-padding-l-32 {
padding-left: $suzy-spacing-32;
}

.spacing-padding-36 {
padding: $suzy-spacing-36;
}

.spacing-padding-y-36 {
padding-top: $suzy-spacing-36;
padding-bottom: $suzy-spacing-36;
}

.spacing-padding-x-36 {
padding-left: $suzy-spacing-36;
padding-right: $suzy-spacing-36;
}

.spacing-padding-t-36 {
padding-top: $suzy-spacing-36;
}

.spacing-padding-r-36 {
padding-right: $suzy-spacing-36;
}

.spacing-padding-b-36 {
padding-bottom: $suzy-spacing-36;
}

.spacing-padding-l-36 {
padding-left: $suzy-spacing-36;
}

.spacing-padding-40 {
padding: $suzy-spacing-40;
}

.spacing-padding-y-40 {
padding-top: $suzy-spacing-40;
padding-bottom: $suzy-spacing-40;
}

.spacing-padding-x-40 {
padding-left: $suzy-spacing-40;
padding-right: $suzy-spacing-40;
}

.spacing-padding-t-40 {
padding-top: $suzy-spacing-40;
}

.spacing-padding-r-40 {
padding-right: $suzy-spacing-40;
}

.spacing-padding-b-40 {
padding-bottom: $suzy-spacing-40;
}

.spacing-padding-l-40 {
padding-left: $suzy-spacing-40;
}

// Spacing Margin Classes
///////////////////////

.spacing-margin-0 {
margin: 0px;
}

.spacing-margin-y-0 {
margin-top: 0px;
margin-bottom: 0px;
}

.spacing-margin-x-0 {
margin-left: 0px;
margin-right: 0px;
}

.spacing-margin-t-0 {
margin-top: 0px;
}

.spacing-margin-r-0 {
margin-right: 0px;
}

.spacing-margin-b-0 {
margin-bottom: 0px;
}

.spacing-margin-l-0 {
margin-left: 0px;
}

.spacing-margin-4 {
margin: $suzy-spacing-4;
}

.spacing-margin-y-4 {
margin-top: $suzy-spacing-4;
margin-bottom: $suzy-spacing-4;
}

.spacing-margin-x-4 {
margin-left: $suzy-spacing-4;
margin-right: $suzy-spacing-4;
}

.spacing-margin-t-4 {
margin-top: $suzy-spacing-4;
}

.spacing-margin-r-4 {
margin-right: $suzy-spacing-4;
}

.spacing-margin-b-4 {
margin-bottom: $suzy-spacing-4;
}

.spacing-margin-l-4 {
margin-left: $suzy-spacing-4;
}

.spacing-margin-8 {
margin: $suzy-spacing-8;
}

.spacing-margin-y-8 {
margin-top: $suzy-spacing-8;
margin-bottom: $suzy-spacing-8;
}

.spacing-margin-x-8 {
margin-left: $suzy-spacing-8;
margin-right: $suzy-spacing-8;
}

.spacing-margin-t-8 {
margin-top: $suzy-spacing-8;
}

.spacing-margin-r-8 {
margin-right: $suzy-spacing-8;
}

.spacing-margin-b-8 {
margin-bottom: $suzy-spacing-8;
}

.spacing-margin-l-8 {
margin-left: $suzy-spacing-8;
}

.spacing-margin-12 {
margin: $suzy-spacing-12;
}

.spacing-margin-y-12 {
margin-top: $suzy-spacing-12;
margin-bottom: $suzy-spacing-12;
}

.spacing-margin-x-12 {
margin-left: $suzy-spacing-12;
margin-right: $suzy-spacing-12;
}

.spacing-margin-t-12 {
margin-top: $suzy-spacing-12;
}

.spacing-margin-r-12 {
margin-right: $suzy-spacing-12;
}

.spacing-margin-b-12 {
margin-bottom: $suzy-spacing-12;
}

.spacing-margin-l-12 {
margin-left: $suzy-spacing-12;
}

.spacing-margin-16 {
margin: $suzy-spacing-16;
}

.spacing-margin-y-16 {
margin-top: $suzy-spacing-16;
margin-bottom: $suzy-spacing-16;
}

.spacing-margin-x-16 {
margin-left: $suzy-spacing-16;
margin-right: $suzy-spacing-16;
}

.spacing-margin-t-16 {
margin-top: $suzy-spacing-16;
}

.spacing-margin-r-16 {
margin-right: $suzy-spacing-16;
}

.spacing-margin-b-16 {
margin-bottom: $suzy-spacing-16;
}

.spacing-margin-l-16 {
margin-left: $suzy-spacing-16;
}

.spacing-margin-20 {
margin: $suzy-spacing-20;
}

.spacing-margin-y-20 {
margin-top: $suzy-spacing-20;
margin-bottom: $suzy-spacing-20;
}

.spacing-margin-x-20 {
margin-left: $suzy-spacing-20;
margin-right: $suzy-spacing-20;
}

.spacing-margin-t-20 {
margin-top: $suzy-spacing-20;
}

.spacing-margin-r-20 {
margin-right: $suzy-spacing-20;
}

.spacing-margin-b-20 {
margin-bottom: $suzy-spacing-20;
}

.spacing-margin-l-20 {
margin-left: $suzy-spacing-20;
}

.spacing-margin-24 {
margin: $suzy-spacing-24;
}

.spacing-margin-y-24 {
margin-top: $suzy-spacing-24;
margin-bottom: $suzy-spacing-24;
}

.spacing-margin-x-24 {
margin-left: $suzy-spacing-24;
margin-right: $suzy-spacing-24;
}

.spacing-margin-t-24 {
margin-top: $suzy-spacing-24;
}

.spacing-margin-r-24 {
margin-right: $suzy-spacing-24;
}

.spacing-margin-b-24 {
margin-bottom: $suzy-spacing-24;
}

.spacing-margin-l-24 {
margin-left: $suzy-spacing-24;
}

.spacing-margin-32 {
margin: $suzy-spacing-32;
}

.spacing-margin-y-32 {
margin-top: $suzy-spacing-32;
margin-bottom: $suzy-spacing-32;
}

.spacing-margin-x-32 {
margin-left: $suzy-spacing-32;
margin-right: $suzy-spacing-32;
}

.spacing-margin-t-32 {
margin-top: $suzy-spacing-32;
}

.spacing-margin-r-32 {
margin-right: $suzy-spacing-32;
}

.spacing-margin-b-32 {
margin-bottom: $suzy-spacing-32;
}

.spacing-margin-l-32 {
margin-left: $suzy-spacing-32;
}

.spacing-margin-36 {
margin: $suzy-spacing-36;
}

.spacing-margin-y-36 {
margin-top: $suzy-spacing-36;
margin-bottom: $suzy-spacing-36;
}

.spacing-margin-x-36 {
margin-left: $suzy-spacing-36;
margin-right: $suzy-spacing-36;
}

.spacing-margin-t-36 {
margin-top: $suzy-spacing-36;
}

.spacing-margin-r-36 {
margin-right: $suzy-spacing-36;
}

.spacing-margin-b-36 {
margin-bottom: $suzy-spacing-36;
}

.spacing-margin-l-36 {
margin-left: $suzy-spacing-36;
}

.spacing-margin-40 {
margin: $suzy-spacing-40;
}

.spacing-margin-y-40 {
margin-top: $suzy-spacing-40;
margin-bottom: $suzy-spacing-40;
}

.spacing-margin-x-40 {
margin-left: $suzy-spacing-40;
margin-right: $suzy-spacing-40;
}

.spacing-margin-t-40 {
margin-top: $suzy-spacing-40;
}

.spacing-margin-r-40 {
margin-right: $suzy-spacing-40;
}

.spacing-margin-b-40 {
margin-bottom: $suzy-spacing-40;
}

.spacing-margin-l-40 {
margin-left: $suzy-spacing-40;
}

// Suzy Border Radius Classes
///////////////////////

.suzy-border-radius-0 {
border-radius: 0px;
}

.suzy-border-l-radius-0 {
border-top-left-radius: 0px;
border-bottom-left-radius: 0px;
}

.suzy-border-r-radius-0 {
border-top-right-radius: 0px;
border-bottom-right-radius: 0px;
}

.suzy-border-top-left-radius-0 {
border-top-left-radius: 0px;
}

.suzy-border-top-right-radius-0 {
border-top-right-radius: 0px;
}

.suzy-border-bottom-right-radius-0 {
border-bottom-right-radius: 0px;
}

.suzy-border-bottom-left-radius-0 {
border-bottom-left-radius: 0px;
}

.suzy-border-radius-4 {
border-radius: $suzy-spacing-4;
}

.suzy-border-l-radius-4 {
border-top-left-radius: $suzy-spacing-4;
border-bottom-left-radius: $suzy-spacing-4;
}

.suzy-border-r-radius-4 {
border-top-right-radius: $suzy-spacing-4;
border-bottom-right-radius: $suzy-spacing-4;
}

.suzy-border-top-left-radius-4 {
border-top-left-radius: $suzy-spacing-4;
}

.suzy-border-top-right-radius-4 {
border-top-right-radius: $suzy-spacing-4;
}

.suzy-border-bottom-right-radius-4 {
border-bottom-right-radius: $suzy-spacing-4;
}

.suzy-border-bottom-left-radius-4 {
border-bottom-left-radius: $suzy-spacing-4;
}

.suzy-border-radius-8 {
border-radius: $suzy-spacing-8;
}

.suzy-border-l-radius-8 {
border-top-left-radius: $suzy-spacing-8;
border-bottom-left-radius: $suzy-spacing-8;
}

.suzy-border-r-radius-8 {
border-top-right-radius: $suzy-spacing-8;
border-bottom-right-radius: $suzy-spacing-8;
}

.suzy-border-top-left-radius-8 {
border-top-left-radius: $suzy-spacing-8;
}

.suzy-border-top-right-radius-8 {
border-top-right-radius: $suzy-spacing-8;
}

.suzy-border-bottom-right-radius-8 {
border-bottom-right-radius: $suzy-spacing-8;
}

.suzy-border-bottom-left-radius-8 {
border-bottom-left-radius: $suzy-spacing-8;
}

.suzy-border-radius-12 {
border-radius: $suzy-spacing-12;
}

.suzy-border-l-radius-12 {
border-top-left-radius: $suzy-spacing-12;
border-bottom-left-radius: $suzy-spacing-12;
}

.suzy-border-r-radius-12 {
border-top-right-radius: $suzy-spacing-12;
border-bottom-right-radius: $suzy-spacing-12;
}

.suzy-border-top-left-radius-12 {
border-top-left-radius: $suzy-spacing-12;
}

.suzy-border-top-right-radius-12 {
border-top-right-radius: $suzy-spacing-12;
}

.suzy-border-bottom-right-radius-12 {
border-bottom-right-radius: $suzy-spacing-12;
}

.suzy-border-bottom-left-radius-12 {
border-bottom-left-radius: $suzy-spacing-12;
}

.suzy-border-radius-16 {
border-radius: $suzy-spacing-16;
}

.suzy-border-l-radius-16 {
border-top-left-radius: $suzy-spacing-16;
border-bottom-left-radius: $suzy-spacing-16;
}

.suzy-border-r-radius-16 {
border-top-right-radius: $suzy-spacing-16;
border-bottom-right-radius: $suzy-spacing-16;
}

.suzy-border-top-left-radius-16 {
border-top-left-radius: $suzy-spacing-16;
}

.suzy-border-top-right-radius-16 {
border-top-right-radius: $suzy-spacing-16;
}

.suzy-border-bottom-right-radius-16 {
border-bottom-right-radius: $suzy-spacing-16;
}

.suzy-border-bottom-left-radius-16 {
border-bottom-left-radius: $suzy-spacing-16;
}

.suzy-border-radius-20 {
border-radius: $suzy-spacing-20;
}

.suzy-border-l-radius-20 {
border-top-left-radius: $suzy-spacing-20;
border-bottom-left-radius: $suzy-spacing-20;
}

.suzy-border-r-radius-20 {
border-top-right-radius: $suzy-spacing-20;
border-bottom-right-radius: $suzy-spacing-20;
}

.suzy-border-top-left-radius-20 {
border-top-left-radius: $suzy-spacing-20;
}

.suzy-border-top-right-radius-20 {
border-top-right-radius: $suzy-spacing-20;
}

.suzy-border-bottom-right-radius-20 {
border-bottom-right-radius: $suzy-spacing-20;
}

.suzy-border-bottom-left-radius-20 {
border-bottom-left-radius: $suzy-spacing-20;
}

.suzy-border-radius-24 {
border-radius: $suzy-spacing-24;
}

.suzy-border-l-radius-24 {
border-top-left-radius: $suzy-spacing-24;
border-bottom-left-radius: $suzy-spacing-24;
}

.suzy-border-r-radius-24 {
border-top-right-radius: $suzy-spacing-24;
border-bottom-right-radius: $suzy-spacing-24;
}

.suzy-border-top-left-radius-24 {
border-top-left-radius: $suzy-spacing-24;
}

.suzy-border-top-right-radius-24 {
border-top-right-radius: $suzy-spacing-24;
}

.suzy-border-bottom-right-radius-24 {
border-bottom-right-radius: $suzy-spacing-24;
}

.suzy-border-bottom-left-radius-24 {
border-bottom-left-radius: $suzy-spacing-24;
}

.suzy-border-radius-32 {
border-radius: $suzy-spacing-32;
}

.suzy-border-l-radius-32 {
border-top-left-radius: $suzy-spacing-32;
border-bottom-left-radius: $suzy-spacing-32;
}

.suzy-border-r-radius-32 {
border-top-right-radius: $suzy-spacing-32;
border-bottom-right-radius: $suzy-spacing-32;
}

.suzy-border-top-left-radius-32 {
border-top-left-radius: $suzy-spacing-32;
}

.suzy-border-top-right-radius-32 {
border-top-right-radius: $suzy-spacing-32;
}

.suzy-border-bottom-right-radius-32 {
border-bottom-right-radius: $suzy-spacing-32;
}

.suzy-border-bottom-left-radius-32 {
border-bottom-left-radius: $suzy-spacing-32;
}

.suzy-border-radius-36 {
border-radius: $suzy-spacing-36;
}

.suzy-border-l-radius-36 {
border-top-left-radius: $suzy-spacing-36;
border-bottom-left-radius: $suzy-spacing-36;
}

.suzy-border-r-radius-36 {
border-top-right-radius: $suzy-spacing-36;
border-bottom-right-radius: $suzy-spacing-36;
}

.suzy-border-top-left-radius-36 {
border-top-left-radius: $suzy-spacing-36;
}

.suzy-border-top-right-radius-36 {
border-top-right-radius: $suzy-spacing-36;
}

.suzy-border-bottom-right-radius-36 {
border-bottom-right-radius: $suzy-spacing-36;
}

.suzy-border-bottom-left-radius-36 {
border-bottom-left-radius: $suzy-spacing-36;
}

.suzy-border-radius-40 {
border-radius: $suzy-spacing-40;
}

.suzy-border-l-radius-40 {
border-top-left-radius: $suzy-spacing-40;
border-bottom-left-radius: $suzy-spacing-40;
}

.suzy-border-r-radius-40 {
border-top-right-radius: $suzy-spacing-40;
border-bottom-right-radius: $suzy-spacing-40;
}

.suzy-border-top-left-radius-40 {
border-top-left-radius: $suzy-spacing-40;
}

.suzy-border-top-right-radius-40 {
border-top-right-radius: $suzy-spacing-40;
}

.suzy-border-bottom-right-radius-40 {
border-bottom-right-radius: $suzy-spacing-40;
}

.suzy-border-bottom-left-radius-40 {
border-bottom-left-radius: $suzy-spacing-40;
}

// Suzy Icon Weight Classes
///////////////////////

.icon-light {
font-weight: 300;
}

.icon-medium {
font-weight: 500;
}

.icon-heavy {
  font-weight: 700;
}

// Suzy Circle and Line Classes
///////////////////////

.suzy-circle-filled-active {
  border-radius: 50%;
  background: $suzy-core-blue-500;
  color: $suzy-white;
  padding: $suzy-spacing-4;
  min-width: $suzy-spacing-24;
  min-height: $suzy-spacing-24;
  line-height: $suzy-spacing-24;
  position: relative;
  .circle-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
  }
}

.suzy-circle-filled-inactive {
border-radius: 50%;
background: $suzy-grey-500;
color: $suzy-white;
padding: $suzy-spacing-4;
min-width: $suzy-spacing-24;
min-height: $suzy-spacing-24;
line-height: $suzy-spacing-24;
position: relative;
.circle-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
}
}

.suzy-circle-filled-enabled {
border-radius: 50%;
background: $suzy-purple-700;
color: $suzy-white;
padding: $suzy-spacing-4;
min-width: $suzy-spacing-24;
min-height: $suzy-spacing-24;
line-height: $suzy-spacing-24;
position: relative;
.circle-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
}
}

.suzy-circle-filled-completed {
border-radius: 50%;
background: $suzy-purple-700;
color: $suzy-white;
padding: $suzy-spacing-4;
min-width: $suzy-spacing-24;
min-height: $suzy-spacing-24;
line-height: $suzy-spacing-24;
position: relative;
.circle-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
}
}

.suzy-circle-active {
display: flex;
padding: $suzy-spacing-4;
width: $suzy-spacing-20;
height: $suzy-spacing-20;
line-height: $suzy-spacing-24;
position: relative;
border: 3px solid $suzy-core-blue-500;
background: transparent;
color: $suzy-white;
box-sizing: border-box;
border-radius: 50%;
.circle-content {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
display: flex;
}
}

.suzy-circle-inactive {
display: flex;
padding: $suzy-spacing-4;
width: $suzy-spacing-20;
height: $suzy-spacing-20;
line-height: $suzy-spacing-24;
position: relative;
border: 3px solid $suzy-grey-500;
background: transparent;;
color: $suzy-white;
box-sizing: border-box;
border-radius: 50%;
.circle-content {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
display: flex;
}
}

.suzy-circle-completed {
  display: flex;
  padding: $suzy-spacing-4;
  width: $suzy-spacing-20;
  height: $suzy-spacing-20;
  line-height: $suzy-spacing-24;
  position: relative;
  background: $suzy-core-blue-500;
  color: $suzy-white;
  box-sizing: border-box;
  border-radius: 50%;
  .circle-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
  }
}

.suzy-line {
width: 100%;
height: 2px;
background: $suzy-grey-300;
}

// Suzy Material Tab Classes
///////////////////////

.suzy-mat-tab {
font-family: $suzy-font-families-inter;
.mat-ripple-element {
  transition-duration: 0ms;
  display: none;
}
.mat-tab-header {
  border-bottom: 2px solid $suzy-grey-400;
  overflow: inherit;
  margin-bottom: $suzy-spacing-32;
}
.mat-tab-label {
  color: $suzy-grey-800;
  padding: 13px $suzy-spacing-20;
  opacity: 1;
  min-width: auto;
  width: 100%;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  white-space: nowrap;
    &:focus-visible {
    border: 1px solid 2px solid $suzy-blue-500;
    background-color: transparent;
    border-radius: 2px;
    }
}
.mat-tab-label-container {
  overflow: inherit;
.mat-ink-bar {
background-color: $suzy-purple-500;
height: $suzy-spacing-4;
border-radius: $suzy-spacing-24 $suzy-spacing-24 0 0;
bottom: -1px;
}
}
.mat-tab-label-active {
color: $suzy-purple-500;
.mat-tab-label-content {
color: $suzy-purple-500;
}
}
.mat-tab-label-content:not(.mat-tab-disabled) {
@extend .button-text-small;
font-weight: 400;
&:hover {
color: $suzy-purple-500;
}
&:active {
color: $suzy-purple-600;
}
}
.mat-tab-labels {
  .mat-tab-disabled {
    color: $suzy-grey-400;
    cursor: not-allowed;
    .mat-tab-label-content {
      @extend .button-text-small;
      font-weight: 400;
        &:hover {
        color: $suzy-grey-400;
        }
        &:active {
        color: $suzy-grey-400;
        }
    }
    &:active {
    border: 1px solid none;
    }
  }
}
}

.suzy-mat-tab-left-aligned {
  font-family: $suzy-font-families-inter;
  .mat-ripple-element {
    transition-duration: 0ms;
    display: none;
  }
  .mat-tab-header {
  border-bottom: 2px solid $suzy-grey-400;
  overflow: inherit;
  margin-bottom: $suzy-spacing-32;
  }
  .mat-tab-label {
  color: $suzy-grey-800;
  padding: 13px $suzy-spacing-20;
  opacity: 1;
  min-width: 180px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  white-space: nowrap;
  &:focus-visible {
  border: 1px solid 2px solid $suzy-blue-500;
  background-color: transparent;
  border-radius: 2px;
  }
  }
  .mat-tab-label-container {
  overflow: inherit;
  .mat-ink-bar {
  background-color: $suzy-purple-500;
  height: $suzy-spacing-4;
  border-radius: $suzy-spacing-24 $suzy-spacing-24 0 0;
  bottom: -1px;
  }
  }
  .mat-tab-label-active {
  color: $suzy-purple-500;
  .mat-tab-label-content {
  color: $suzy-purple-500;
  }
  }
  .mat-tab-label-content:not(.mat-tab-disabled) {
  @extend .button-text-small;
  font-weight: 400;
  &:hover {
  color: $suzy-purple-500;
  }
  &:active {
  color: $suzy-purple-600;
  }
  }
  .mat-tab-labels {
  .mat-tab-disabled {
  color: $suzy-grey-400;
  cursor: not-allowed;
  .mat-tab-label-content {
  @extend .button-text-small;
  font-weight: 400;
  &:hover {
  color: $suzy-grey-400;
  }
  &:active {
  color: $suzy-grey-400;
  }
  }
  &:active {
  border: 1px solid none;
  }
  }
  }
}

// Material Overrides
///////////////////////

mat-icon {
  &.selected {
  color: $suzy-purple-500;
  }
}

.mat-menu-panel.suzy-mat-menu {
    min-height: 0px !important;
}

.suzy-mat-menu{
  .mat-menu-item.voe-mat-button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: $suzy-spacing-8;
    padding: $suzy-spacing-8;
    margin: 0;
    min-width: 176px;
    max-height: 36px;
    @extend .body-copy-small;
    color: $suzy-grey-900;
    .mat-ripple {
      display: none;
    }
    &:hover {
      cursor: pointer;
      background-color: $suzy-grey-100;
    }
    &:active {
      background-color: $suzy-grey-200;
    }
  }
  .mat-menu-item.suzy-mat-button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: $suzy-spacing-8;
    padding: $suzy-spacing-8;
    margin: 0;
    min-width: 176px;
    max-height: 36px;
    @extend .body-copy-small;
    color: $suzy-grey-900;
    .mat-ripple {
    display: none;
    }
    &:hover {
      cursor: pointer;
      background-color: $suzy-grey-100;
    }
    &:active {
      background-color: $suzy-grey-200;
    }
  }
}

.suzy-toe-card {
  .mat-checkbox-persistent-ripple {
    display: none;
  }
}
