@import 'igniteui';
@import '@asksuzy/suzy-design-tokens/dist/web/suzy-core/scss/all-tokens';
@import '@asksuzy/suzy-design-tokens/dist/web/suzy-core/scss/token-classes';
@import 'standard-modal';
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import '@angular/cdk/overlay-prebuilt.css';
@include core();
@include typography(
  $font-family: $my-typeface,
  $type-scale: $material-type-scale
);
@include theme($my-color-palette);

:root {
  @include drop-down($drop-down-theme);
  @include dialog($my-dialog-theme);
  font-size: 14px !important;

  .igx-icon {
    &.no-color-fill {
      div,
      svg {
        fill: none !important;
      }
    }
  }

  .igx-dialog .igx-dialog__window {
    padding: 0;
  }

  .igx-dialog__window {
    border: none;
    border-radius: none;
    background: transparent;
    box-shadow: none;
  }
}

.suzy-text-input.mat-form-field .mat-form-field-label {
  padding-top: 0px;
}

.suzy-searchable-input-alt.mat-form-field .mat-input-element {
  padding-bottom: 4px;
}

.no-scroll {
  overflow: hidden;
}

.dialog-backdrop {
  background-color: rgba(0, 0, 0, 0.6);
}

.suzy-secondary-extended {
  @extend .suzy-secondary-text !optional;
  text-wrap: nowrap;
  min-width: unset;
}

.debug {
  border: 1px dashed red;
}

.gray-fit-button {
  gap: 4px;
  height: 32px;
  padding: 8px 24px;
  background: #ffffff;
  border: 1px solid #b0b5bf;
  cursor: pointer;
  color: #4e5258;
  font-weight: 500;
  font-family: 'Inter', Helvetica Neue, sans-serif;
  line-height: 125%;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-transform: none;
  text-decoration: none;
  border-radius: 6px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80px;
  white-space: preserve nowrap;

  &--no-border {
    @extend .gray-fit-button;
    border-width: 0 !important;
  }

  &--disabled {
    @extend .gray-fit-button;
    cursor: not-allowed !important;
    opacity: 0.8 !important;
  }
}

.cdk-overlay-container {
  z-index: 10006 !important;
}

// Suzy tooltip Class

.suzy-tooltip {
  // Text
  @extend .body-copy-small;
  color: $suzy-grey-800;
  overflow-wrap: anywhere;
  hyphens: manual;
  max-width: 100%;
  background-color: transparent;
  margin-top: 0px;
  box-shadow: none;
}

.cdk-overlay-pane {
  &.mat-tooltip-panel {
    .mat-tooltip {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 240px;
      padding: $suzy-spacing-8;
      border-radius: 6px;
      background-color: $suzy-grey-800 !important;
      box-shadow: $suzy-box-shadow-medium;
      margin-top: $suzy-spacing-8;
      // Text
      @extend .body-copy-caption;
      color: $suzy-grey-50;
      overflow-wrap: anywhere;
      hyphens: manual !important;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-help {
  cursor: help;
}
