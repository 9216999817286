// Suzy Standard Modal Class
/////////////////////////////////////////

.suzy-modal {
  padding: $suzy-spacing-32;
  border-radius: $suzy-spacing-8;
  background-color: $suzy-white;
  box-shadow: $suzy-box-shadow-large;
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: $suzy-spacing-16;
    .modal-header-left {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      gap: $suzy-spacing-8;
    }
  }
  .modal-body {
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 400px;
    &.two-column {
      display: grid;
      grid-template-columns: 50% 50%;
      gap: $suzy-spacing-8;
    }
  }
  .modal-footer-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: $suzy-spacing-16;
    padding-top:$suzy-spacing-16;
  }
  .modal-footer-centered {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $suzy-spacing-32;
    padding-top:$suzy-spacing-16;
  }
}