// All Design Token Variables
$suzy-token-set-order-0: typography;
$suzy-token-set-order-1: shadows;
$suzy-token-set-order-2: borderRadius;
$suzy-token-set-order-3: primaryButton;
$suzy-token-set-order-4: H1;
$suzy-token-set-order-5: H2;
$suzy-token-set-order-6: H3;
$suzy-token-set-order-7: H4;
$suzy-token-set-order-8: H5;
$suzy-token-set-order-9: Eyebrow;
$suzy-token-set-order-10: BodyCopyLarge;
$suzy-token-set-order-11: BodyCopyRegular;
$suzy-token-set-order-12: BodyCopyBold;
$suzy-token-set-order-13: BodyCopyMedium;
$suzy-token-set-order-14: BodyCopySmall;
$suzy-token-set-order-15: BodyCopySmallBold;
$suzy-token-set-order-16: BodyCopyCaption;
$suzy-token-set-order-17: AssistiveText;
$suzy-token-set-order-18: ButtonText;
$suzy-token-set-order-19: ButtonTextSmall;
$suzy-token-set-order-20: LinkText;
$suzy-token-set-order-21: LinkTextSmall;
$suzy-token-set-order-22: Label;
$suzy-token-set-order-23: SecondaryLabel;
$suzy-token-set-order-24: Spacing;
$suzy-token-set-order-25: D1;
$suzy-token-set-order-26: D2;
$suzy-token-set-order-27: InputText;
$suzy-token-set-order-28: InputLabel;
$suzy-token-set-order-29: LinkTextXSmall;
$suzy-token-set-order-30: colors;
$suzy-token-set-order-31: LinkTitle;
$suzy-token-set-order-32: InputLabelSmall;
$suzy-token-set-order-33: CrowdtapDesktopTypography;
$suzy-token-set-order-34: RandomWord;
$suzy-ct-primary-colors-black: #1a152e;
$suzy-ct-primary-colors-white: #ffffff;
$suzy-ct-primary-colors-grey: #cecece;
$suzy-ct-primary-colors-light-grey: #e4e4e4;
$suzy-ct-primary-colors-blue: #3589f9;
$suzy-ct-primary-colors-light-blue: #62b4ff;
$suzy-ct-primary-colors-dark-purple: #654cff;
$suzy-ct-primary-colors-purple: #ac6dfd;
$suzy-ct-primary-colors-lavender: #dac4ff;
$suzy-ct-primary-colors-emerald: #19a9a5;
$suzy-ct-primary-colors-green: #68c97f;
$suzy-ct-primary-colors-mint: #8fd2c6;
$suzy-ct-primary-colors-orange: #ff8840;
$suzy-ct-primary-colors-light-orange: #ffbd2e;
$suzy-ct-primary-colors-yellow: #feec4b;
$suzy-ct-primary-colors-pink: #ff7092;
$suzy-ct-primary-colors-peach: #ffa4a4;
$suzy-ct-primary-colors-red: #ff5f57;
$suzy-ct-darkmode-primary: #24232b;
$suzy-ct-darkmode-secondary: #141414;
$suzy-ct-darkmode-accent-1: #444444;
$suzy-ct-darkmode-accent-2: #3a3940;
$suzy-ct-darkmode-form: #3a3940;
$suzy-ct-darkmode-text: #ffffff;
$suzy-ct-darkmode-icon: #ffffff;
$suzy-ct-lightmode-primary: #fafafa;
$suzy-ct-lightmode-secondary: #e5e5e5;
$suzy-ct-lightmode-accent-1: #cecece;
$suzy-ct-lightmode-accent-2: #dbdade;
$suzy-ct-lightmode-form: #fafafa;
$suzy-ct-lightmode-text: #1a152e;
$suzy-ct-lightmode-icon: #1a152e;
$suzy-ct-buttons-hover: #1e6fdb;
$suzy-ct-buttons-focused: #1e6fdb;
$suzy-ct-buttons-pressed: #105ec6;
$suzy-ct-buttons-disabled: #b0b5bf;
$suzy-ct-line-heights-1: 130%;
$suzy-ct-line-heights-2: 150%;
$suzy-ct-font-size-0: 2.2857142857142856em;
$suzy-ct-font-size-1: 1.7142857142857142em;
$suzy-ct-font-size-2: 1.4285714285714286em;
$suzy-ct-font-size-3: 1.2857142857142858em;
$suzy-ct-font-size-4: 1.1428571428571428em;
$suzy-ct-font-size-5: 1em;
$suzy-ct-font-size-6: 0.8571428571428571em;
$suzy-ct-font-size-7: 0.7142857142857143em;
$suzy-desktop-h1-font-family: Quicksand, Helvetica Neue, sans-serif;
$suzy-desktop-h1-font-weight: 700;
$suzy-desktop-h1-line-height: 150%;
$suzy-desktop-h1-font-size: 2.2857142857142856em;
$suzy-desktop-h1-letter-spacing: 0em;
$suzy-desktop-h1-paragraph-spacing: 0;
$suzy-desktop-h1-paragraph-indent: 0;
$suzy-desktop-h1-text-case: none;
$suzy-desktop-h1-text-decoration: none;
$suzy-desktop-h2-font-family: Quicksand, Helvetica Neue, sans-serif;
$suzy-desktop-h2-font-weight: 700;
$suzy-desktop-h2-line-height: 125%;
$suzy-desktop-h2-font-size: 2.2857142857142856em;
$suzy-desktop-h2-letter-spacing: 0em;
$suzy-desktop-h2-paragraph-spacing: 0;
$suzy-desktop-h2-paragraph-indent: 0px;
$suzy-desktop-h2-text-case: none;
$suzy-desktop-h2-text-decoration: none;
$suzy-desktop-h3-font-family: Quicksand, Helvetica Neue, sans-serif;
$suzy-desktop-h3-font-weight: 700;
$suzy-desktop-h3-line-height: 125%;
$suzy-desktop-h3-font-size: 1.7142857142857142em;
$suzy-desktop-h3-letter-spacing: 0em;
$suzy-desktop-h3-paragraph-spacing: 0;
$suzy-desktop-h3-paragraph-indent: 0px;
$suzy-desktop-h3-text-case: none;
$suzy-desktop-h3-text-decoration: none;
$suzy-desktop-sub-header-font-family: Quicksand, Helvetica Neue, sans-serif;
$suzy-desktop-sub-header-font-weight: 600;
$suzy-desktop-sub-header-line-height: 125%;
$suzy-desktop-sub-header-font-size: 1.2857142857142858em;
$suzy-desktop-sub-header-letter-spacing: 0em;
$suzy-desktop-sub-header-paragraph-spacing: 0;
$suzy-desktop-sub-header-paragraph-indent: 0px;
$suzy-desktop-sub-header-text-case: none;
$suzy-desktop-sub-header-text-decoration: none;
$suzy-desktop-body-copy-font-family: Quicksand, Helvetica Neue, sans-serif;
$suzy-desktop-body-copy-font-weight: 500;
$suzy-desktop-body-copy-line-height: 125%;
$suzy-desktop-body-copy-font-size: 1em;
$suzy-desktop-body-copy-letter-spacing: 0em;
$suzy-desktop-body-copy-paragraph-spacing: 0;
$suzy-desktop-body-copy-paragraph-indent: 0px;
$suzy-desktop-body-copy-text-case: none;
$suzy-desktop-body-copy-text-decoration: none;
$suzy-desktop-body-copy-small-font-family: Quicksand, Helvetica Neue, sans-serif;
$suzy-desktop-body-copy-small-font-weight: 600;
$suzy-desktop-body-copy-small-line-height: 125%;
$suzy-desktop-body-copy-small-font-size: 0.8571428571428571em;
$suzy-desktop-body-copy-small-letter-spacing: 0em;
$suzy-desktop-body-copy-small-paragraph-spacing: 0;
$suzy-desktop-body-copy-small-paragraph-indent: 0px;
$suzy-desktop-body-copy-small-text-case: none;
$suzy-desktop-body-copy-small-text-decoration: none;
$suzy-desktop-buttons-font-family: Quicksand, Helvetica Neue, sans-serif;
$suzy-desktop-buttons-font-weight: 700;
$suzy-desktop-buttons-line-height: 125%;
$suzy-desktop-buttons-font-size: 1.1428571428571428em;
$suzy-desktop-buttons-letter-spacing: 0em;
$suzy-desktop-buttons-paragraph-spacing: 0;
$suzy-desktop-buttons-paragraph-indent: 0px;
$suzy-desktop-buttons-text-case: none;
$suzy-desktop-buttons-text-decoration: none;
$suzy-desktop-tooltips-font-family: Quicksand, Helvetica Neue, sans-serif;
$suzy-desktop-tooltips-font-weight: 600;
$suzy-desktop-tooltips-line-height: 125%;
$suzy-desktop-tooltips-font-size: 1em;
$suzy-desktop-tooltips-letter-spacing: 0em;
$suzy-desktop-tooltips-paragraph-spacing: 0;
$suzy-desktop-tooltips-paragraph-indent: 0px;
$suzy-desktop-tooltips-text-case: none;
$suzy-desktop-tooltips-text-decoration: none;
$suzy-desktop-links-font-family: Quicksand, Helvetica Neue, sans-serif;
$suzy-desktop-links-font-weight: 700;
$suzy-desktop-links-line-height: 125%;
$suzy-desktop-links-font-size: 1em;
$suzy-desktop-links-letter-spacing: 0em;
$suzy-desktop-links-paragraph-spacing: 0;
$suzy-desktop-links-paragraph-indent: 0px;
$suzy-desktop-links-text-case: none;
$suzy-desktop-links-text-decoration: none;
$suzy-desktop-captions-font-family: Quicksand, Helvetica Neue, sans-serif;
$suzy-desktop-captions-font-weight: 600;
$suzy-desktop-captions-line-height: 125%;
$suzy-desktop-captions-font-size: 0.8571428571428571em;
$suzy-desktop-captions-letter-spacing: 0em;
$suzy-desktop-captions-paragraph-spacing: 0;
$suzy-desktop-captions-paragraph-indent: 0px;
$suzy-desktop-captions-text-case: none;
$suzy-desktop-captions-text-decoration: none;
$suzy-tablet-h1-font-family: Quicksand, Helvetica Neue, sans-serif;
$suzy-tablet-h1-font-weight: 700;
$suzy-tablet-h1-line-height: 125%;
$suzy-tablet-h1-font-size: 2.2857142857142856em;
$suzy-tablet-h1-letter-spacing: 0em;
$suzy-tablet-h1-paragraph-spacing: 0;
$suzy-tablet-h1-paragraph-indent: 0px;
$suzy-tablet-h1-text-case: none;
$suzy-tablet-h1-text-decoration: none;
$suzy-tablet-h2-font-family: Quicksand, Helvetica Neue, sans-serif;
$suzy-tablet-h2-font-weight: 700;
$suzy-tablet-h2-line-height: 125%;
$suzy-tablet-h2-font-size: 1.7142857142857142em;
$suzy-tablet-h2-letter-spacing: 0em;
$suzy-tablet-h2-paragraph-spacing: 0;
$suzy-tablet-h2-paragraph-indent: 0px;
$suzy-tablet-h2-text-case: none;
$suzy-tablet-h2-text-decoration: none;
$suzy-tablet-h3-font-family: Quicksand, Helvetica Neue, sans-serif;
$suzy-tablet-h3-font-weight: 600;
$suzy-tablet-h3-line-height: 125%;
$suzy-tablet-h3-font-size: 1.2857142857142858em;
$suzy-tablet-h3-letter-spacing: 0em;
$suzy-tablet-h3-paragraph-spacing: 0;
$suzy-tablet-h3-paragraph-indent: 0px;
$suzy-tablet-h3-text-case: none;
$suzy-tablet-h3-text-decoration: none;
$suzy-tablet-sub-header-font-family: Quicksand, Helvetica Neue, sans-serif;
$suzy-tablet-sub-header-font-weight: 600;
$suzy-tablet-sub-header-line-height: 125%;
$suzy-tablet-sub-header-font-size: 1.1428571428571428em;
$suzy-tablet-sub-header-letter-spacing: 0em;
$suzy-tablet-sub-header-paragraph-spacing: 0;
$suzy-tablet-sub-header-paragraph-indent: 0px;
$suzy-tablet-sub-header-text-case: none;
$suzy-tablet-sub-header-text-decoration: none;
$suzy-tablet-body-copy-font-family: Quicksand, Helvetica Neue, sans-serif;
$suzy-tablet-body-copy-font-weight: 500;
$suzy-tablet-body-copy-line-height: 125%;
$suzy-tablet-body-copy-font-size: 1em;
$suzy-tablet-body-copy-letter-spacing: 0em;
$suzy-tablet-body-copy-paragraph-spacing: 0;
$suzy-tablet-body-copy-paragraph-indent: 0px;
$suzy-tablet-body-copy-text-case: none;
$suzy-tablet-body-copy-text-decoration: none;
$suzy-tablet-body-copy-small-font-family: Quicksand, Helvetica Neue, sans-serif;
$suzy-tablet-body-copy-small-font-weight: 600;
$suzy-tablet-body-copy-small-line-height: 125%;
$suzy-tablet-body-copy-small-font-size: 0.8571428571428571em;
$suzy-tablet-body-copy-small-letter-spacing: 0em;
$suzy-tablet-body-copy-small-paragraph-spacing: 0;
$suzy-tablet-body-copy-small-paragraph-indent: 0px;
$suzy-tablet-body-copy-small-text-case: none;
$suzy-tablet-body-copy-small-text-decoration: none;
$suzy-tablet-buttons-font-family: Quicksand, Helvetica Neue, sans-serif;
$suzy-tablet-buttons-font-weight: 700;
$suzy-tablet-buttons-line-height: 125%;
$suzy-tablet-buttons-font-size: 1.1428571428571428em;
$suzy-tablet-buttons-letter-spacing: 0em;
$suzy-tablet-buttons-paragraph-spacing: 0;
$suzy-tablet-buttons-paragraph-indent: 0px;
$suzy-tablet-buttons-text-case: none;
$suzy-tablet-buttons-text-decoration: none;
$suzy-tablet-links-font-family: Quicksand, Helvetica Neue, sans-serif;
$suzy-tablet-links-font-weight: 700;
$suzy-tablet-links-line-height: 125%;
$suzy-tablet-links-font-size: 1em;
$suzy-tablet-links-letter-spacing: 0em;
$suzy-tablet-links-paragraph-spacing: 0;
$suzy-tablet-links-paragraph-indent: 0px;
$suzy-tablet-links-text-case: none;
$suzy-tablet-links-text-decoration: none;
$suzy-tablet-tooltips-font-family: Quicksand, Helvetica Neue, sans-serif;
$suzy-tablet-tooltips-font-weight: 600;
$suzy-tablet-tooltips-line-height: 125%;
$suzy-tablet-tooltips-font-size: 1em;
$suzy-tablet-tooltips-letter-spacing: 0em;
$suzy-tablet-tooltips-paragraph-spacing: 0;
$suzy-tablet-tooltips-paragraph-indent: 0px;
$suzy-tablet-tooltips-text-case: none;
$suzy-tablet-tooltips-text-decoration: none;
$suzy-tablet-captions-font-family: Quicksand, Helvetica Neue, sans-serif;
$suzy-tablet-captions-font-weight: 600;
$suzy-tablet-captions-line-height: 125%;
$suzy-tablet-captions-font-size: 0.8571428571428571em;
$suzy-tablet-captions-letter-spacing: 0em;
$suzy-tablet-captions-paragraph-spacing: 0;
$suzy-tablet-captions-paragraph-indent: 0px;
$suzy-tablet-captions-text-case: none;
$suzy-tablet-captions-text-decoration: none;
$suzy-mobile-h1-font-family: Quicksand, Helvetica Neue, sans-serif;
$suzy-mobile-h1-font-weight: 700;
$suzy-mobile-h1-line-height: 125%;
$suzy-mobile-h1-font-size: 2.2857142857142856em;
$suzy-mobile-h1-letter-spacing: 0em;
$suzy-mobile-h1-paragraph-spacing: 0;
$suzy-mobile-h1-paragraph-indent: 0px;
$suzy-mobile-h1-text-case: none;
$suzy-mobile-h1-text-decoration: none;
$suzy-mobile-h2-font-family: Quicksand, Helvetica Neue, sans-serif;
$suzy-mobile-h2-font-weight: 700;
$suzy-mobile-h2-line-height: 125%;
$suzy-mobile-h2-font-size: 1.7142857142857142em;
$suzy-mobile-h2-letter-spacing: 0em;
$suzy-mobile-h2-paragraph-spacing: 0;
$suzy-mobile-h2-paragraph-indent: 0px;
$suzy-mobile-h2-text-case: none;
$suzy-mobile-h2-text-decoration: none;
$suzy-mobile-h2-medium-font-family: Quicksand, Helvetica Neue, sans-serif;
$suzy-mobile-h2-medium-font-weight: 500;
$suzy-mobile-h2-medium-line-height: 125%;
$suzy-mobile-h2-medium-font-size: 1.7142857142857142em;
$suzy-mobile-h2-medium-letter-spacing: 0em;
$suzy-mobile-h2-medium-paragraph-spacing: 0;
$suzy-mobile-h2-medium-paragraph-indent: 0px;
$suzy-mobile-h2-medium-text-case: none;
$suzy-mobile-h2-medium-text-decoration: none;
$suzy-mobile-h3-font-family: Quicksand, Helvetica Neue, sans-serif;
$suzy-mobile-h3-font-weight: 700;
$suzy-mobile-h3-line-height: 125%;
$suzy-mobile-h3-font-size: 1.2857142857142858em;
$suzy-mobile-h3-letter-spacing: 0em;
$suzy-mobile-h3-paragraph-spacing: 0;
$suzy-mobile-h3-paragraph-indent: 0px;
$suzy-mobile-h3-text-case: none;
$suzy-mobile-h3-text-decoration: none;
$suzy-mobile-sub-header-font-family: Quicksand, Helvetica Neue, sans-serif;
$suzy-mobile-sub-header-font-weight: 600;
$suzy-mobile-sub-header-line-height: 125%;
$suzy-mobile-sub-header-font-size: 1.1428571428571428em;
$suzy-mobile-sub-header-letter-spacing: 0em;
$suzy-mobile-sub-header-paragraph-spacing: 0;
$suzy-mobile-sub-header-paragraph-indent: 0px;
$suzy-mobile-sub-header-text-case: none;
$suzy-mobile-sub-header-text-decoration: none;
$suzy-mobile-body-copy-font-family: Quicksand, Helvetica Neue, sans-serif;
$suzy-mobile-body-copy-font-weight: 500;
$suzy-mobile-body-copy-line-height: 125%;
$suzy-mobile-body-copy-font-size: 1em;
$suzy-mobile-body-copy-letter-spacing: 0em;
$suzy-mobile-body-copy-paragraph-spacing: 0;
$suzy-mobile-body-copy-paragraph-indent: 0px;
$suzy-mobile-body-copy-text-case: none;
$suzy-mobile-body-copy-text-decoration: none;
$suzy-mobile-body-copy-small-font-family: Quicksand, Helvetica Neue, sans-serif;
$suzy-mobile-body-copy-small-font-weight: 600;
$suzy-mobile-body-copy-small-line-height: 125%;
$suzy-mobile-body-copy-small-font-size: 0.8571428571428571em;
$suzy-mobile-body-copy-small-letter-spacing: 0em;
$suzy-mobile-body-copy-small-paragraph-spacing: 0;
$suzy-mobile-body-copy-small-paragraph-indent: 0px;
$suzy-mobile-body-copy-small-text-case: none;
$suzy-mobile-body-copy-small-text-decoration: none;
$suzy-mobile-buttons-font-family: Quicksand, Helvetica Neue, sans-serif;
$suzy-mobile-buttons-font-weight: 700;
$suzy-mobile-buttons-line-height: 125%;
$suzy-mobile-buttons-font-size: 1em;
$suzy-mobile-buttons-letter-spacing: 0em;
$suzy-mobile-buttons-paragraph-spacing: 0;
$suzy-mobile-buttons-paragraph-indent: 0px;
$suzy-mobile-buttons-text-case: none;
$suzy-mobile-buttons-text-decoration: none;
$suzy-mobile-links-font-family: Quicksand, Helvetica Neue, sans-serif;
$suzy-mobile-links-font-weight: 700;
$suzy-mobile-links-line-height: 125%;
$suzy-mobile-links-font-size: 1em;
$suzy-mobile-links-letter-spacing: 0em;
$suzy-mobile-links-paragraph-spacing: 0;
$suzy-mobile-links-paragraph-indent: 0px;
$suzy-mobile-links-text-case: none;
$suzy-mobile-links-text-decoration: none;
$suzy-mobile-tooltips-font-family: Quicksand, Helvetica Neue, sans-serif;
$suzy-mobile-tooltips-font-weight: 600;
$suzy-mobile-tooltips-line-height: 125%;
$suzy-mobile-tooltips-font-size: 1em;
$suzy-mobile-tooltips-letter-spacing: 0em;
$suzy-mobile-tooltips-paragraph-spacing: 0;
$suzy-mobile-tooltips-paragraph-indent: 0px;
$suzy-mobile-tooltips-text-case: none;
$suzy-mobile-tooltips-text-decoration: none;
$suzy-mobile-captions-font-family: Quicksand, Helvetica Neue, sans-serif;
$suzy-mobile-captions-font-weight: 600;
$suzy-mobile-captions-line-height: 125%;
$suzy-mobile-captions-font-size: 0.8571428571428571em;
$suzy-mobile-captions-letter-spacing: 0em;
$suzy-mobile-captions-paragraph-spacing: 0;
$suzy-mobile-captions-paragraph-indent: 0px;
$suzy-mobile-captions-text-case: none;
$suzy-mobile-captions-text-decoration: none;
$suzy-mobile-captions-small-font-family: Quicksand, Helvetica Neue, sans-serif;
$suzy-mobile-captions-small-font-weight: 600;
$suzy-mobile-captions-small-line-height: 125%;
$suzy-mobile-captions-small-font-size: 0.7142857142857143em;
$suzy-mobile-captions-small-letter-spacing: 0em;
$suzy-mobile-captions-small-paragraph-spacing: 0;
$suzy-mobile-captions-small-paragraph-indent: 0px;
$suzy-mobile-captions-small-text-case: none;
$suzy-mobile-captions-small-text-decoration: none;
$suzy-input-label-small-font-family: "Inter", Helvetica Neue, sans-serif;
$suzy-input-label-small-font-weight: 500;
$suzy-input-label-small-line-height: 130%;
$suzy-input-label-small-font-size: 0.8571428571428571em;
$suzy-input-label-small-letter-spacing: 0em;
$suzy-input-label-small-paragraph-spacing: 0;
$suzy-input-label-small-paragraph-indent: 0;
$suzy-input-label-small-text-decoration: none;
$suzy-input-label-small-text-case: none;
$suzy-link-title-font-family: "Inter", Helvetica Neue, sans-serif;
$suzy-link-title-font-weight: 500;
$suzy-link-title-line-height: 140%;
$suzy-link-title-font-size: 1em;
$suzy-link-title-letter-spacing: 0em;
$suzy-link-title-paragraph-spacing: 0;
$suzy-link-title-paragraph-indent: 0;
$suzy-link-title-text-decoration: none;
$suzy-link-title-text-case: none;
$suzy-black: #000000;
$suzy-white: #ffffff;
$suzy-grey-50: #fafafc;
$suzy-grey-100: #f6f6f8;
$suzy-grey-200: #edf0f3;
$suzy-grey-300: #d7dadf;
$suzy-grey-400: #c4c8cf;
$suzy-grey-500: #b0b5bf;
$suzy-grey-600: #9ca3af;
$suzy-grey-700: #757a83;
$suzy-grey-800: #4e5258;
$suzy-grey-900: #27292c;
$suzy-purple-50: #f7f2ff;
$suzy-purple-100: #f0ebfa;
$suzy-purple-200: #e0d6f5;
$suzy-purple-300: #c2adeb;
$suzy-purple-400: #a383e0;
$suzy-purple-500: #6a33d4;
$suzy-purple-600: #4d2599;
$suzy-purple-700: #331966;
$suzy-purple-800: #291257;
$suzy-purple-900: #1a0c33;
$suzy-core-blue-50: #f4f6ff;
$suzy-core-blue-100: #edeffb;
$suzy-core-blue-200: #dbdef6;
$suzy-core-blue-300: #b6bdee;
$suzy-core-blue-400: #929be5;
$suzy-core-blue-500: #4959d4;
$suzy-core-blue-600: #394ace;
$suzy-core-blue-700: #37439f;
$suzy-core-blue-800: #252d6a;
$suzy-core-blue-900: #121635;
$suzy-blue-50: #eef5ff;
$suzy-blue-100: #cbdfff;
$suzy-blue-200: #b9d5ff;
$suzy-blue-300: #97c0ff;
$suzy-blue-400: #74abff;
$suzy-blue-500: #5196ff;
$suzy-blue-600: #417bd4;
$suzy-blue-700: #3160a9;
$suzy-blue-800: #20467e;
$suzy-blue-900: #183869;
$suzy-red-50: #fef3f2;
$suzy-red-100: #fee4e2;
$suzy-red-200: #fecdca;
$suzy-red-300: #fda29b;
$suzy-red-400: #f97066;
$suzy-red-500: #f04c41;
$suzy-red-600: #d92d20;
$suzy-red-700: #b42318;
$suzy-red-800: #912018;
$suzy-red-900: #7a271a;
$suzy-warm-50: #fffcf5;
$suzy-warm-100: #fffaeb;
$suzy-warm-200: #fef0c7;
$suzy-warm-300: #fedf89;
$suzy-warm-400: #fec84b;
$suzy-warm-500: #f68444;
$suzy-warm-600: #dc6803;
$suzy-warm-700: #b54708;
$suzy-warm-800: #93370d;
$suzy-warm-900: #7a2e0e;
$suzy-green-50: #ecfdf3;
$suzy-green-100: #d1fadf;
$suzy-green-200: #a6f4c5;
$suzy-green-300: #6ce9a6;
$suzy-green-400: #3dd985;
$suzy-green-500: #21c277;
$suzy-green-600: #039855;
$suzy-green-700: #027a48;
$suzy-green-800: #05603a;
$suzy-green-900: #054f31;
$suzy-gradient-1: linear-gradient(180deg, #4e1276 0%, #191460 51.56%);
$suzy-gradient-2: linear-gradient(-28deg, #ee73bc 0%, #9347a0 0.01%, #653191 26.7%, #432187 53.97%, #331966 78.81%);
$suzy-gradient-3: linear-gradient(126deg, #6b17b8 9.57%, #6c16b8 50.81%, #9a18cc 73.39%, #b71fd9 88.2%);
$suzy-gradient-4: linear-gradient(126deg, #6b17b8 9.57%, #6c16b8 50.81%, #9a18cc 73.39%, #b71fd9 88.2%);
$suzy-pink-50: #fff2fa;
$suzy-pink-100: #fad5f9;
$suzy-pink-200: #f8c7f3;
$suzy-pink-300: #f5abee;
$suzy-pink-400: #f179f3;
$suzy-pink-500: #e473ee;
$suzy-pink-600: #d159ee;
$suzy-pink-700: #bb55df;
$suzy-pink-800: #b03cdd;
$suzy-pink-900: #8332a5;
$suzy-link-text-x-small-font-family: "Inter", Helvetica Neue, sans-serif;
$suzy-link-text-x-small-font-weight: 500;
$suzy-link-text-x-small-line-height: 145%;
$suzy-link-text-x-small-font-size: 0.8571428571428571em;
$suzy-link-text-x-small-letter-spacing: 0em;
$suzy-link-text-x-small-paragraph-spacing: 0;
$suzy-link-text-x-small-paragraph-indent: 0;
$suzy-link-text-x-small-text-decoration: underline;
$suzy-link-text-x-small-text-case: none;
$suzy-input-label-font-family: "Inter", Helvetica Neue, sans-serif;
$suzy-input-label-font-weight: 500;
$suzy-input-label-line-height: 110%;
$suzy-input-label-font-size: 1em;
$suzy-input-label-letter-spacing: 0em;
$suzy-input-label-paragraph-spacing: 0;
$suzy-input-label-paragraph-indent: 0;
$suzy-input-label-text-decoration: none;
$suzy-input-label-text-case: none;
$suzy-input-font-family: "Inter", Helvetica Neue, sans-serif;
$suzy-input-font-weight: 400;
$suzy-input-line-height: 140%;
$suzy-input-font-size: 1em;
$suzy-input-letter-spacing: 0em;
$suzy-input-paragraph-spacing: 0;
$suzy-input-paragraph-indent: 0;
$suzy-input-text-decoration: none;
$suzy-input-text-case: none;
$suzy-d2-font-family: "Inter", Helvetica Neue, sans-serif;
$suzy-d2-font-weight: 500;
$suzy-d2-line-height: 125%;
$suzy-d2-font-size: 2.2857142857142856em;
$suzy-d2-letter-spacing: 0em;
$suzy-d2-paragraph-spacing: 0;
$suzy-d2-paragraph-indent: 0;
$suzy-d2-text-decoration: none;
$suzy-d2-text-case: none;
$suzy-d1-font-family: "Inter", Helvetica Neue, sans-serif;
$suzy-d1-font-weight: 500;
$suzy-d1-line-height: 117%;
$suzy-d1-font-size: 3.4285714285714284em;
$suzy-d1-letter-spacing: 0em;
$suzy-d1-paragraph-spacing: 0;
$suzy-d1-paragraph-indent: 0;
$suzy-d1-text-decoration: none;
$suzy-d1-text-case: none;
$suzy-spacing-40: 40px;
$suzy-spacing-36: 36px;
$suzy-spacing-32: 32px;
$suzy-spacing-28: 28px;
$suzy-spacing-24: 24px;
$suzy-spacing-20: 20px;
$suzy-spacing-16: 16px;
$suzy-spacing-12: 12px;
$suzy-spacing-8: 8px;
$suzy-spacing-4: 4px;
$suzy-secondary-label-font-family: "Inter", Helvetica Neue, sans-serif;
$suzy-secondary-label-font-weight: 400;
$suzy-secondary-label-line-height: 130%;
$suzy-secondary-label-font-size: 0.8571428571428571em;
$suzy-secondary-label-letter-spacing: 0em;
$suzy-secondary-label-paragraph-spacing: 0;
$suzy-secondary-label-paragraph-indent: 0;
$suzy-secondary-label-text-decoration: none;
$suzy-secondary-label-text-case: none;
$suzy-label-font-family: "Inter", Helvetica Neue, sans-serif;
$suzy-label-font-weight: 500;
$suzy-label-line-height: 130%;
$suzy-label-font-size: 0.8571428571428571em;
$suzy-label-letter-spacing: 0em;
$suzy-label-paragraph-spacing: 0;
$suzy-label-paragraph-indent: 0;
$suzy-label-text-decoration: none;
$suzy-label-text-case: none;
$suzy-link-text-small-font-family: "Inter", Helvetica Neue, sans-serif;
$suzy-link-text-small-font-weight: 500;
$suzy-link-text-small-line-height: 145%;
$suzy-link-text-small-font-size: 1em;
$suzy-link-text-small-letter-spacing: 0em;
$suzy-link-text-small-paragraph-spacing: 0;
$suzy-link-text-small-paragraph-indent: 0;
$suzy-link-text-small-text-decoration: underline;
$suzy-link-text-small-text-case: none;
$suzy-link-text-font-family: "Inter", Helvetica Neue, sans-serif;
$suzy-link-text-font-weight: 500;
$suzy-link-text-font-size: 1.1428571428571428em;
$suzy-link-text-line-height: 150%;
$suzy-link-text-letter-spacing: 0em;
$suzy-link-text-paragraph-spacing: 0;
$suzy-link-text-paragraph-indent: 0;
$suzy-link-text-text-decoration: underline;
$suzy-link-text-text-case: none;
$suzy-button-text-small-font-family: "Inter", Helvetica Neue, sans-serif;
$suzy-button-text-small-font-weight: 600;
$suzy-button-text-small-font-size: 1em;
$suzy-button-text-small-line-height: 140%;
$suzy-button-text-small-letter-spacing: 0.02em;
$suzy-button-text-small-paragraph-spacing: 0;
$suzy-button-text-small-paragraph-indent: 0;
$suzy-button-text-small-text-decoration: none;
$suzy-button-text-small-text-case: capitalize;
$suzy-button-text-font-family: "Inter", Helvetica Neue, sans-serif;
$suzy-button-text-font-weight: 500;
$suzy-button-text-font-size: 1.1428571428571428em;
$suzy-button-text-line-height: 125%;
$suzy-button-text-letter-spacing: 0.02em;
$suzy-button-text-paragraph-spacing: 0;
$suzy-button-text-paragraph-indent: 0;
$suzy-button-text-text-decoration: none;
$suzy-button-text-text-case: capitalize;
$suzy-assistive-text-font-family: "Inter", Helvetica Neue, sans-serif;
$suzy-assistive-text-font-weight: 400;
$suzy-assistive-text-font-size: 0.8571428571428571em;
$suzy-assistive-text-letter-spacing: 0em;
$suzy-assistive-text-paragraph-spacing: 0;
$suzy-assistive-text-paragraph-indent: 0;
$suzy-assistive-text-text-decoration: none;
$suzy-assistive-text-text-case: none;
$suzy-assistive-text-line-height: 130%;
$suzy-body-copy-caption-font-family: "Inter", Helvetica Neue, sans-serif;
$suzy-body-copy-caption-font-weight: 400;
$suzy-body-copy-caption-line-height: 135%;
$suzy-body-copy-caption-font-size: 0.8571428571428571em;
$suzy-body-copy-caption-letter-spacing: 0em;
$suzy-body-copy-caption-paragraph-spacing: 0;
$suzy-body-copy-caption-paragraph-indent: 0;
$suzy-body-copy-caption-text-decoration: none;
$suzy-body-copy-caption-text-case: none;
$suzy-body-copy-small-bold-font-family: "Inter", Helvetica Neue, sans-serif;
$suzy-body-copy-small-bold-font-weight: 600;
$suzy-body-copy-small-bold-line-height: 140%;
$suzy-body-copy-small-bold-font-size: 1em;
$suzy-body-copy-small-bold-letter-spacing: 0em;
$suzy-body-copy-small-bold-paragraph-spacing: 0;
$suzy-body-copy-small-bold-paragraph-indent: 0;
$suzy-body-copy-small-bold-text-decoration: none;
$suzy-body-copy-small-bold-text-case: none;
$suzy-body-copy-small-font-family: "Inter", Helvetica Neue, sans-serif;
$suzy-body-copy-small-font-weight: 400;
$suzy-body-copy-small-line-height: 140%;
$suzy-body-copy-small-font-size: 1em;
$suzy-body-copy-small-letter-spacing: 0em;
$suzy-body-copy-small-paragraph-spacing: 0;
$suzy-body-copy-small-paragraph-indent: 0;
$suzy-body-copy-small-text-decoration: none;
$suzy-body-copy-small-text-case: none;
$suzy-body-copy-medium-font-family: "Inter", Helvetica Neue, sans-serif;
$suzy-body-copy-medium-font-weight: 500;
$suzy-body-copy-medium-line-height: 150%;
$suzy-body-copy-medium-font-size: 1.1428571428571428em;
$suzy-body-copy-medium-letter-spacing: 0em;
$suzy-body-copy-medium-paragraph-spacing: 0;
$suzy-body-copy-medium-paragraph-indent: 0;
$suzy-body-copy-medium-text-decoration: none;
$suzy-body-copy-medium-text-case: none;
$suzy-body-copy-bold-font-family: "Inter", Helvetica Neue, sans-serif;
$suzy-body-copy-bold-font-weight: 600;
$suzy-body-copy-bold-font-size: 1.1428571428571428em;
$suzy-body-copy-bold-line-height: 150%;
$suzy-body-copy-bold-letter-spacing: 0em;
$suzy-body-copy-bold-paragraph-spacing: 0;
$suzy-body-copy-bold-paragraph-indent: 0;
$suzy-body-copy-bold-text-decoration: none;
$suzy-body-copy-bold-text-case: none;
$suzy-body-copy-regular-font-family: "Inter", Helvetica Neue, sans-serif;
$suzy-body-copy-regular-font-weight: 400;
$suzy-body-copy-regular-line-height: 150%;
$suzy-body-copy-regular-font-size: 1.1428571428571428em;
$suzy-body-copy-regular-letter-spacing: 0em;
$suzy-body-copy-regular-paragraph-spacing: 0;
$suzy-body-copy-regular-paragraph-indent: 0;
$suzy-body-copy-regular-text-decoration: none;
$suzy-body-copy-regular-text-case: none;
$suzy-body-copy-large-font-family: "Inter", Helvetica Neue, sans-serif;
$suzy-body-copy-large-font-weight: 400;
$suzy-body-copy-large-line-height: 155%;
$suzy-body-copy-large-font-size: 1.2857142857142858em;
$suzy-body-copy-large-letter-spacing: 0em;
$suzy-body-copy-large-paragraph-spacing: 0;
$suzy-body-copy-large-paragraph-indent: 0;
$suzy-body-copy-large-text-decoration: none;
$suzy-body-copy-large-text-case: none;
$suzy-eyebrow-font-family: "Inter", Helvetica Neue, sans-serif;
$suzy-eyebrow-font-weight: 500;
$suzy-eyebrow-line-height: 135%;
$suzy-eyebrow-font-size: 0.8571428571428571em;
$suzy-eyebrow-letter-spacing: 0.1em;
$suzy-eyebrow-paragraph-spacing: 0;
$suzy-eyebrow-paragraph-indent: 0;
$suzy-eyebrow-text-decoration: none;
$suzy-eyebrow-text-case: uppercase;
$suzy-h5-font-family: "Inter", Helvetica Neue, sans-serif;
$suzy-h5-font-weight: 500;
$suzy-h5-line-height: 115%;
$suzy-h5-font-size: 1em;
$suzy-h5-letter-spacing: 0em;
$suzy-h5-paragraph-spacing: 0;
$suzy-h5-paragraph-indent: 0;
$suzy-h5-text-case: none;
$suzy-h5-text-decoration: none;
$suzy-h4-font-family: "Inter", Helvetica Neue, sans-serif;
$suzy-h4-font-weight: 600;
$suzy-h4-line-height: 115%;
$suzy-h4-font-size: 1em;
$suzy-h4-letter-spacing: 0em;
$suzy-h4-paragraph-spacing: 0;
$suzy-h4-paragraph-indent: 0;
$suzy-h4-text-decoration: none;
$suzy-h4-text-case: none;
$suzy-h3-font-family: "Inter", Helvetica Neue, sans-serif;
$suzy-h3-font-weight: 600;
$suzy-h3-line-height: 115%;
$suzy-h3-font-size: 1.1428571428571428em;
$suzy-h3-letter-spacing: 0em;
$suzy-h3-paragraph-spacing: 0;
$suzy-h3-paragraph-indent: 0;
$suzy-h3-text-decoration: none;
$suzy-h3-text-case: none;
$suzy-h2-font-family: "Inter", Helvetica Neue, sans-serif;
$suzy-h2-font-weight: 600;
$suzy-h2-line-height: 135%;
$suzy-h2-font-size: 1.2857142857142858em;
$suzy-h2-letter-spacing: 0em;
$suzy-h2-paragraph-spacing: 0;
$suzy-h2-paragraph-indent: 0;
$suzy-h2-text-decoration: none;
$suzy-h2-text-case: none;
$suzy-h1-font-family: "Inter", Helvetica Neue, sans-serif;
$suzy-h1-font-weight: 500;
$suzy-h1-line-height: 135%;
$suzy-h1-font-size: 1.7142857142857142em;
$suzy-h1-letter-spacing: 0em;
$suzy-h1-paragraph-spacing: 0;
$suzy-h1-paragraph-indent: 0;
$suzy-h1-text-decoration: none;
$suzy-h1-text-case: none;
$suzy-primary-button-background-default: #6a33d4;
$suzy-primary-button-font-family: "Inter", Helvetica Neue, sans-serif;
$suzy-primary-button-font-weight: 400;
$suzy-primary-button-line-height: 125%;
$suzy-primary-button-letter-spacing: 0.0002em;
$suzy-primary-button-text-color: #ffffff;
$suzy-primary-button-text-case: capitalize;
$suzy-primary-button-border-width: 0;
$suzy-primary-button-hover-bg-color: #331966;
$suzy-primary-button-border-radius: 6px;
$suzy-border-radius-6: 6px;
$suzy-border-radius-8: 8px;
$suzy-border-radius-2: 2px;
$suzy-border-radius-4: 4px;
$suzy-box-shadow-lightbox: 0px 3px 64px 0px #00000014;
$suzy-box-shadow-small: 0px 0.8px 3px 0px #0000003d;
$suzy-box-shadow-medium: 0px 0px 6px 0px #00000029;
$suzy-box-shadow-large: 0px 3px 6px 0px #0000003d;
$suzy-font-base: 14px;
$suzy-font-families-inter: "Inter", Helvetica Neue, sans-serif;
$suzy-font-families-quicksand: Quicksand, Helvetica Neue, sans-serif;
$suzy-line-heights-0: 117%;
$suzy-line-heights-1: 125%;
$suzy-line-heights-2: 135%;
$suzy-line-heights-3: 115%;
$suzy-line-heights-4: 148%;
$suzy-line-heights-5: 150%;
$suzy-line-heights-6: 145%;
$suzy-line-heights-7: 100%;
$suzy-line-heights-8: 140%;
$suzy-line-heights-9: 160%;
$suzy-line-heights-10: 120%;
$suzy-line-heights-11: 155%;
$suzy-font-weights-inter-medium: 500;
$suzy-font-weights-inter-bold: 700;
$suzy-font-weights-inter-regular: 400;
$suzy-font-weights-inter-semibold: 600;
$suzy-font-weights-quicksand-0: 700;
$suzy-font-weights-quicksand-1: 600;
$suzy-font-weights-quicksand-2: 500;
$suzy-font-size-0: 0.7142857142857143em;
$suzy-font-size-1: 0.8571428571428571em;
$suzy-font-size-2: 1em;
$suzy-font-size-3: 1.1428571428571428em;
$suzy-font-size-4: 1.2857142857142858em;
$suzy-font-size-5: 1.7142857142857142em;
$suzy-font-size-6: 2.2857142857142856em;
$suzy-font-size-7: 3.4285714285714284em;
$suzy-letter-spacing-0: 0em;
$suzy-letter-spacing-1: 0.04em;
$suzy-letter-spacing-2: 0.1em;
$suzy-letter-spacing-3: 0.02em;
$suzy-paragraph-spacing-none: 0;
$suzy-text-transform-none: none;
$suzy-text-transform-uppercase: uppercase;
$suzy-text-transform-capitalize: capitalize;
$suzy-text-decoration-none: none;
$suzy-text-decoration-underline: underline;
$suzy-paragraph-indent-0: 0pxpx;
$suzy-text-case-capitalize: capitalize;
