igc-dockmanager::part(split-pane) {
  background: #ffffff;
}

igc-dockmanager::part(content-pane) {
  background: #ffffff;
}

igc-dockmanager::part(pane-header) {
  margin-top: 16px;
  background: #fafafa;
  box-shadow: none;
  padding: 0;
}

igc-dockmanager::part(pane-header-content) {
  font-weight: 700;
  font-size: 14px;
  line-height: 38px;
  color: #808080;
  text-align: center;
}

igc-dockmanager::part(tabs-container) {
  background-color: #ffffff;
  border-radius: 6px;
  border: 1px solid #c4c8cf;
}

igc-dockmanager::part(tab-strip-area) {
  background-color: #ffffff;
  padding: 0;
  height: 34px;
  min-height: 34px;
  max-height: 34px;
  border-radius: 6px 6px 0 0;
}

igc-dockmanager::part(tab-strip-actions top) {
  display: none;
}

igc-dockmanager::part(tab-header) {
  background-color: #ffffff;
  padding: 0 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 32px;
  height: 34px;
  cursor: pointer;
  margin-inline-end: 0px;
  width: 16.66%;
  color: #4e5258;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
}

igc-dockmanager::part(tab-header selected) {
  background: #ffffff;
  color: #000000;
  box-shadow: inset 0 -2px 0 0 var(--igc-active-color, cornflowerblue);
}

igc-dockmanager::part(tab-panel) {
  border-radius: 0 0 6px 6px;
  border-top: 1px solid #c4c8cf;
}

igc-dockmanager::part(splitter) {
  display: none;
}

::slotted(preview-row) {
  background: red;
}
