@use 'all-tokens' as *;
@use '@angular/material' as mat;
@use 'suzy-icon';

@import 'token-classes';
@import 'snackbar-classes';
@import 'button-classes';
@import 'dashboard-card';
@import 'badge';
@import 'popover';
@import 'table';
@import 'footer';
@import 'contextual-toolbar';
@import 'radio-button';
@import 'content-switcher';
@import 'media-uploader';
@import 'text-input';
@import 'searchable-text-input';
@import 'textarea';

html,
body {
  @extend .suzy-inter, .suzy-font-base;
  margin: 0;
  font-size: 14px;
}
