@use '@infragistics/igniteui-angular/lib/core/styles/themes/index' as *;
@forward '@infragistics/igniteui-angular/lib/core/styles/themes/index';
@use '@asksuzy/suzy-design-tokens/dist/web/suzy-core/scss/all-tokens' as *;

$primary-color: #673ab7;
$secondary-color: #673ab7;
$my-color-palette: palette(
  $primary: $primary-color,
  $secondary: $secondary-color,
);
$my-typeface: $suzy-font-families-inter !default;
$suyz-button-theme: button-theme(
  $hover-foreground: white,
  $focus-foreground: white,
  $active-foreground: white,
  $background: $primary-color,
);
$scrollbar-theme: scrollbar-theme(
  $size: '8px',
);
$no-ripple-theme: ripple-theme(
  $color: transparent,
);
$drop-down-theme: drop-down-theme(
  $selected-item-background: #f7f2ff,
  $selected-hover-item-background: #f7f2ff,
  $selected-focus-item-background: #f7f2ff,
  $hover-item-background: rgba(247, 242, 255, 0.5),
);
$my-dialog-theme: dialog-theme(
    $background: white,
);

:root {
  --igx-font-family: $suzy-font-families-inter;
  --igc-font-family: $suzy-font-families-inter;
  --igx-dialog-border-radius: 8px;
}

// Overrides
.igx-input-group--border .igx-input-group__bundle igx-suffix, .igx-input-group--border .igx-input-group__bundle [igxSuffix] {
  padding-inline-end: 0 !important;
}

.table-view-selection {
  .igx-input-group--border .igx-input-group__bundle igx-suffix, .igx-input-group--border .igx-input-group__bundle [igxSuffix] {
    padding-inline-end: 1rem !important;
  }
}

.igx-drop-down__list {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.igx-list {
  height: 100% !important;
}

.igx-combo__clear-button {
  padding-inline-end: 1.5rem !important;
  igx-icon {
    font-size: 16px !important;
    align-self: end !important;
    margin-bottom: 2px !important;
  }
}
